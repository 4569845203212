import React, { Component } from "react";
import "./styles.css";

class Splash extends Component {
  render() {
    return (
      <div className="jumbotron noBG">
        <h1>
          Welcome to IWinToLose's Genshin Impact Damage Maximizer (name WIP)
        </h1>

        <p className="text-danger">
          There is a lot of stuff that this damage maximizer does not account
          for! For the list of unaccounted for and upcoming stuff, click{" "}
          <a href="/WIP">here</a>!
        </p>
        <p className="">
          This tool maximizes your damage by shuffling around artifact substat
          "points" from a group of predefined main stats to find the maximum
          amount of elemental or physical damage a character can do with a
          specific weapon. It assumes all level 20 5 star artifacts with average
          rolls for the substats and each artifact starts with 4 substats.{" "}
          <b>
            It does NOT currently account for artifact set bonuses, one reason
            being that many of them are situational. You can input artifact set
            bonuses and other bonuses in the "Additional Stats" section below.
          </b>
        </p>
        <p className="offWhiteText">
          I made this in my free time to figure out how close my Klee was to her
          <b> maximum damage potential</b>. If you find this tool useful, all I
          ask from you is to please{" "}
          <b>
            <a href="https://www.youtube.com/c/iwintolose" target="_blank" rel="noreferrer">
              Subscribe
            </a>
          </b>{" "}
          to my{" "}
          <b>
            <a href="https://www.youtube.com/c/iwintolose" target="_blank" rel="noreferrer">
              YouTube Channel{" "}
            </a>
            !{" "}
          </b>
          Let me know what you think of this tool in a comment to one of my
          videos.
        </p>
        <p className="">
          Also, check out this{" "}
          <a href="https://youtu.be/TWGVuQcR3no" target="_blank" rel="noreferrer">
            showcase/tutorial video
          </a>{" "}
          on how to accurately use this tool.
        </p>
        {/* <div
          className="g-ytsubscribe"
          data-channelid="UCHMnsOPDgrjWvjHXZp-xvqg"
          data-layout="full"
          data-count="default"
        ></div> */}
        <div>
          <small className="text-muted">
            This site is currently in alpha (v0.01). Things will break! Many
            features aren't done yet! Please be patient.
          </small>
        </div>
      </div>
    );
  }
}

export default Splash;

var catalysts = {
  // lostPrayer
  lostPrayer: {
    name: 'lostPrayer',
    stars: 5,
    type: 'catalyst',
    display: 'Lost Prayer to the Sacred Winds',
    levels: {
      90: [
        { type: 'weaponAttack', value: 608 },
        { type: 'critRate', value: 0.331 },
      ],
      80: [
        { type: 'weaponAttack', value: 532 },
        { type: 'critRate', value: 0.302 },
      ],
      70: [
        { type: 'weaponAttack', value: 457 },
        { type: 'critRate', value: 0.273 },
      ],
    },
    bonuses: [
      // { type: "attackPercent", value: 0.6, refinementScaling: true }
    ],
    situationalBonuses: [
      {
        display: '4 stacks',
        stats: [{ type: 'elementalDmg', value: 0.32, refinementScaling: true }],
      },
      {
        display: '3 stacks',
        stats: [{ type: 'elementalDmg', value: 0.24, refinementScaling: true }],
      },
      {
        display: '2 stacks',
        stats: [{ type: 'elementalDmg', value: 0.16, refinementScaling: true }],
      },
      {
        display: '1 stacks',
        stats: [{ type: 'elementalDmg', value: 0.08, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // kagurasVerity
  kagurasVerity: {
    name: 'kagurasVerity',
    stars: 5,
    type: 'catalyst',
    display: "Kagura's Verity",
    levels: {
      90: [
        { type: 'weaponAttack', value: 608 },
        { type: 'critDmg', value: 0.662 },
      ],
      80: [
        { type: 'weaponAttack', value: 532 },
        { type: 'critDmg', value: 0.603 },
      ],
      70: [
        { type: 'weaponAttack', value: 457 },
        { type: 'critDmg', value: 0.545 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '3 stacks',
        stats: [{ type: 'elementalDmg', value: 0.48, refinementScaling: true }],
      },
      {
        display: '2 stacks',
        stats: [{ type: 'elementalDmg', value: 0.24, refinementScaling: true }],
      },
      {
        display: '1 stack',
        stats: [{ type: 'elementalDmg', value: 0.12, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // skywardAtlas
  skywardAtlas: {
    name: 'skywardAtlas',
    stars: 5,
    type: 'catalyst',
    display: 'Skyward Atlas',
    levels: {
      90: [
        { type: 'weaponAttack', value: 674 },
        { type: 'attackPercent', value: 0.331 },
      ],
      80: [
        { type: 'weaponAttack', value: 590 },
        { type: 'attackPercent', value: 0.302 },
      ],
      70: [
        { type: 'weaponAttack', value: 506 },
        { type: 'attackPercent', value: 0.273 },
      ],
    },
    bonuses: [{ type: 'elementalDmg', value: 0.12, refinementScaling: true }],
    situationalBonuses: [{ display: 'None' }],
  },
  // memoryOfDust
  memoryOfDust: {
    name: 'memoryOfDust',
    stars: 5,
    type: 'catalyst',
    display: 'Memory of Dust',
    levels: {
      90: [
        { type: 'weaponAttack', value: 608 },
        { type: 'attackPercent', value: 0.496 },
      ],
      80: [
        { type: 'weaponAttack', value: 532 },
        { type: 'attackPercent', value: 0.453 },
      ],
      70: [
        { type: 'weaponAttack', value: 457 },
        { type: 'attackPercent', value: 0.409 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '5 stacks of boost with shield',
        stats: [{ type: 'attackPercent', value: 0.4, refinementScaling: true }],
      },
      {
        display: '5 stacks of boost without shield',
        stats: [{ type: 'attackPercent', value: 0.2, refinementScaling: true }],
      },
      { display: 'None' },
    ],
  },
  // theWidsith
  theWidsith: {
    name: 'theWidsith',
    stars: 4,
    type: 'catalyst',
    display: 'The Widsith',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'critDmg', value: 0.551 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'critDmg', value: 0.503 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'critDmg', value: 0.454 },
      ],
    },
    bonuses: [
      // { type: "attackPercent", value: 0.6, refinementScaling: true }
    ],
    situationalBonuses: [
      {
        display: 'Attack percent boost upon switching in',
        stats: [{ type: 'attackPercent', value: 0.6, refinementScaling: true }],
      },
      {
        display: 'Elemental mastery boost upon switching in',
        stats: [
          { type: 'elementalMastery', value: 240, refinementScaling: true },
        ],
      },
      {
        display: 'Elemental damage boost upon switching in',
        stats: [{ type: 'elementalDmg', value: 0.48, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // eyeOfPerception
  eyeOfPerception: {
    name: 'eyeOfPerception',
    stars: 4,
    type: 'catalyst',
    display: 'Eye of Perception',
    levels: {
      90: [
        { type: 'weaponAttack', value: 454 },
        { type: 'attackPercent', value: 0.551 },
      ],
      80: [
        { type: 'weaponAttack', value: 401 },
        { type: 'attackPercent', value: 0.503 },
      ],
      70: [
        { type: 'weaponAttack', value: 347 },
        { type: 'attackPercent', value: 0.454 },
      ],
    },
    bonuses: [
      // { type: "attackPercent", value: 0.6, refinementScaling: true }
    ],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  // wineAndSong
  wineAndSong: {
    name: 'wineAndSong',
    stars: 4,
    type: 'catalyst',
    display: 'Wine and Song',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'energyRecharge', value: 0.306 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'energyRecharge', value: 0.279 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'energyRecharge', value: 0.252 },
      ],
      1: [
        { type: 'weaponAttack', value: 44 },
        { type: 'energyRecharge', value: 0.067 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Normal attack and then dash.',
        stats: [{ type: 'attackPercent', value: 0.2, refinementScaling: true }],
      },
      { display: 'None' },
    ],
  },
  // frostBearer
  frostBearer: {
    name: 'frostBearer',
    stars: 4,
    type: 'catalyst',
    display: 'Frost Bearer',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'attackPercent', value: 0.413 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'attackPercent', value: 0.377 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'attackPercent', value: 0.341 },
      ],
      1: [
        { type: 'weaponAttack', value: 42 },
        { type: 'attackPercent', value: 0.09 },
      ],
    },
    bonuses: [
      // { type: "attackPercent", value: 0.6, refinementScaling: true }
    ],
    situationalBonuses: [{ display: 'None' }],
  },
  // solarPearl
  solarPearl: {
    name: 'solarPearl',
    stars: 4,
    type: 'catalyst',
    display: 'Solar Pearl',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'critRate', value: 0.276 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'critRate', value: 0.251 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'critRate', value: 0.227 },
      ],
    },
    bonuses: [
      // { type: "attackPercent", value: 0.6, refinementScaling: true }
    ],
    situationalBonuses: [
      {
        display: 'Boost to type of attack',
        stats: [{ type: 'bonusDmg', value: 0.2, refinementScaling: true }],
      },
      { display: 'None' },
    ],
  },
  // hakkushinRing
  hakkushinRing: {
    name: 'hakkushinRing',
    stars: 4,
    type: 'catalyst',
    display: 'Hakkushin Ring',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'energyRecharge', value: 0.306 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'energyRecharge', value: 0.279 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'energyRecharge', value: 0.252 },
      ],
    },
    bonuses: [
      // { type: "attackPercent", value: 0.6, refinementScaling: true }
    ],
    situationalBonuses: [
      {
        display: 'Triggered proper electro related reaction',
        stats: [{ type: 'elementalDmg', value: 0.1, refinementScaling: true }],
      },
      { display: 'None' },
    ],
  },
  // sacrificialFragments
  sacrificialFragments: {
    name: 'sacrificialFragments',
    stars: 4,
    type: 'catalyst',
    display: 'Sacrificial Fragments',
    levels: {
      90: [
        { type: 'weaponAttack', value: 454 },
        { type: 'elementalMastery', value: 221 },
      ],
      80: [
        { type: 'weaponAttack', value: 401 },
        { type: 'elementalMastery', value: 201 },
      ],
      70: [
        { type: 'weaponAttack', value: 347 },
        { type: 'elementalMastery', value: 182 },
      ],
    },
    bonuses: [
      // { type: "attackPercent", value: 0.6, refinementScaling: true }
    ],
    situationalBonuses: [{ display: 'None' }],
  },
  // oathswornEye
  oathswornEye: {
    name: 'oathswornEye',
    stars: 4,
    type: 'catalyst',
    display: 'Oathsworn Eye',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'attackPercent', value: 0.276 },
      ],
      80: [
        { type: 'weaponAttack', value: 401 },
        { type: 'attackPercent', value: 201 },
      ],
      70: [
        { type: 'weaponAttack', value: 347 },
        { type: 'attackPercent', value: 182 },
      ],
    },
    bonuses: [
      // { type: "attackPercent", value: 0.6, refinementScaling: true }
    ],
    situationalBonuses: [
      {
        display: 'Bonus Energy Recharge after E',
        stats: [
          { type: 'energyRecharge', value: 0.24, refinementScaling: true },
        ],
      },
      { display: 'None' },
    ],
  },
  // royalGrimoire
  royalGrimoire: {
    name: 'royalGrimoire',
    stars: 4,
    type: 'catalyst',
    display: 'Royal Grimoire',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'attackPercent', value: 0.276 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'attackPercent', value: 0.251 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'attackPercent', value: 0.227 },
      ],
    },
    bonuses: [
      // { type: "attackPercent", value: 0.6, refinementScaling: true }
    ],
    situationalBonuses: [
      {
        display: '1 stack of crit rate',
        stats: [{ type: 'critRate', value: 0.08, refinementScaling: true }],
      },
      {
        display: '2 stacks of crit rate',
        stats: [{ type: 'critRate', value: 0.16, refinementScaling: true }],
      },
      {
        display: '3 stacks of crit rate',
        stats: [{ type: 'critRate', value: 0.24, refinementScaling: true }],
      },
      {
        display: '4 stacks of crit rate',
        stats: [{ type: 'critRate', value: 0.32, refinementScaling: true }],
      },
      {
        display: '5 stacks of crit rate',
        stats: [{ type: 'critRate', value: 0.4, refinementScaling: true }],
      },
      { display: 'None' },
    ],
  },
  // prototypeMalice
  prototypeMalice: {
    name: 'prototypeMalice',
    stars: 4,
    type: 'catalyst',
    display: 'Prototype Malice',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'hpPercent', value: 0.413 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'hpPercent', value: 0.377 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'hpPercent', value: 0.341 },
      ],
    },
    bonuses: [
      // { type: "attackPercent", value: 0.6, refinementScaling: true }
    ],
    situationalBonuses: [{ display: 'None' }],
  },
  // mappaMare
  mappaMare: {
    name: 'mappaMare',
    stars: 4,
    type: 'catalyst',
    display: 'Mappa Mare',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'elementalMastery', value: 110 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'elementalMastery', value: 101 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'elementalMastery', value: 91 },
      ],
    },
    bonuses: [
      // { type: "attackPercent", value: 0.6, refinementScaling: true }
    ],
    situationalBonuses: [
      {
        display: '2 stacks of elemental damage',
        stats: [{ type: 'elementalDmg', value: 0.16, refinementScaling: true }],
      },
      {
        display: '1 stack of elemental damage',
        stats: [{ type: 'elementalDmg', value: 0.08, refinementScaling: true }],
      },
      { display: 'None' },
    ],
  },
  // favoniusCodex
  favoniusCodex: {
    name: 'favoniusCodex',
    stars: 4,
    type: 'catalyst',
    display: 'Favonius Codex',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'energyRecharge', value: 0.459 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'energyRecharge', value: 0.419 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'energyRecharge', value: 0.379 },
      ],
    },
    bonuses: [
      // { type: "attackPercent", value: 0.6, refinementScaling: true }
    ],
    situationalBonuses: [{ display: 'None' }],
  },
  // blackcliffAmulet
  blackcliffAmulet: {
    name: 'blackcliffAmulet',
    stars: 4,
    type: 'catalyst',
    display: 'Blackcliff Amulet',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'critDmg', value: 0.551 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'critDmg', value: 0.503 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'critDmg', value: 0.454 },
      ],
    },
    bonuses: [
      // { type: "attackPercent", value: 0.6, refinementScaling: true }
    ],
    situationalBonuses: [
      {
        display: '3 stacks of attack boosts',
        stats: [
          { type: 'attackPercent', value: 0.36, refinementScaling: true },
        ],
      },
      {
        display: '2 stacks of attack boosts',
        stats: [
          { type: 'attackPercent', value: 0.24, refinementScaling: true },
        ],
      },
      {
        display: '1 stack of attack boost',
        stats: [
          { type: 'attackPercent', value: 0.12, refinementScaling: true },
        ],
      },
      { display: 'None' },
    ],
  },
  // Dodoventure
  Dodoventure: {
    name: 'Dodoventure',
    stars: 4,
    type: 'catalyst',
    display: 'Dodoventure',
    levels: {
      90: [
        { type: 'weaponAttack', value: 454 },
        { type: 'attackPercent', value: 0.551 },
      ],
      80: [
        { type: 'weaponAttack', value: 401 },
        { type: 'attackPercent', value: 0.503 },
      ],
      70: [
        { type: 'weaponAttack', value: 347 },
        { type: 'attackPercent', value: 0.454 },
      ],
      1: [
        { type: 'weaponAttack', value: 41 },
        { type: 'attackPercent', value: 0.12 },
      ],
    },
    bonuses: [
      // { type: "attackPercent", value: 0.6, refinementScaling: true }
    ],
    situationalBonuses: [
      {
        display: 'Hit with CA and NA.',
        stats: [
          { type: 'attackPercent', value: 0.08, refinementScaling: true },
          { type: 'bonusDmg', value: 0.16, refinementScaling: true },
        ],
      },
      {
        display: 'Hit with NA',
        stats: [{ type: 'bonusDmg', value: 0.16, refinementScaling: true }],
      },
      {
        display: 'Hit with CA',
        stats: [
          { type: 'attackPercent', value: 0.08, refinementScaling: true },
        ],
      },
      { display: 'None' },
    ],
  },
  // twinNephrite
  twinNephrite: {
    name: 'twinNephrite',
    stars: 3,
    type: 'catalyst',
    display: 'Twin Nephrite',
    levels: {
      90: [
        { type: 'weaponAttack', value: 448 },
        { type: 'critRate', value: 0.156 },
      ],
      80: [
        { type: 'weaponAttack', value: 396 },
        { type: 'critRate', value: 0.142 },
      ],
      70: [
        { type: 'weaponAttack', value: 344 },
        { type: 'critRate', value: 0.129 },
      ],
    },
    bonuses: [
      // { type: "attackPercent", value: 0.6, refinementScaling: true }
    ],
    situationalBonuses: [
      {
        display: 'Attack boost from defeating an enemy',
        stats: [
          { type: 'attackPercent', value: 0.12, refinementScaling: true },
        ],
      },
      { display: 'None' },
    ],
  },
  // magicGuide
  magicGuide: {
    name: 'magicGuide',
    stars: 3,
    type: 'catalyst',
    display: 'Magic Guide',
    levels: {
      90: [
        { type: 'weaponAttack', value: 354 },
        { type: 'elementalMastery', value: 187 },
      ],
      80: [
        { type: 'weaponAttack', value: 314 },
        { type: 'elementalMastery', value: 171 },
      ],
      70: [
        { type: 'weaponAttack', value: 274 },
        { type: 'elementalMastery', value: 154 },
      ],
      1: [
        { type: 'weaponAttack', value: 38 },
        { type: 'elementalMastery', value: 41 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Hitting enemy with Hydro or Electro.',
        stats: [{ type: 'bonusDmg', value: 0.12, refinementScaling: true }],
      },
      { display: 'None' },
    ],
  },
  // emeraldOrb
  emeraldOrb: {
    name: 'emeraldOrb',
    stars: 3,
    type: 'catalyst',
    display: 'Emerald Orb',
    levels: {
      90: [
        { type: 'weaponAttack', value: 448 },
        { type: 'elementalMastery', value: 94 },
      ],
      80: [
        { type: 'weaponAttack', value: 396 },
        { type: 'elementalMastery', value: 85 },
      ],
      70: [
        { type: 'weaponAttack', value: 344 },
        { type: 'elementalMastery', value: 77 },
      ],
      1: [
        { type: 'weaponAttack', value: 40 },
        { type: 'elementalMastery', value: 20 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Did elemental reaction.',
        stats: [{ type: 'attackPercent', value: 0.2, refinementScaling: true }],
      },
      { display: 'None' },
    ],
  },
};

var polearms = {
  // staffOfTheScarletSands
  // staffOfTheScarletSands: {
  //   name: 'staffOfTheScarletSands',
  //   stars: 5,
  //   type: 'polearm',
  //   display: 'Staff of the Scarlet Sands',
  //   levels: {
  //     90: [
  //       { type: 'weaponAttack', value: 541 },
  //       { type: 'critRate', value: 0.441 },
  //     ],
  //   },
  //   bonuses: [
  //     {
  //       type: 'emScalingAttack',
  //       value: 0.52,
  //       refinementScaling: true,
  //     },
  //   ],
  //   situationalBonuses: [
  //     {
  //       display: '3 stacks of E hitting enemy',
  //       stats: [
  //         {
  //           type: 'emScalingAttack',
  //           value: 0.84,
  //           refinementScaling: true,
  //         },
  //       ],
  //     },
  //     {
  //       display: '2 stacks of E hitting enemy',
  //       stats: [
  //         {
  //           type: 'emScalingAttack',
  //           value: 0.56,
  //           refinementScaling: true,
  //         },
  //       ],
  //     },
  //     {
  //       display: '1 stack of E hitting enemy',
  //       stats: [
  //         {
  //           type: 'emScalingAttack',
  //           value: 0.28,
  //           refinementScaling: true,
  //         },
  //       ],
  //     },
  //     { display: 'None', stats: [] },
  //   ],
  // },
  // engulfingLightning
  engulfingLightning: {
    name: 'engulfingLightning',
    stars: 5,
    type: 'polearm',
    display: 'Engulfing Lightning',
    levels: {
      90: [
        { type: 'weaponAttack', value: 608 },
        { type: 'energyRecharge', value: 0.551 },
      ],
      80: [
        { type: 'weaponAttack', value: 532 },
        { type: 'energyRecharge', value: 0.503 },
      ],
      70: [
        { type: 'weaponAttack', value: 457 },
        { type: 'energyRecharge', value: 0.454 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Attack Bonus after burst',
        stats: [
          {
            type: 'energyRecharge',
            value: 0.3,
            refinementScaling: true,
            customRefine: 1 / 6,
          },
          {
            type: 'engulfingLightningAttackBoost',
            value: 0.28,
            refinementScaling: true,
          },
          {
            type: 'engulfingLightningMax',
            value: 0.8,
            refinementScaling: true,
            customRefine: 1 / 8,
          },
        ],
      },
      {
        display: 'Attack Bonus without burst',
        stats: [
          {
            type: 'engulfingLightningAttackBoost',
            value: 0.28,
            refinementScaling: true,
          },
          {
            type: 'engulfingLightningMax',
            value: 0.8,
            refinementScaling: true,
            customRefine: 1 / 8,
          },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  // primordialJade
  primordialJadeWingedSpear: {
    name: 'primordialJadeWingedSpear',
    stars: 5,
    type: 'polearm',
    display: 'Primordial Jade Winged-Spear',
    levels: {
      90: [
        { type: 'weaponAttack', value: 674 },
        { type: 'critRate', value: 0.221 },
      ],
      80: [
        { type: 'weaponAttack', value: 590 },
        { type: 'critRate', value: 0.201 },
      ],
      70: [
        { type: 'weaponAttack', value: 506 },
        { type: 'critRate', value: 0.182 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '7 on-hit stacks',
        stats: [
          {
            type: 'attackPercent',
            value: 0.224,
            refinementScaling: true,
            customRefine: 0.21875,
          },
          {
            type: 'bonusDmg',
            value: 0.12,
            refinementScaling: true,
          },
        ],
      },
      {
        display: '6 on-hit stacks',
        stats: [
          {
            type: 'attackPercent',
            value: 0.192,
            refinementScaling: true,
            customRefine: 0.21875,
          },
        ],
      },
      {
        display: '5 on-hit stacks',
        stats: [
          {
            type: 'attackPercent',
            value: 0.16,
            refinementScaling: true,
            customRefine: 0.21875,
          },
        ],
      },
      {
        display: '4 on-hit stacks',
        stats: [
          {
            type: 'attackPercent',
            value: 0.128,
            refinementScaling: true,
            customRefine: 0.21875,
          },
        ],
      },
      {
        display: '3 on-hit stacks',
        stats: [
          {
            type: 'attackPercent',
            value: 0.096,
            refinementScaling: true,
            customRefine: 0.21875,
          },
        ],
      },
      {
        display: '2 on-hit stacks',
        stats: [
          {
            type: 'attackPercent',
            value: 0.064,
            refinementScaling: true,
            customRefine: 0.21875,
          },
        ],
      },
      {
        display: '1 on-hit stack',
        stats: [
          {
            type: 'attackPercent',
            value: 0.032,
            refinementScaling: true,
            customRefine: 0.21875,
          },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  // skywardSpine
  skywardSpine: {
    name: 'skywardSpine',
    stars: 5,
    type: 'polearm',
    display: 'Skyward Spine',
    levels: {
      90: [
        { type: 'weaponAttack', value: 674 },
        { type: 'energyRecharge', value: 0.368 },
      ],
      80: [
        { type: 'weaponAttack', value: 590 },
        { type: 'energyRecharge', value: 0.335 },
      ],
      70: [
        { type: 'weaponAttack', value: 506 },
        { type: 'energyRecharge', value: 0.303 },
      ],
    },
    bonuses: [{ type: 'critRate', value: 0.08, refinementScaling: true }],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  // vortexVanquisher
  vortexVanquisher: {
    name: 'vortexVanquisher',
    stars: 5,
    type: 'polearm',
    display: 'Vortex Vanquisher',
    levels: {
      90: [
        { type: 'weaponAttack', value: 608 },
        { type: 'attackPercent', value: 0.496 },
      ],
      80: [
        { type: 'weaponAttack', value: 532 },
        { type: 'attackPercent', value: 0.453 },
      ],
      70: [
        { type: 'weaponAttack', value: 457 },
        { type: 'attackPercent', value: 0.409 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '5 stacks of boost with shield',
        stats: [{ type: 'attackPercent', value: 0.4, refinementScaling: true }],
      },
      {
        display: '5 stacks of boost without shield',
        stats: [{ type: 'attackPercent', value: 0.2, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // CalamtiyQueller
  calamityQueller: {
    name: 'calamityQueller',
    stars: 5,
    type: 'polearm',
    display: 'Calamity Queller',
    levels: {
      90: [
        { type: 'weaponAttack', value: 741 },
        { type: 'attackPercent', value: 0.165 },
      ],
    },
    bonuses: [{ type: 'elementalDmg', value: 0.12, refinementScaling: true }],
    situationalBonuses: [
      {
        display: '6 stacks and off field',
        stats: [
          { type: 'attackPercent', value: 0.384, refinementScaling: true },
        ],
      },
      {
        display: '6 stacks and on field',
        stats: [
          { type: 'attackPercent', value: 0.192, refinementScaling: true },
        ],
      },
      {
        display: '4 stacks and off field',
        stats: [
          { type: 'attackPercent', value: 0.256, refinementScaling: true },
        ],
      },
      {
        display: '4 stacks and on field',
        stats: [
          { type: 'attackPercent', value: 0.128, refinementScaling: true },
        ],
      },
      {
        display: '2 stacks and off field',
        stats: [
          { type: 'attackPercent', value: 0.128, refinementScaling: true },
        ],
      },
      {
        display: '2 stacks and on field',
        stats: [
          { type: 'attackPercent', value: 0.064, refinementScaling: true },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  staffOfHoma: {
    name: 'staffOfHoma',
    stars: 5,
    type: 'polearm',
    display: 'Staff of Homa',
    levels: {
      90: [
        { type: 'weaponAttack', value: 608 },
        { type: 'critDmg', value: 0.662 },
      ],
      80: [
        { type: 'weaponAttack', value: 532 },
        { type: 'critDmg', value: 0.603 },
      ],
      70: [
        { type: 'weaponAttack', value: 457 },
        { type: 'critDmg', value: 0.545 },
      ],
    },
    bonuses: [
      { type: 'hpPercent', value: 0.2, refinementScaling: true },
      { type: 'hpScalingAttack', value: 0.008, refinementScaling: true },
    ],
    situationalBonuses: [
      {
        display: 'Less than 50% HP.',
        stats: [
          {
            type: 'hpScalingAttack',
            value: 0.01,
            refinementScaling: true,
            customRefine: 1 / 5,
          },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  // // moonpiercer
  // moonpiercer: {
  //   name: 'moonpiercer',
  //   stars: 4,
  //   type: 'polearm',
  //   display: 'Moonpiercer',
  //   levels: {
  //     90: [
  //       { type: 'weaponAttack', value: 564 },
  //       { type: 'elementalMastery', value: 110 },
  //     ],
  //   },
  //   bonuses: [],
  //   situationalBonuses: [
  //     {
  //       display: 'Grabbed leaf thing',
  //       stats: [
  //         {
  //           type: 'attackPercent',
  //           value: 0.16,
  //           refinementScaling: true,
  //         },
  //       ],
  //     },
  //     { display: 'None', stats: [] },
  //   ],
  // },
  // missiveWindspear: {
  //   name: 'missiveWindspear',
  //   stars: 4,
  //   type: 'polearm',
  //   display: 'Missive Windspear',
  //   levels: {
  //     90: [
  //       { type: 'weaponAttack', value: 509 },
  //       { type: 'attackPercent', value: 0.4135 },
  //     ],
  //   },
  //   bonuses: [],
  //   situationalBonuses: [
  //     {
  //       display: 'Did an elemental reaction',
  //       stats: [
  //         {
  //           type: 'attackPercent',
  //           value: 0.12,
  //           refinementScaling: true,
  //         },
  //         {
  //           type: 'elementalMastery',
  //           value: 48,
  //           refinementScaling: true,
  //         },
  //       ],
  //     },
  //     { display: 'None', stats: [] },
  //   ],
  // },
  // dragonspineSpear
  dragonspineSpear: {
    name: 'dragonspineSpear',
    stars: 4,
    type: 'polearm',
    display: 'Dragonspine Spear',
    levels: {
      90: [
        { type: 'weaponAttack', value: 454 },
        { type: 'physicalDmg', value: 0.69 },
      ],
      80: [
        { type: 'weaponAttack', value: 401 },
        { type: 'physicalDmg', value: 0.629 },
      ],
      70: [
        { type: 'weaponAttack', value: 347 },
        { type: 'physicalDmg', value: 0.568 },
      ],
    },
    bonuses: [],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  // crescentPike
  crescentPike: {
    name: 'crescentPike',
    stars: 4,
    type: 'polearm',
    display: 'Crescent Pike',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'physicalDmg', value: 0.345 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'physicalDmg', value: 0.315 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'physicalDmg', value: 0.284 },
      ],
    },
    bonuses: [],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  // waveBreakersFin
  waveBreakersFin: {
    name: 'waveBreakersFin',
    stars: 4,
    type: 'polearm',
    display: "Wavebreaker's Fin",
    levels: {
      90: [
        { type: 'weaponAttack', value: 620 },
        { type: 'attackPercent', value: 0.138 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'attackPercent', value: 0.126 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'attackPercent', value: 0.114 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '334+ Energy and using Burst',
        stats: [{ type: 'bonusDmg', value: 0.4, refinementScaling: true }],
      },
      {
        display: '320 Energy and using Burst',
        stats: [{ type: 'bonusDmg', value: 0.384, refinementScaling: true }],
      },
      {
        display: '300 Energy and using Burst',
        stats: [{ type: 'bonusDmg', value: 0.36, refinementScaling: true }],
      },
      {
        display: '290 Energy and using Burst',
        stats: [{ type: 'bonusDmg', value: 0.348, refinementScaling: true }],
      },
      {
        display: '240 Energy and using Burst',
        stats: [{ type: 'bonusDmg', value: 0.288, refinementScaling: true }],
      },
      {
        display: '160 Energy and using Burst',
        stats: [{ type: 'bonusDmg', value: 0.192, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // royalSpear
  royalSpear: {
    name: 'royalSpear',
    stars: 4,
    type: 'polearm',
    display: 'Royal Spear',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'attackPercent', value: 0.276 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'attackPercent', value: 0.251 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'attackPercent', value: 0.227 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '1 stack of crit rate',
        stats: [{ type: 'critRate', value: 0.08, refinementScaling: true }],
      },
      {
        display: '2 stacks of crit rate',
        stats: [{ type: 'critRate', value: 0.16, refinementScaling: true }],
      },
      {
        display: '3 stacks of crit rate',
        stats: [{ type: 'critRate', value: 0.24, refinementScaling: true }],
      },
      {
        display: '4 stacks of crit rate',
        stats: [{ type: 'critRate', value: 0.32, refinementScaling: true }],
      },
      {
        display: '5 stacks of crit rate',
        stats: [{ type: 'critRate', value: 0.4, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // favoniusLance
  favoniusLance: {
    name: 'favoniusLance',
    stars: 4,
    type: 'polearm',
    display: 'Favonius Lance',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'energyRecharge', value: 0.306 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'energyRecharge', value: 0.279 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'energyRecharge', value: 0.252 },
      ],
      1: [
        { type: 'weaponAttack', value: 44 },
        { type: 'energyRecharge', value: 0.067 },
      ],
    },
    bonuses: [],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  // prototypeGrudge
  prototypeGrudge: {
    name: 'prototypeGrudge',
    stars: 4,
    type: 'polearm',
    display: 'Prototype Grudge',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'energyRecharge', value: 0.459 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'energyRecharge', value: 0.419 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'energyRecharge', value: 0.379 },
      ],
      1: [
        { type: 'weaponAttack', value: 42 },
        { type: 'energyRecharge', value: 0.1 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '2 stacks for normal/charged attacks',
        stats: [{ type: 'bonusDmg', value: 0.16, refinementScaling: true }],
      },
      {
        display: '1 stack for normal/charged attacks',
        stats: [{ type: 'bonusDmg', value: 0.08, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // lithicSpear
  lithicSpear: {
    name: 'lithicSpear',
    stars: 4,
    type: 'polearm',
    display: 'Lithic Spear',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'attackPercent', value: 0.276 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'attackPercent', value: 0.251 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'attackPercent', value: 0.227 },
      ],
      1: [
        { type: 'weaponAttack', value: 44 },
        { type: 'attackPercent', value: 0.06 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '4 from Liyue',
        stats: [
          {
            type: 'attackPercent',
            value: 0.28,
            refinementScaling: true,
            customRefine: 1 / 7,
          },
          {
            type: 'critRate',
            value: 0.12,
            refinementScaling: true,
            customRefine: 1 / 3,
          },
        ],
      },
      {
        display: '3 from Liyue',
        stats: [
          {
            type: 'attackPercent',
            value: 0.21,
            refinementScaling: true,
            customRefine: 1 / 7,
          },
          {
            type: 'critRate',
            value: 0.09,
            refinementScaling: true,
            customRefine: 1 / 3,
          },
        ],
      },
      {
        display: '2 from Liyue',
        stats: [
          {
            type: 'attackPercent',
            value: 0.14,
            refinementScaling: true,
            customRefine: 1 / 7,
          },
          {
            type: 'critRate',
            value: 0.06,
            refinementScaling: true,
            customRefine: 1 / 3,
          },
        ],
      },
      {
        display: '1 from Liyue',
        stats: [
          {
            type: 'attackPercent',
            value: 0.07,
            refinementScaling: true,
            customRefine: 1 / 7,
          },
          {
            type: 'critRate',
            value: 0.03,
            refinementScaling: true,
            customRefine: 1 / 3,
          },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  // dragonsBane
  dragonsBane: {
    name: 'dragonsBane',
    stars: 4,
    type: 'polearm',
    display: "Dragon's Bane",
    levels: {
      90: [
        { type: 'weaponAttack', value: 454 },
        { type: 'elementalMastery', value: 221 },
      ],
      80: [
        { type: 'weaponAttack', value: 401 },
        { type: 'elementalMastery', value: 201 },
      ],
      70: [
        { type: 'weaponAttack', value: 347 },
        { type: 'elementalMastery', value: 182 },
      ],
      1: [
        { type: 'weaponAttack', value: 41 },
        { type: 'elementalMastery', value: 48 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Hitting enemy with Hydro or Pyro debuff',
        stats: [
          {
            type: 'bonusDmg',
            value: 0.2,
            refinementScaling: true,
            customRefine: 1 / 5,
          },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  // deathMatch
  deathMatch: {
    name: 'deathMatch',
    stars: 4,
    type: 'polearm',
    display: 'Death Match',
    levels: {
      90: [
        { type: 'weaponAttack', value: 454 },
        { type: 'critRate', value: 0.303 },
      ],
      80: [
        { type: 'weaponAttack', value: 401 },
        { type: 'critRate', value: 0.335 },
      ],
      70: [
        { type: 'weaponAttack', value: 347 },
        { type: 'critRate', value: 0.368 },
      ],
      1: [
        { type: 'weaponAttack', value: 41 },
        { type: 'critRate', value: 0.08 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '2+ enemies nearby',
        stats: [
          { type: 'attackPercent', value: 0.16, refinementScaling: true },
        ],
      },
      {
        display: '1 enemy nearby',
        stats: [
          { type: 'attackPercent', value: 0.24, refinementScaling: true },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  // blackcliffPole
  blackcliffPole: {
    name: 'blackcliffPole',
    stars: 4,
    type: 'polearm',
    display: 'Blackcliff Pole',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'critDmg', value: 0.551 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'critDmg', value: 0.503 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'critDmg', value: 0.454 },
      ],
      1: [
        { type: 'weaponAttack', value: 42 },
        { type: 'critDmg', value: 0.12 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Defeated 3 enemies',
        stats: [
          { type: 'attackPercent', value: 0.36, refinementScaling: true },
        ],
      },
      {
        display: 'Defeated 2 enemies',
        stats: [
          { type: 'attackPercent', value: 0.24, refinementScaling: true },
        ],
      },
      {
        display: 'Defeated 1 enemy',
        stats: [
          { type: 'attackPercent', value: 0.12, refinementScaling: true },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  // theCatch
  theCatch: {
    name: 'theCatch',
    stars: 4,
    type: 'polearm',
    display: 'The Catch',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'energyRecharge', value: 0.459 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'energyRecharge', value: 0.419 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'energyRecharge', value: 0.379 },
      ],
      1: [
        { type: 'weaponAttack', value: 42 },
        { type: 'energyRecharge', value: 0.1 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Using Elemental Burst',
        stats: [
          { type: 'bonusDmg', value: 0.16, refinementScaling: true },
          { type: 'critRate', value: 0.06, refinementScaling: true },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  // whiteTassel
  whiteTassel: {
    name: 'whiteTassel',
    stars: 3,
    type: 'polearm',
    display: 'White Tassel',
    levels: {
      90: [
        { type: 'weaponAttack', value: 401 },
        { type: 'critRate', value: 0.234 },
      ],
      80: [
        { type: 'weaponAttack', value: 355 },
        { type: 'critRate', value: 0.214 },
      ],
      70: [
        { type: 'weaponAttack', value: 309 },
        { type: 'critRate', value: 0.193 },
      ],
      1: [
        { type: 'weaponAttack', value: 39 },
        { type: 'critRate', value: 0.051 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Normal attack bonus damage',
        stats: [{ type: 'bonusDmg', value: 0.24, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // blackTassel
  blackTassel: {
    name: 'blackTassel',
    stars: 3,
    type: 'polearm',
    display: 'Black Tassel',
    levels: {
      90: [
        { type: 'weaponAttack', value: 354 },
        { type: 'hpPercent', value: 0.469 },
      ],
      80: [
        { type: 'weaponAttack', value: 314 },
        { type: 'hpPercent', value: 0.428 },
      ],
      70: [
        { type: 'weaponAttack', value: 274 },
        { type: 'hpPercent', value: 0.387 },
      ],
      1: [
        { type: 'weaponAttack', value: 38 },
        { type: 'hpPercent', value: 0.102 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Bonus Damage vs Slimes',
        stats: [{ type: 'bonusDmg', value: 0.4, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
};

var bows = {
  aquaSimulacra: {
    name: 'aquaSimulacra',
    stars: 5,
    type: 'bow',
    display: 'Aqua Simulacra',
    levels: {
      90: [
        { type: 'weaponAttack', value: 542 },
        { type: 'critDmg', value: 0.882 },
      ],
    },
    bonuses: [{ type: 'hpPercent', value: 0.16, refinementScaling: true }],
    situationalBonuses: [
      {
        display: 'Enemy nearby',
        stats: [{ type: 'bonusDmg', value: 0.2, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  huntersPath: {
    name: 'huntersPath',
    stars: 5,
    type: 'bow',
    display: "Hunter's Path",
    levels: {
      90: [
        { type: 'weaponAttack', value: 542 },
        { type: 'critDmg', value: 0.441 },
      ],
    },
    bonuses: [{ type: 'elementalDmg', value: 0.12, refinementScaling: true }],
    situationalBonuses: [
      {
        display: '12 instances of CA damage',
        stats: [
          { type: 'emScalingFlatDamage', value: 19.2, refinementScaling: true },
        ],
      },
      {
        display: '11 instances of CA damage',
        stats: [
          { type: 'emScalingFlatDamage', value: 17.6, refinementScaling: true },
        ],
      },
      {
        display: '10 instances of CA damage',
        stats: [
          { type: 'emScalingFlatDamage', value: 16, refinementScaling: true },
        ],
      },
      {
        display: '9 instances of CA damage',
        stats: [
          { type: 'emScalingFlatDamage', value: 14.4, refinementScaling: true },
        ],
      },
      {
        display: '8 instances of CA damage',
        stats: [
          { type: 'emScalingFlatDamage', value: 12.8, refinementScaling: true },
        ],
      },
      {
        display: '7 instances of CA damage',
        stats: [
          { type: 'emScalingFlatDamage', value: 11.2, refinementScaling: true },
        ],
      },
      {
        display: '6 instances of CA damage',
        stats: [
          { type: 'emScalingFlatDamage', value: 9.6, refinementScaling: true },
        ],
      },
      {
        display: '5 instances of CA damage',
        stats: [
          { type: 'emScalingFlatDamage', value: 8, refinementScaling: true },
        ],
      },
      {
        display: '4 instances of CA damage',
        stats: [
          { type: 'emScalingFlatDamage', value: 6.4, refinementScaling: true },
        ],
      },
      {
        display: '3 instances of CA damage',
        stats: [
          { type: 'emScalingFlatDamage', value: 4.8, refinementScaling: true },
        ],
      },
      {
        display: '2 instances of CA damage',
        stats: [
          { type: 'emScalingFlatDamage', value: 3.2, refinementScaling: true },
        ],
      },
      {
        display: '1 instance of CA damage',
        stats: [
          { type: 'emScalingFlatDamage', value: 1.6, refinementScaling: true },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  thunderingPulse: {
    name: 'thunderingPulse',
    stars: 5,
    type: 'bow',
    display: 'Thundering Pulse',
    levels: {
      90: [
        { type: 'weaponAttack', value: 608 },
        { type: 'critDmg', value: 0.662 },
      ],
      80: [
        { type: 'weaponAttack', value: 532 },
        { type: 'critDmg', value: 0.603 },
      ],
      70: [
        { type: 'weaponAttack', value: 457 },
        { type: 'critDmg', value: 0.545 },
      ],
    },
    bonuses: [{ type: 'attackPercent', value: 0.2, refinementScaling: true }],
    situationalBonuses: [
      {
        display: '3 stacks',
        stats: [{ type: 'bonusDmg', value: 0.4, refinementScaling: true }],
      },
      {
        display: '2 stacks',
        stats: [{ type: 'bonusDmg', value: 0.24, refinementScaling: true }],
      },
      {
        display: '1 stack',
        stats: [{ type: 'bonusDmg', value: 0.12, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },

  // skywardHarp
  skywardHarp: {
    name: 'skywardHarp',
    stars: 5,
    type: 'bow',
    display: 'Skyward Harp',
    levels: {
      90: [
        { type: 'weaponAttack', value: 674 },
        { type: 'critRate', value: 0.221 },
      ],
      80: [
        { type: 'weaponAttack', value: 590 },
        { type: 'critRate', value: 0.201 },
      ],
      70: [
        { type: 'weaponAttack', value: 506 },
        { type: 'critRate', value: 0.182 },
      ],
    },
    bonuses: [{ type: 'critDmg', value: 0.2, refinementScaling: true }],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  // amosBow
  amosBow: {
    name: 'amosBow',
    stars: 5,
    type: 'bow',
    display: 'Amos Bow',
    levels: {
      90: [
        { type: 'weaponAttack', value: 608 },
        { type: 'attackPercent', value: 0.496 },
      ],
      80: [
        { type: 'weaponAttack', value: 532 },
        { type: 'attackPercent', value: 0.453 },
      ],
      70: [
        { type: 'weaponAttack', value: 457 },
        { type: 'attackPercent', value: 0.409 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Normal/Charged atk with 0.5 seconds fly time',
        stats: [{ type: 'bonusDmg', value: 0.52, refinementScaling: true }],
      },
      {
        display: 'Normal/Charged atk with 0.4 seconds fly time',
        stats: [{ type: 'bonusDmg', value: 0.44, refinementScaling: true }],
      },
      {
        display: 'Normal/Charged atk with 0.3 seconds fly time',
        stats: [{ type: 'bonusDmg', value: 0.36, refinementScaling: true }],
      },
      {
        display: 'Normal/Charged atk with 0.2 seconds fly time',
        stats: [{ type: 'bonusDmg', value: 0.28, refinementScaling: true }],
      },
      {
        display: 'Normal/Charged atk with 0.1 seconds fly time',
        stats: [{ type: 'bonusDmg', value: 0.2, refinementScaling: true }],
      },
      {
        display: 'Normal/Charged atk with 0 second fly time',
        stats: [{ type: 'bonusDmg', value: 0.12, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // elegyForTheEnd
  elegyForTheEnd: {
    name: 'elegyForTheEnd',
    stars: 5,
    type: 'bow',
    display: 'Elegy for the End',
    levels: {
      90: [
        { type: 'weaponAttack', value: 608 },
        { type: 'energyRecharge', value: 0.551 },
      ],
      80: [
        { type: 'weaponAttack', value: 532 },
        { type: 'energyRecharge', value: 0.503 },
      ],
      70: [
        { type: 'weaponAttack', value: 457 },
        { type: 'energyRecharge', value: 0.454 },
      ],
      1: [
        { type: 'weaponAttack', value: 46 },
        { type: 'energyRecharge', value: 0.12 },
      ],
    },
    bonuses: [{ type: 'elementalMastery', value: 60, refinementScaling: true }],
    situationalBonuses: [
      {
        display: '4 Stack Buff Up (12s duration, 20s cooldown)',
        stats: [
          { type: 'elementalMastery', value: 100, refinementScaling: true },
          { type: 'attackPercent', value: 0.2, refinementScaling: true },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  // polarStar
  polarStar: {
    name: 'polarStar',
    stars: 5,
    type: 'bow',
    display: 'Polar Star',
    levels: {
      90: [
        { type: 'weaponAttack', value: 608 },
        { type: 'critRate', value: 0.331 },
      ],
      80: [
        { type: 'weaponAttack', value: 532 },
        { type: 'critRate', value: 0.302 },
      ],
      70: [
        { type: 'weaponAttack', value: 457 },
        { type: 'critRate', value: 0.273 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '4 stacks',
        stats: [
          { type: 'attackPercent', value: 0.48, refinementScaling: true },
        ],
      },
      {
        display: '3 stacks',
        stats: [{ type: 'attackPercent', value: 0.3, refinementScaling: true }],
      },
      {
        display: '2 stacks',
        stats: [{ type: 'attackPercent', value: 0.2, refinementScaling: true }],
      },
      {
        display: '1 stack',
        stats: [{ type: 'attackPercent', value: 0.1, refinementScaling: true }],
      },
      {
        display: '4 stacks and burst or skill',
        stats: [
          { type: 'attackPercent', value: 0.48, refinementScaling: true },
          { type: 'bonusDmg', value: 0.12, refinementScaling: true },
        ],
      },
      {
        display: '3 stacks and burst or skill',
        stats: [
          { type: 'attackPercent', value: 0.3, refinementScaling: true },
          { type: 'bonusDmg', value: 0.12, refinementScaling: true },
        ],
      },
      {
        display: '2 stacks and burst or skill',
        stats: [
          { type: 'attackPercent', value: 0.2, refinementScaling: true },
          { type: 'bonusDmg', value: 0.12, refinementScaling: true },
        ],
      },
      {
        display: '1 stack and burst or skill',
        stats: [
          { type: 'attackPercent', value: 0.1, refinementScaling: true },
          { type: 'bonusDmg', value: 0.12, refinementScaling: true },
        ],
      },
      {
        display: '0 stacks and burst or skill',
        stats: [{ type: 'bonusDmg', value: 0.12, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  hamayumi: {
    name: 'hamayumi',
    stars: 4,
    type: 'bow',
    display: 'Hamayumi',
    levels: {
      90: [
        { type: 'weaponAttack', value: 454 },
        { type: 'attackPercent', value: 0.551 },
      ],
      80: [
        { type: 'weaponAttack', value: 401 },
        { type: 'attackPercent', value: 0.503 },
      ],
      70: [
        { type: 'weaponAttack', value: 347 },
        { type: 'attackPercent', value: 0.44 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Normal Attack DMG with 100% energy',
        stats: [{ type: 'bonusDmg', value: 0.32, refinementScaling: true }],
      },
      {
        display: 'Charged Attack DMG with 100% energy',
        stats: [{ type: 'bonusDmg', value: 0.24, refinementScaling: true }],
      },
      {
        display: 'Normal Attack DMG with <100% energy',
        stats: [{ type: 'bonusDmg', value: 0.16, refinementScaling: true }],
      },
      {
        display: 'Charged Attack DMG with <100% energy',
        stats: [{ type: 'bonusDmg', value: 0.12, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  fadingTwilight: {
    name: 'fadingTwilight',
    stars: 4,
    type: 'bow',
    display: 'Fading Twilight',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'energyRecharge', value: 0.306 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Evengleam',
        stats: [{ type: 'bonusDmg', value: 0.06, refinementScaling: true }],
      },
      {
        display: 'Afterglow',
        stats: [{ type: 'bonusDmg', value: 0.1, refinementScaling: true }],
      },
      {
        display: 'Dawnblaze',
        stats: [{ type: 'bonusDmg', value: 0.14, refinementScaling: true }],
      },
    ],
  },
  // mouunsMoon
  mouunsMoon: {
    name: 'mouunsMoon',
    stars: 4,
    type: 'bow',
    display: "Mouun's Moon",
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'attackPercent', value: 0.276 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '334+ Energy and using Burst',
        stats: [{ type: 'bonusDmg', value: 0.4, refinementScaling: true }],
      },
      {
        display: '320 Energy and using Burst',
        stats: [{ type: 'bonusDmg', value: 0.384, refinementScaling: true }],
      },
      {
        display: '300 Energy and using Burst',
        stats: [{ type: 'bonusDmg', value: 0.36, refinementScaling: true }],
      },
      {
        display: '290 Energy and using Burst',
        stats: [{ type: 'bonusDmg', value: 0.348, refinementScaling: true }],
      },
      {
        display: '240 Energy and using Burst',
        stats: [{ type: 'bonusDmg', value: 0.288, refinementScaling: true }],
      },
      {
        display: '160 Energy and using Burst',
        stats: [{ type: 'bonusDmg', value: 0.192, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // windblumeOde
  windblumeOde: {
    name: 'windblumeOde',
    stars: 4,
    type: 'bow',
    display: 'Windblume Ode',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'elementalMastery', value: 165 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'elementalMastery', value: 151 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'elementalMastery', value: 136 },
      ],
      0: [
        { type: 'weaponAttack', value: 39 },
        { type: 'elementalMastery', value: 36 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Used Skill (6 second duration)',
        stats: [
          { type: 'attackPercent', value: 0.16, refinementScaling: true },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  // prototypeCrescent
  prototypeCrescent: {
    name: 'prototypeCrescent',
    stars: 4,
    type: 'bow',
    display: 'Prototype Crescent',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'attackPercent', value: 0.413 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'attackPercent', value: 0.377 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'attackPercent', value: 0.341 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Charged Attack hit weakpoint',
        stats: [
          { type: 'attackPercent', value: 0.36, refinementScaling: true },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  // favoniusWarbow
  favoniusWarbow: {
    name: 'favoniusWarbow',
    stars: 4,
    type: 'bow',
    display: 'Favonius Warbow',
    levels: {
      90: [
        { type: 'weaponAttack', value: 454 },
        { type: 'energyRecharge', value: 0.613 },
      ],
      80: [
        { type: 'weaponAttack', value: 401 },
        { type: 'energyRecharge', value: 0.559 },
      ],
      70: [
        { type: 'weaponAttack', value: 347 },
        { type: 'energyRecharge', value: 0.505 },
      ],
    },
    bonuses: [],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  // compoundBow
  compoundBow: {
    name: 'compoundBow',
    stars: 4,
    type: 'bow',
    display: 'Compound Bow',
    levels: {
      90: [
        { type: 'weaponAttack', value: 454 },
        { type: 'physicalDmg', value: 0.69 },
      ],
      80: [
        { type: 'weaponAttack', value: 401 },
        { type: 'physicalDmg', value: 0.629 },
      ],
      70: [
        { type: 'weaponAttack', value: 347 },
        { type: 'physicalDmg', value: 0.568 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '4 stacks',
        stats: [
          { type: 'attackPercent', value: 0.16, refinementScaling: true },
        ],
      },
      {
        display: '3 stacks',
        stats: [
          { type: 'attackPercent', value: 0.12, refinementScaling: true },
        ],
      },
      {
        display: '2 stacks',
        stats: [
          { type: 'attackPercent', value: 0.08, refinementScaling: true },
        ],
      },
      {
        display: '1 stacks',
        stats: [
          { type: 'attackPercent', value: 0.04, refinementScaling: true },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  // blackcliffWarbow
  blackcliffWarbow: {
    name: 'blackcliffWarbow',
    stars: 4,
    type: 'bow',
    display: 'Blackcliff Warbow',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'critDmg', value: 0.368 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'critDmg', value: 0.335 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'critDmg', value: 0.303 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '3 stacks after defeating enemies',
        stats: [
          { type: 'attackPercent', value: 0.36, refinementScaling: true },
        ],
      },
      {
        display: '2 stacks after defeating enemies',
        stats: [
          { type: 'attackPercent', value: 0.24, refinementScaling: true },
        ],
      },
      {
        display: '1 stacks after defeating enemy',
        stats: [
          { type: 'attackPercent', value: 0.12, refinementScaling: true },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  // kingsSquire
  kingsSquire: {
    name: 'kingsSquire',
    stars: 4,
    type: 'bow',
    display: "King's Squire",
    levels: {
      90: [
        { type: 'weaponAttack', value: 454 },
        { type: 'attackPercent', value: 0.5513 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Elemental Mastery active',
        stats: [
          {
            type: 'elementalMastery',
            value: 60,
            refinementScaling: true,
            customRefine: 1 / 3,
          },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  // alleyHunter
  alleyHunter: {
    name: 'alleyHunter',
    stars: 4,
    type: 'bow',
    display: 'Alley Hunter',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'attackPercent', value: 0.276 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'attackPercent', value: 0.251 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'attackPercent', value: 0.227 },
      ],
      1: [
        { type: 'weaponAttack', value: 44 },
        { type: 'attackPercent', value: 0.06 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '10 stacks from staying off field',
        stats: [{ type: 'bonusDmg', value: 0.2, refinementScaling: true }],
      },
      {
        display: '5 stacks from staying off field',
        stats: [{ type: 'bonusDmg', value: 0.1, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // theStringless
  theStringless: {
    name: 'theStringless',
    stars: 4,
    type: 'bow',
    display: 'The Stringless',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'elementalMastery', value: 165 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'elementalMastery', value: 151 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'elementalMastery', value: 136 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Elemental Skill and Elemental Burst',
        stats: [{ type: 'bonusDmg', value: 0.24, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // royalBow
  royalBow: {
    name: 'royalBow',
    stars: 4,
    type: 'bow',
    display: 'RoyalBow',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'attackPercent', value: 0.413 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'attackPercent', value: 0.377 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'attackPercent', value: 0.341 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '1 stack boost on non-crit hit',
        stats: [{ type: 'critRate', value: 0.08, refinementScaling: true }],
      },
      {
        display: '2 stack boost on non-crit hit',
        stats: [{ type: 'critRate', value: 0.16, refinementScaling: true }],
      },
      {
        display: '3 stack boost on non-crit hit',
        stats: [{ type: 'critRate', value: 0.24, refinementScaling: true }],
      },

      {
        display: '4 stack boost on non-crit hit',
        stats: [{ type: 'critRate', value: 0.32, refinementScaling: true }],
      },
      {
        display: '3 stack boost on non-crit hit',
        stats: [{ type: 'critRate', value: 0.4, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // rust
  rust: {
    name: 'rust',
    stars: 4,
    type: 'bow',
    display: 'Rust',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'attackPercent', value: 0.413 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'attackPercent', value: 0.377 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'attackPercent', value: 0.341 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Using normal attack',
        stats: [{ type: 'bonusDmg', value: 0.4, refinementScaling: true }],
      },
      {
        display: 'Using charged attack',
        stats: [{ type: 'bonusDmg', value: -0.1, refinementScaling: false }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // sacrificialBow
  sacrificialBow: {
    name: 'sacrificialBow',
    stars: 4,
    type: 'bow',
    display: 'Sacrificial Bow',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'energyRecharge', value: 0.306 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'energyRecharge', value: 0.279 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'energyRecharge', value: 0.226 },
      ],
    },
    bonuses: [],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  // theViridescentHunt
  theViridescentHunt: {
    name: 'theViridescentHunt',
    stars: 4,
    type: 'bow',
    display: 'The Viridescent Hunt',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'critRate', value: 0.276 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'critRate', value: 0.251 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'critRate', value: 0.227 },
      ],
    },
    bonuses: [],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  // slingShot
  slingShot: {
    name: 'slingShot',
    stars: 3,
    type: 'bow',
    display: 'Slingshot',
    levels: {
      90: [
        { type: 'weaponAttack', value: 354 },
        { type: 'critRate', value: 0.312 },
      ],
      80: [
        { type: 'weaponAttack', value: 314 },
        { type: 'critRate', value: 0.285 },
      ],
      70: [
        { type: 'weaponAttack', value: 274 },
        { type: 'critRate', value: 0.257 },
      ],
      1: [
        { type: 'weaponAttack', value: 38 },
        { type: 'critRate', value: 0.068 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Normal/Charged Within 0.3s',
        stats: [
          {
            type: 'bonusDmg',
            value: 0.36,
            refinementScaling: true,
            customRefine: 1 / 6,
          },
        ],
      },
      {
        display: 'Normal/Charged Over 0.3s',
        stats: [{ type: 'bonusDmg', value: -0.1, refinementScaling: false }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // ebonyBow
  ebonyBow: {
    name: 'ebonyBow',
    stars: 3,
    type: 'bow',
    display: 'Ebony Bow',
    levels: {
      90: [
        { type: 'weaponAttack', value: 401 },
        { type: 'attackPercent', value: 0.352 },
      ],
      80: [
        { type: 'weaponAttack', value: 355 },
        { type: 'attackPercent', value: 0.321 },
      ],
      70: [
        { type: 'weaponAttack', value: 309 },
        { type: 'attackPercent', value: 0.29 },
      ],
      1: [
        { type: 'weaponAttack', value: 39 },
        { type: 'attackPercent', value: 0.077 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Hitting Robot',
        stats: [
          {
            type: 'bonusDmg',
            value: 0.4,
            refinementScaling: true,
          },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  // recurveBow
  recurveBow: {
    name: 'recurveBow',
    stars: 3,
    type: 'bow',
    display: 'Recurve Bow',
    levels: {
      90: [
        { type: 'weaponAttack', value: 354 },
        { type: 'hpPercent', value: 0.469 },
      ],
    },
    bonuses: [],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  // sharpshootersOath
  sharpshootersOath: {
    name: 'sharpshootersOath',
    stars: 3,
    type: 'bow',
    display: "Sharpshooter's Oath",
    levels: {
      90: [
        { type: 'weaponAttack', value: 401 },
        { type: 'critDmg', value: 0.469 },
      ],
      80: [
        { type: 'weaponAttack', value: 355 },
        { type: 'critDmg', value: 0.427 },
      ],
      70: [
        { type: 'weaponAttack', value: 309 },
        { type: 'critDmg', value: 0.386 },
      ],
      0: [
        { type: 'weaponAttack', value: 39 },
        { type: 'critDmg', value: 0.109 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Hit weakpoint',
        stats: [{ type: 'bonusDmg', value: 0.24, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
};

var claymores = {
  // wolfsGravestone
  wolfsGravestone: {
    name: 'wolfsGravestone',
    stars: 5,
    type: 'claymore',
    display: "Wolf's Gravestone",
    levels: {
      90: [
        { type: 'weaponAttack', value: 608 },
        { type: 'attackPercent', value: 0.496 },
      ],
      80: [
        { type: 'weaponAttack', value: 532 },
        { type: 'attackPercent', value: 0.453 },
      ],
      70: [
        { type: 'weaponAttack', value: 457 },
        { type: 'attackPercent', value: 0.409 },
      ],
    },
    bonuses: [{ type: 'attackPercent', value: 0.2, refinementScaling: true }],
    situationalBonuses: [
      {
        display: 'Attack buff after hitting <30% hp enemy',
        stats: [{ type: 'attackPercent', value: 0.4, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // redhornStonethresher
  redhornStonethresher: {
    name: 'redhornStonethresher',
    stars: 5,
    type: 'claymore',
    display: 'Redhorn Stonethresher',
    levels: {
      90: [
        { type: 'weaponAttack', value: 542 },
        { type: 'critDmg', value: 0.882 },
      ],
      80: [
        { type: 'weaponAttack', value: 475 },
        { type: 'critDmg', value: 0.804 },
      ],
      70: [
        { type: 'weaponAttack', value: 408 },
        { type: 'critDmg', value: 0.727 },
      ],
    },
    bonuses: [{ type: 'defensePercent', value: 0.4, refinementScaling: true }],
    situationalBonuses: [
      {
        display: 'Using Normal or Charged Attacks',
        stats: [
          {
            type: 'defenseScalingFlatDamage',
            value: 0.4,
            refinementScaling: true,
          },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  // redhornStonethresherNoBonus
  redhornStonethresherNoBonus: {
    name: 'redhornStonethresherNoBonus',
    stars: 5,
    type: 'claymore',
    display: 'Redhorn StonethresherNoBonus',
    levels: {
      90: [
        { type: 'weaponAttack', value: 542 },
        { type: 'critDmg', value: 0.882 },
      ],
      80: [
        { type: 'weaponAttack', value: 475 },
        { type: 'critDmg', value: 0.804 },
      ],
      70: [
        { type: 'weaponAttack', value: 408 },
        { type: 'critDmg', value: 0.727 },
      ],
    },
    bonuses: [
      { type: 'defensePercent', value: 0.4, refinementScaling: true },
      // { type: 'defenseScalingFlatDamage', value: 0.4, refinementScaling: true },
    ],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  // skywardPride
  skywardPride: {
    name: 'skywardPride',
    stars: 5,
    type: 'claymore',
    display: 'Skyward Pride',
    levels: {
      90: [
        { type: 'weaponAttack', value: 674 },
        { type: 'energyRecharge', value: 0.368 },
      ],
      80: [
        { type: 'weaponAttack', value: 590 },
        { type: 'energyRecharge', value: 0.335 },
      ],
      70: [
        { type: 'weaponAttack', value: 506 },
        { type: 'energyRecharge', value: 0.303 },
      ],
    },
    bonuses: [{ type: 'bonusDmg', value: 0.08, refinementScaling: true }],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  // theUnforged
  theUnforged: {
    name: 'theUnforged',
    stars: 5,
    type: 'claymore',
    display: 'The Unforged',
    levels: {
      90: [
        { type: 'weaponAttack', value: 608 },
        { type: 'attackPercent', value: 0.496 },
      ],
      80: [
        { type: 'weaponAttack', value: 532 },
        { type: 'attackPercent', value: 0.453 },
      ],
      70: [
        { type: 'weaponAttack', value: 457 },
        { type: 'attackPercent', value: 0.409 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '5 stacks of boost with shield',
        stats: [{ type: 'attackPercent', value: 0.4, refinementScaling: true }],
      },
      {
        display: '5 stacks of boost without shield',
        stats: [{ type: 'attackPercent', value: 0.2, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // songOfBrokenPines
  songOfBrokenPines: {
    name: 'songOfBrokenPines',
    stars: 5,
    type: 'claymore',
    display: 'Song of Broken Pines',
    levels: {
      90: [
        { type: 'weaponAttack', value: 741 },
        { type: 'physicalDmg', value: 0.207 },
      ],
      80: [
        { type: 'weaponAttack', value: 648 },
        { type: 'physicalDmg', value: 0.189 },
      ],
      70: [
        { type: 'weaponAttack', value: 555 },
        { type: 'physicalDmg', value: 0.17 },
      ],
      1: [
        { type: 'weaponAttack', value: 49 },
        { type: 'physicalDmg', value: 0.045 },
      ],
    },
    bonuses: [{ type: 'attackPercent', value: 0.16, refinementScaling: true }],
    situationalBonuses: [
      {
        display: 'Millenial Movement: Banner-Hyme',
        stats: [{ type: 'attackPercent', value: 0.2, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // luxuriousSealord
  luxuriousSealord: {
    name: 'luxuriousSealord',
    stars: 4,
    type: 'claymore',
    display: 'Luxurious Sealord',
    levels: {
      90: [
        { type: 'weaponAttack', value: 445 },
        { type: 'attackPercent', value: 0.551 },
      ],
      80: [
        { type: 'weaponAttack', value: 401 },
        { type: 'attackPercent', value: 0.503 },
      ],
      70: [
        { type: 'weaponAttack', value: 347 },
        { type: 'attackPercent', value: 0.454 },
      ],
      1: [
        { type: 'weaponAttack', value: 41 },
        { type: 'attackPercent', value: 0.12 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Burst Damage',
        stats: [{ type: 'bonusDmg', value: 0.12, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // snowTombedStarsilver
  snowTombedStarsilver: {
    name: 'snowTombedStarsilver',
    stars: 4,
    type: 'claymore',
    display: 'Snow Tombed Starsilver',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'physicalDmg', value: 0.345 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'physicalDmg', value: 0.315 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'physicalDmg', value: 0.284 },
      ],
      1: [
        { type: 'weaponAttack', value: 44 },
        { type: 'physicalDmg', value: 0.075 },
      ],
    },
    bonuses: [],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  // whiteblind
  whiteblind: {
    name: 'whiteblind',
    stars: 4,
    type: 'claymore',
    display: 'Whiteblind',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'defensePercent', value: 0.517 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'defensePercent', value: 0.472 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'defensePercent', value: 0.426 },
      ],
      1: [
        { type: 'weaponAttack', value: 42 },
        { type: 'defensePercent', value: 0.113 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '4 stacks on hit',
        stats: [
          { type: 'attackPercent', value: 0.24, refinementScaling: true },
          { type: 'defensePercent', value: 0.24, refinementScaling: true },
        ],
      },
      {
        display: '3 stacks on hit',
        stats: [
          { type: 'attackPercent', value: 0.18, refinementScaling: true },
          { type: 'defensePercent', value: 0.18, refinementScaling: true },
        ],
      },
      {
        display: '2 stacks on hit',
        stats: [
          { type: 'attackPercent', value: 0.12, refinementScaling: true },
          { type: 'defensePercent', value: 0.12, refinementScaling: true },
        ],
      },
      {
        display: '1 stack on hit',
        stats: [
          { type: 'attackPercent', value: 0.06, refinementScaling: true },
          { type: 'defensePercent', value: 0.06, refinementScaling: true },
        ],
      },
      {
        display: '4 attack stacks only',
        stats: [
          { type: 'attackPercent', value: 0.24, refinementScaling: true },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  // theBell
  theBell: {
    name: 'theBell',
    stars: 4,
    type: 'claymore',
    display: 'The Bell',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'hpPercent', value: 0.413 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'hpPercent', value: 0.377 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'hpPercent', value: 0.341 },
      ],
      1: [
        { type: 'weaponAttack', value: 42 },
        { type: 'hpPercent', value: 0.09 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Bonus damage triggered',
        stats: [{ type: 'bonusDmg', value: 0.12, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // serpentSpine
  serpentSpine: {
    name: 'serpentSpine',
    stars: 4,
    type: 'claymore',
    display: 'Serpent Spine',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'critRate', value: 0.276 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'critRate', value: 0.251 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'critRate', value: 0.227 },
      ],
      1: [
        { type: 'weaponAttack', value: 42 },
        { type: 'critRate', value: 0.06 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '5 stacks up',
        stats: [
          {
            type: 'bonusDmg',
            value: 0.3,
            customRefine: 1 / 6,
            refinementScaling: true,
          },
        ],
      },
      {
        display: '4 stacks up',
        stats: [
          {
            type: 'bonusDmg',
            value: 0.24,
            customRefine: 1 / 6,
            refinementScaling: true,
          },
        ],
      },
      {
        display: '3 stacks up',
        stats: [
          {
            type: 'bonusDmg',
            value: 0.18,
            customRefine: 1 / 6,
            refinementScaling: true,
          },
        ],
      },
      {
        display: '2 stacks up',
        stats: [
          {
            type: 'bonusDmg',
            value: 0.12,
            customRefine: 1 / 6,
            refinementScaling: true,
          },
        ],
      },
      {
        display: '1 stack1 up',
        stats: [
          {
            type: 'bonusDmg',
            value: 0.06,
            customRefine: 1 / 6,
            refinementScaling: true,
          },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  // royalGreatsword
  royalGreatsword: {
    name: 'royalGreatsword',
    stars: 4,
    type: 'claymore',
    display: 'Royal Greatsword',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'attackPercent', value: 0.276 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'attackPercent', value: 0.251 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'attackPercent', value: 0.227 },
      ],
      1: [
        { type: 'weaponAttack', value: 44 },
        { type: 'attackPercent', value: 0.06 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '1 stack',
        stats: [{ type: 'critRate', value: 0.08, refinementScaling: true }],
      },
      {
        display: '2 stacks',
        stats: [{ type: 'critRate', value: 0.16, refinementScaling: true }],
      },
      {
        display: '3 stacks',
        stats: [{ type: 'critRate', value: 0.24, refinementScaling: true }],
      },
      {
        display: '4 stacks',
        stats: [{ type: 'critRate', value: 0.32, refinementScaling: true }],
      },
      {
        display: '5 stacks',
        stats: [{ type: 'critRate', value: 0.4, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // rainSlasher
  rainSlasher: {
    name: 'rainSlasher',
    stars: 4,
    type: 'claymore',
    display: 'Rain Slasher',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'elementalMastery', value: 136 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'elementalMastery', value: 151 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'elementalMastery', value: 165 },
      ],
      1: [
        { type: 'weaponAttack', value: 42 },
        { type: 'elementalMastery', value: 36 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Hitting enemy with Hydro/Electro debuff',
        stats: [{ type: 'bonusDmg', value: 0.2, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  // prototypeAminus
  prototypeAminus: {
    name: 'prototypeAminus',
    stars: 4,
    type: 'claymore',
    display: 'Prototype Aminus',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'attackPercent', value: 0.276 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'attackPercent', value: 0.251 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'attackPercent', value: 0.227 },
      ],
      1: [
        { type: 'weaponAttack', value: 44 },
        { type: 'attackPercent', value: 0.06 },
      ],
    },
    bonuses: [],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  // favoniusGreatsword
  favoniusGreatsword: {
    name: 'favoniusGreatsword',
    stars: 4,
    type: 'claymore',
    display: 'Favonius Greatsword',
    levels: {
      90: [
        { type: 'weaponAttack', value: 454 },
        { type: 'energyRecharge', value: 0.613 },
      ],
      80: [
        { type: 'weaponAttack', value: 401 },
        { type: 'energyRecharge', value: 0.559 },
      ],
      70: [
        { type: 'weaponAttack', value: 347 },
        { type: 'energyRecharge', value: 0.505 },
      ],
      1: [
        { type: 'weaponAttack', value: 41 },
        { type: 'energyRecharge', value: 0.133 },
      ],
    },
    bonuses: [],
    situationalBonuses: [{ display: 'None' }],
  },
  // sacrificialGreatsword
  sacrificialGreatsword: {
    name: 'sacrificialGreatsword',
    stars: 4,
    type: 'claymore',
    display: 'Sacrificial Greatsword',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'energyRecharge', value: 0.306 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'energyRecharge', value: 0.279 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'energyRecharge', value: 0.252 },
      ],
      1: [
        { type: 'weaponAttack', value: 44 },
        { type: 'energyRecharge', value: 0.067 },
      ],
    },
    bonuses: [],
    situationalBonuses: [{ display: 'None' }],
  },
  // blackcliffSlasher
  blackcliffSlasher: {
    name: 'blackcliffSlasher',
    stars: 4,
    type: 'claymore',
    display: 'Blackcliff Slasher',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'critDmg', value: 0.551 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'critDmg', value: 0.503 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'critDmg', value: 0.454 },
      ],
      1: [
        { type: 'weaponAttack', value: 42 },
        { type: 'critDmg', value: 0.12 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '3 stacks of attack boosts',
        stats: [
          { type: 'attackPercent', value: 0.36, refinementScaling: true },
        ],
      },
      {
        display: '2 stacks of attack boosts',
        stats: [
          { type: 'attackPercent', value: 0.24, refinementScaling: true },
        ],
      },
      {
        display: '1 stack of attack boost',
        stats: [
          { type: 'attackPercent', value: 0.12, refinementScaling: true },
        ],
      },
      { display: 'None' },
    ],
  },
  // skyriderGreatsword
  skyriderGreatsword: {
    name: 'skyriderGreatsword',
    stars: 3,
    type: 'claymore',
    display: 'Skyrider Greatsword',
    levels: {
      90: [
        { type: 'weaponAttack', value: 401 },
        { type: 'physicalDmg', value: 0.439 },
      ],
      80: [
        { type: 'weaponAttack', value: 355 },
        { type: 'physicalDmg', value: 0.401 },
      ],
      70: [
        { type: 'weaponAttack', value: 309 },
        { type: 'physicalDmg', value: 0.362 },
      ],
      1: [
        { type: 'weaponAttack', value: 39 },
        { type: 'physicalDmg', value: 0.096 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '4 hits from NA or CA',
        stats: [
          {
            type: 'attackPercent',
            value: 0.24,
            refinementScaling: true,
            customRefine: 1 / 6,
          },
        ],
      },
      {
        display: '3 hits from NA or CA',
        stats: [
          {
            type: 'attackPercent',
            value: 0.18,
            refinementScaling: true,
            customRefine: 1 / 6,
          },
        ],
      },
      {
        display: '2 hits from NA or CA',
        stats: [
          {
            type: 'attackPercent',
            value: 0.12,
            refinementScaling: true,
            customRefine: 1 / 6,
          },
        ],
      },
      {
        display: '1 hit from NA or CA',
        stats: [
          {
            type: 'attackPercent',
            value: 0.06,
            refinementScaling: true,
            customRefine: 1 / 6,
          },
        ],
      },
      { display: 'None' },
    ],
  },
  // debateClub
  debateClub: {
    name: 'debateClub',
    stars: 3,
    type: 'claymore',
    display: 'Debate Club',
    levels: {
      90: [
        { type: 'weaponAttack', value: 401 },
        { type: 'attackPercent', value: 0.352 },
      ],
      80: [
        { type: 'weaponAttack', value: 355 },
        { type: 'attackPercent', value: 0.321 },
      ],
      70: [
        { type: 'weaponAttack', value: 309 },
        { type: 'attackPercent', value: 0.29 },
      ],
      1: [
        { type: 'weaponAttack', value: 39 },
        { type: 'attackPercent', value: 0.077 },
      ],
    },
    bonuses: [],
    situationalBonuses: [{ display: 'None' }],
  },
  // lithicBlade
  lithicBlade: {
    name: 'lithicBlade',
    stars: 4,
    type: 'claymore',
    display: 'Lithic Blade',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'attackPercent', value: 0.413 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'attackPercent', value: 0.377 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'attackPercent', value: 0.341 },
      ],
      1: [
        { type: 'weaponAttack', value: 42 },
        { type: 'attackPercent', value: 0.09 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '4 from Liyue',
        stats: [
          {
            type: 'attackPercent',
            value: 0.28,
            refinementScaling: true,
            customRefine: 1 / 7,
          },
          {
            type: 'critRate',
            value: 0.12,
            refinementScaling: true,
            customRefine: 1 / 3,
          },
        ],
      },
      {
        display: '3 from Liyue',
        stats: [
          {
            type: 'attackPercent',
            value: 0.21,
            refinementScaling: true,
            customRefine: 1 / 7,
          },
          {
            type: 'critRate',
            value: 0.09,
            refinementScaling: true,
            customRefine: 1 / 3,
          },
        ],
      },
      {
        display: '2 from Liyue',
        stats: [
          {
            type: 'attackPercent',
            value: 0.14,
            refinementScaling: true,
            customRefine: 1 / 7,
          },
          {
            type: 'critRate',
            value: 0.06,
            refinementScaling: true,
            customRefine: 1 / 3,
          },
        ],
      },
      {
        display: '1 from Liyue',
        stats: [
          {
            type: 'attackPercent',
            value: 0.07,
            refinementScaling: true,
            customRefine: 1 / 7,
          },
          {
            type: 'critRate',
            value: 0.03,
            refinementScaling: true,
            customRefine: 1 / 3,
          },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
};

var swords = {
  aquilaFavonia: {
    name: 'aquilaFavonia',
    stars: 5,
    type: 'sword',
    display: 'Aquila Favonia',
    levels: {
      90: [
        { type: 'weaponAttack', value: 674 },
        { type: 'physicalDmg', value: 0.413 },
      ],
      80: [
        { type: 'weaponAttack', value: 590 },
        { type: 'physicalDmg', value: 0.377 },
      ],
      70: [
        { type: 'weaponAttack', value: 506 },
        { type: 'physicalDmg', value: 0.341 },
      ],
    },
    bonuses: [{ type: 'attackPercent', value: 0.2, refinementScaling: true }],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  haranGeppakuFutsu: {
    name: 'haranGeppakuFutsu',
    stars: 5,
    type: 'sword',
    display: 'Haran Geppaku Futsu',
    levels: {
      90: [
        { type: 'weaponAttack', value: 608 },
        { type: 'critRate', value: 0.331 },
      ],
    },
    bonuses: [{ type: 'elementalDmg', value: 0.12, refinementScaling: true }],
    situationalBonuses: [
      {
        display: '2 Wavespike Stacks',
        stats: [{ type: 'bonusDmg', value: 0.4, refinementScaling: true }],
      },
      {
        display: '1 Wavespike Stack',
        stats: [{ type: 'bonusDmg', value: 0.2, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  freedomSworn: {
    name: 'freedomSworn',
    stars: 5,
    type: 'sword',
    display: 'Freedom Sworn',
    levels: {
      90: [
        { type: 'weaponAttack', value: 608 },
        { type: 'elementalMastery', value: 198 },
      ],
      80: [
        { type: 'weaponAttack', value: 532 },
        { type: 'elementalMastery', value: 181 },
      ],
      70: [
        { type: 'weaponAttack', value: 457 },
        { type: 'elementalMastery', value: 457 },
      ],
    },
    bonuses: [{ type: 'bonusDmg', value: 0.1, refinementScaling: true }],
    situationalBonuses: [
      {
        display: 'Millenial Movement. Full Buff.',
        stats: [
          { type: 'bonusDmg', value: 0.16, refinementScaling: true },
          { type: 'attackPercent', value: 0.2, refinementScaling: true },
        ],
      },
      {
        display: 'Millenial Movement. Attack Only.',
        stats: [{ type: 'attackPercent', value: 0.2, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  skywardBlade: {
    name: 'skywardBlade',
    stars: 5,
    type: 'sword',
    display: 'Skyward Blade',
    levels: {
      90: [
        { type: 'weaponAttack', value: 608 },
        { type: 'energyRecharge', value: 0.551 },
      ],
      80: [
        { type: 'weaponAttack', value: 532 },
        { type: 'energyRecharge', value: 0.503 },
      ],
      70: [
        { type: 'weaponAttack', value: 457 },
        { type: 'energyRecharge', value: 0.454 },
      ],
    },
    bonuses: [{ type: 'critRate', value: 0.04, refinementScaling: true }],
    situationalBonuses: [
      {
        display: 'After burst, use normal/charged attack',
        stats: [{ type: 'bonusDmg', value: 0.2, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  summitShaper: {
    name: 'summitShaper',
    stars: 5,
    type: 'sword',
    display: 'Summit Shaper',
    levels: {
      90: [
        { type: 'weaponAttack', value: 608 },
        { type: 'attackPercent', value: 0.496 },
      ],
      80: [
        { type: 'weaponAttack', value: 532 },
        { type: 'attackPercent', value: 0.453 },
      ],
      70: [
        { type: 'weaponAttack', value: 457 },
        { type: 'attackPercent', value: 0.409 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '5 stacks of boost with shield',
        stats: [{ type: 'attackPercent', value: 0.4, refinementScaling: true }],
      },
      {
        display: '5 stacks of boost without shield',
        stats: [{ type: 'attackPercent', value: 0.2, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  MistSplittersReforged: {
    name: 'MistSplittersReforged',
    stars: 5,
    type: 'sword',
    display: "Mist Splitter's Reforged",
    levels: {
      90: [
        { type: 'weaponAttack', value: 674 },
        { type: 'critDmg', value: 0.441 },
      ],
      80: [
        { type: 'weaponAttack', value: 1 },
        { type: 'critDmg', value: 0.01 },
      ],
      70: [
        { type: 'weaponAttack', value: 1 },
        { type: 'critDmg', value: 0.01 },
      ],
    },
    bonuses: [{ type: 'bonusDmg', value: 0.12, refinementScaling: true }],
    situationalBonuses: [
      {
        display: '3 stacks',
        stats: [{ type: 'bonusDmg', value: 0.28, refinementScaling: true }],
      },
      {
        display: '2 stacks',
        stats: [{ type: 'bonusDmg', value: 0.16, refinementScaling: true }],
      },
      {
        display: '1 stack',
        stats: [{ type: 'bonusDmg', value: 0.08, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  primordialJadeCutter: {
    name: 'primordialJadeCutter',
    stars: 5,
    type: 'sword',
    display: 'Primordial Jade Cutter',
    levels: {
      90: [
        { type: 'weaponAttack', value: 542 },
        { type: 'critRate', value: 0.441 },
      ],
      80: [
        { type: 'weaponAttack', value: 475 },
        { type: 'critRate', value: 0.402 },
      ],
      70: [
        { type: 'weaponAttack', value: 408 },
        { type: 'critRate', value: 0.363 },
      ],
    },
    bonuses: [
      { type: 'hpPercent', value: 0.2, refinementScaling: true },
      { type: 'hpScalingAttack', value: 0.012, refinementScaling: true },
    ],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  // cinnabarSpindle
  cinnabarSpindle: {
    name: 'cinnabarSpindle',
    stars: 4,
    type: 'sword',
    display: 'Cinnabar Spindle',
    levels: {
      90: [
        { type: 'weaponAttack', value: 454 },
        { type: 'critDmg', value: 0.69 },
      ],
      80: [
        { type: 'weaponAttack', value: 401 },
        { type: 'critDmg', value: 0.629 },
      ],
      70: [
        { type: 'weaponAttack', value: 347 },
        { type: 'critDmg', value: 0.568 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Elemental skill damage is increased by defense.',
        stats: [
          {
            type: 'defenseScalingFlatDamage',
            value: 0.4,
            refinementScaling: true,
          },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  festeringDesire: {
    name: 'festeringDesire',
    stars: 4,
    type: 'sword',
    display: 'Festering Desire',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'energyRecharge', value: 0.459 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'energyRecharge', value: 0.419 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'energyRecharge', value: 0.379 },
      ],
      0: [
        { type: 'weaponAttack', value: 42 },
        { type: 'energyRecharge', value: 0.1 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Using Elemental Skill',
        stats: [
          { type: 'bonusDmg', value: 0.16, refinementScaling: true },
          { type: 'critRate', value: 0.06, refinementScaling: true },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  alleyFlash: {
    name: 'alleyFlash',
    stars: 4,
    type: 'sword',
    display: 'The Alley Flash',
    levels: {
      90: [
        { type: 'weaponAttack', value: 660 },
        { type: 'elementalMastery', value: 55 },
      ],
      80: [
        { type: 'weaponAttack', value: 579 },
        { type: 'elementalMastery', value: 50 },
      ],
      70: [
        { type: 'weaponAttack', value: 499 },
        { type: 'elementalMastery', value: 45 },
      ],
      0: [
        { type: 'weaponAttack', value: 46 },
        { type: 'elementalMastery', value: 12 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Bonus damage. Getting hit disables this for 5 seconds.',
        stats: [{ type: 'bonusDmg', value: 0.12, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  theFlute: {
    name: 'theFlute',
    stars: 4,
    type: 'sword',
    display: 'The Flute',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'attackPercent', value: 0.413 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'attackPercent', value: 0.377 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'attackPercent', value: 0.341 },
      ],
      0: [
        { type: 'weaponAttack', value: 42 },
        { type: 'attackPercent', value: 0.09 },
      ],
    },
    bonuses: [],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  theBlackSword: {
    name: 'theBlackSword',
    stars: 4,
    type: 'sword',
    display: 'The Black Sword',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'critRate', value: 0.276 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'critRate', value: 0.251 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'critRate', value: 0.227 },
      ],
      0: [
        { type: 'weaponAttack', value: 42 },
        { type: 'critRate', value: 0.06 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Using Normal and/or Charged Attack',
        stats: [{ type: 'bonusDmg', value: 0.2, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  sacrificialSword: {
    name: 'sacrificialSword',
    stars: 4,
    type: 'sword',
    display: 'Sacrificial Sword',
    levels: {
      90: [
        { type: 'weaponAttack', value: 454 },
        { type: 'energyRecharge', value: 0.613 },
      ],
      80: [
        { type: 'weaponAttack', value: 401 },
        { type: 'energyRecharge', value: 0.559 },
      ],
      70: [
        { type: 'weaponAttack', value: 347 },
        { type: 'energyRecharge', value: 0.505 },
      ],
      0: [
        { type: 'weaponAttack', value: 41 },
        { type: 'energyRecharge', value: 0.133 },
      ],
    },
    bonuses: [],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  royalLongsword: {
    name: 'royalLongsword',
    stars: 4,
    type: 'sword',
    display: 'Royal Longsword',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'attackPercent', value: 0.276 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'attackPercent', value: 0.377 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'attackPercent', value: 0.341 },
      ],
      0: [
        { type: 'weaponAttack', value: 42 },
        { type: 'attackPercent', value: 0.09 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '1 stack',
        stats: [{ type: 'critRate', value: 0.08, refinementScaling: true }],
      },
      {
        display: '2 stacks',
        stats: [{ type: 'critRate', value: 0.16, refinementScaling: true }],
      },
      {
        display: '3 stacks',
        stats: [{ type: 'critRate', value: 0.24, refinementScaling: true }],
      },
      {
        display: '4 stacks',
        stats: [{ type: 'critRate', value: 0.32, refinementScaling: true }],
      },
      {
        display: '5 stacks',
        stats: [{ type: 'critRate', value: 0.4, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  AmenomaKageuchi: {
    name: 'AmenomaKageuchi',
    stars: 4,
    type: 'sword',
    display: 'Amenoma Kageuchi',
    levels: {
      90: [
        { type: 'weaponAttack', value: 454 },
        { type: 'attackPercent', value: 0.551 },
      ],
      80: [
        { type: 'weaponAttack', value: 401 },
        { type: 'attackPercent', value: 0.503 },
      ],
      70: [
        { type: 'weaponAttack', value: 347 },
        { type: 'attackPercent', value: 0.454 },
      ],
      0: [
        { type: 'weaponAttack', value: 41 },
        { type: 'attackPercent', value: 0.12 },
      ],
    },
    bonuses: [],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  prototypeRancor: {
    name: 'prototypeRancor',
    stars: 4,
    type: 'sword',
    display: 'Prototype Rancour',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'physicalDmg', value: 0.345 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'physicalDmg', value: 0.315 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'physicalDmg', value: 0.284 },
      ],
      0: [
        { type: 'weaponAttack', value: 44 },
        { type: 'physicalDmg', value: 0.075 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '4 stacks',
        stats: [
          { type: 'attackPercent', value: 0.16, refinementScaling: true },
          { type: 'defensePercent', value: 0.16, refinementScaling: true },
        ],
      },
      {
        display: '3 stacks',
        stats: [
          { type: 'attackPercent', value: 0.12, refinementScaling: true },
          { type: 'defensePercent', value: 0.12, refinementScaling: true },
        ],
      },
      {
        display: '2 stacks',
        stats: [
          { type: 'attackPercent', value: 0.08, refinementScaling: true },
          { type: 'defensePercent', value: 0.08, refinementScaling: true },
        ],
      },
      {
        display: '1 stack',
        stats: [
          { type: 'attackPercent', value: 0.04, refinementScaling: true },
          { type: 'defensePercent', value: 0.04, refinementScaling: true },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  lionsRoar: {
    name: 'lionsRoar',
    stars: 4,
    type: 'sword',
    display: "Lion's Roar",
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'attackPercent', value: 0.413 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'attackPercent', value: 0.377 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'attackPercent', value: 0.341 },
      ],
      0: [
        { type: 'weaponAttack', value: 42 },
        { type: 'attackPercent', value: 0.09 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'Attacking enemy afflicted with pyro/electro',
        stats: [{ type: 'bonusDmg', value: 0.2, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  ironSting: {
    name: 'ironSting',
    stars: 4,
    type: 'sword',
    display: 'Iron Sting',
    levels: {
      90: [
        { type: 'weaponAttack', value: 510 },
        { type: 'elementalMastery', value: 165 },
      ],
      80: [
        { type: 'weaponAttack', value: 449 },
        { type: 'elementalMastery', value: 151 },
      ],
      70: [
        { type: 'weaponAttack', value: 388 },
        { type: 'elementalMastery', value: 136 },
      ],
      0: [
        { type: 'weaponAttack', value: 42 },
        { type: 'elementalMastery', value: 36 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '2 stacks',
        stats: [{ type: 'bonusDmg', value: 0.12, refinementScaling: true }],
      },
      {
        display: '1 stack',
        stats: [{ type: 'bonusDmg', value: 0.06, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
  favoniusSword: {
    name: 'favoniusSword',
    stars: 4,
    type: 'sword',
    display: 'Favonius Sword',
    levels: {
      90: [
        { type: 'weaponAttack', value: 454 },
        { type: 'energyRecharge', value: 0.613 },
      ],
      80: [
        { type: 'weaponAttack', value: 401 },
        { type: 'energyRecharge', value: 0.559 },
      ],
      70: [
        { type: 'weaponAttack', value: 347 },
        { type: 'energyRecharge', value: 0.505 },
      ],
      0: [
        { type: 'weaponAttack', value: 41 },
        { type: 'energyRecharge', value: 0.133 },
      ],
    },
    bonuses: [],
    situationalBonuses: [{ display: 'None', stats: [] }],
  },
  blackcliffLongsword: {
    name: 'blackcliffLongsword',
    stars: 4,
    type: 'sword',
    display: 'Blackcliff Longsword',
    levels: {
      90: [
        { type: 'weaponAttack', value: 565 },
        { type: 'critDmg', value: 0.368 },
      ],
      80: [
        { type: 'weaponAttack', value: 497 },
        { type: 'critDmg', value: 0.335 },
      ],
      70: [
        { type: 'weaponAttack', value: 429 },
        { type: 'critDmg', value: 0.303 },
      ],
      0: [
        { type: 'weaponAttack', value: 44 },
        { type: 'critDmg', value: 0.08 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: '3 stacks',
        stats: [
          { type: 'attackPercent', value: 0.36, refinementScaling: true },
        ],
      },
      {
        display: '2 stacks',
        stats: [
          { type: 'attackPercent', value: 0.24, refinementScaling: true },
        ],
      },
      {
        display: '1 stack',
        stats: [
          { type: 'attackPercent', value: 0.12, refinementScaling: true },
        ],
      },
      { display: 'None', stats: [] },
    ],
  },
  harbingerOfDawn: {
    name: 'harbingerOfDawn',
    stars: 3,
    type: 'sword',
    display: 'Harbinger of Dawn',
    levels: {
      90: [
        { type: 'weaponAttack', value: 401 },
        { type: 'critDmg', value: 0.469 },
      ],
      80: [
        { type: 'weaponAttack', value: 355 },
        { type: 'critDmg', value: 0.427 },
      ],
      70: [
        { type: 'weaponAttack', value: 309 },
        { type: 'critDmg', value: 0.386 },
      ],
      0: [
        { type: 'weaponAttack', value: 39 },
        { type: 'critDmg', value: 0.102 },
      ],
    },
    bonuses: [],
    situationalBonuses: [
      {
        display: 'HP Above 90%',
        stats: [{ type: 'critRate', value: 0.14, refinementScaling: true }],
      },
      { display: 'None', stats: [] },
    ],
  },
};

var weapons = { ...catalysts, ...polearms, ...bows, ...claymores, ...swords };

function makeWeaponsArray(weapons) {
  var arr = [];
  for (var key in weapons) {
    arr.push(weapons[key]);
  }
  arr.sort(function (a, b) {
    if (a.type > b.type) return 1;
    if (a.type < b.type) return -1;
    if (a.stars > b.stars) return 1;
    if (a.votes < b.stars) return -1;
  });
  return arr;
}

var weaponsArray = makeWeaponsArray(weapons);

export default {
  weapons: weapons,
  weaponsArray: weaponsArray,
};

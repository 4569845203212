import React, { Component } from 'react';
import './styles.css';
import GuessedLetter from './guessedLetter';
import { checkLetters } from '../../utils/kleedle';

class WordRow extends Component {
  render() {
    let { guess, currentGuess } = this.props;
    var thisArray = [];
    if (guess?.length > 0) {
      thisArray = guess;
    }
    if (currentGuess?.length > 0) {
      thisArray = currentGuess;
    }
    return (
      <div className="keyboardRow">
        {thisArray.map((o, i) => {
          return (
            <GuessedLetter letter={o.letter} display={o.display} key={i} />
          );
        })}
      </div>
    );
  }
}

export default WordRow;

// let guessArray = guess?.split('');
//     let currentGuessArray = currentGuess?.split('');
//     let thisArray = [];
//     console.log('guessArray', guessArray);
//     console.log('currentGuessArray', currentGuessArray);
//     if (guessArray?.length > 0) {
//       thisArray = guessArray;
//     }
//     if (currentGuessArray?.length > 0) {
//       thisArray = currentGuessArray;
//     }
//     let useThisArray = [];
//     for (var i = 0; i < length; i++) {
//       let pushThis = thisArray[i] || '';
//       useThisArray.push(pushThis);
//     }

import React, { Component } from 'react';
import WhiteLogo from '../../assets/logos/iwtl-white.png';
import './styles.css';

class Navbar extends Component {
  render() {
    return (
      <nav className="navbar navbar-dark myNavBar navbar-expand-sm">
        <a className="navbar-brand" href="/">
          <img src={WhiteLogo} width="78" height="30" alt="" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link whiteText" href="/">
                Maximizer
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link whiteText" href="/calculator">
                Calculator
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link whiteText" href="/WIP">
                WIP
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link whiteText" href="/Kleedle">
                Kleedle
              </a>
            </li> */}
          </ul>
        </div>
      </nav>
    );
  }
}

export default Navbar;

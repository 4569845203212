import React, { Component } from 'react';
import './styles.css';

class KeyboardLetter extends Component {
  onClick = () => {
    var { letter } = this.props;
    document.dispatchEvent(new KeyboardEvent('keydown', { key: letter }));
  };
  render() {
    var { letter, display } = this.props;
    return (
      <div
        onClick={this.onClick}
        className={'keyboardLetter ' + display}
        key={letter}
        id={letter}
      >
        {letter}
      </div>
    );
  }
}

export default KeyboardLetter;

import Characters from '../data/characters';
import Weapons from '../data/weapons';
var USEFUL_SUBSTATS = 9;
var { characters } = Characters;
var { weapons } = Weapons;

var mainStats = {
  flatAttack: 311,
  attackPercent: 0.466,
  elementalMastery: 187,
  flatHp: 4780,
  elementalDmg: 0.466,
  physicalDmg: 0.583,
  critRate: 0.311,
  critDmg: 0.622,
  defensePercent: 0.583,
  hpPercent: 0.466,
  energyRecharge: 0.518,
};
// .466/.05 = 9.32 ratio between attack % main stat and attack % substat
// .311/.033 = 9.42 ratio between crit rate main stat and crit rate for substat

var subStats = {
  flatAttack: {
    min: 14,
    mean: 16.5,
    max: 19,
  },
  attackPercent: {
    min: 0.041,
    mean: 0.05,
    max: 0.058,
  },
  elementalMastery: {
    min: 16,
    mean: 19.5,
    max: 23,
  },
  critRate: {
    min: 0.027,
    mean: 0.033,
    max: 0.039,
  },
  critDmg: {
    min: 0.054,
    mean: 0.066,
    max: 0.078,
  },
  defensePercent: {
    min: 0.051,
    mean: 0.06,
    max: 0.073,
  },
  flatDefense: {
    min: 16,
    mean: 19.5,
    max: 23,
  },
  hpPercent: {
    min: 0.041,
    mean: 0.05,
    max: 0.058,
  },
  flatHp: {
    min: 209,
    mean: 254,
    max: 299,
  },
  energyRecharge: {
    min: 0.045,
    mean: 0.055,
    max: 0.065,
  },
};

var subStatsPools = {
  average: [
    { stat: 'attackPercent', value: subStats.attackPercent.mean },
    { stat: 'critRate', value: subStats.critRate.mean },
    { stat: 'critDmg', value: subStats.critDmg.mean },
    { stat: 'energyRecharge', value: subStats.elementalMastery.mean },
    { stat: 'flatAttack', value: subStats.flatAttack.mean },
  ],
  vaporize: [
    { stat: 'attackPercent', value: subStats.attackPercent.mean },
    { stat: 'critDmg', value: subStats.critDmg.mean },
    { stat: 'elementalMastery', value: subStats.elementalMastery.mean },
    { stat: 'flatAttack', value: subStats.flatAttack.mean },
    { stat: 'critRate', value: subStats.critRate.mean },
  ],
  vaporizeAverage: [
    { stat: 'critRate', value: subStats.critRate.mean },
    { stat: 'critDmg', value: subStats.critDmg.mean },
    { stat: 'elementalMastery', value: subStats.elementalMastery.mean },
    { stat: 'attackPercent', value: subStats.attackPercent.mean },
    { stat: 'flatAttack', value: subStats.flatAttack.mean },
  ],
  physical: [
    { stat: 'attackPercent', value: subStats.attackPercent.mean },
    { stat: 'critRate', value: subStats.critRate.mean },
    { stat: 'critDmg', value: subStats.critDmg.mean },
    { stat: 'flatAttack', value: subStats.flatAttack.mean },
    { stat: 'elementalMastery', value: subStats.elementalMastery.mean },
  ],
  both: [
    { stat: 'attackPercent', value: subStats.attackPercent.mean },
    { stat: 'critRate', value: subStats.critRate.mean },
    { stat: 'critDmg', value: subStats.critDmg.mean },
    { stat: 'flatAttack', value: subStats.flatAttack.mean },
    { stat: 'elementalMastery', value: subStats.elementalMastery.mean },
  ],
  attackGoblet: [
    { stat: 'attackPercent', value: subStats.attackPercent.mean },
    { stat: 'critRate', value: subStats.critRate.mean },
    { stat: 'critDmg', value: subStats.critDmg.mean },
    { stat: 'flatAttack', value: subStats.flatAttack.mean },
    { stat: 'elementalMastery', value: subStats.elementalMastery.mean },
  ],
  defenseScaling: [
    { stat: 'critRate', value: subStats.critRate.mean },
    { stat: 'critDmg', value: subStats.critDmg.mean },
    { stat: 'defensePercent', value: subStats.defensePercent.mean },
    { stat: 'flatDefense', value: subStats.flatDefense.mean },
    { stat: 'elementalMastery', value: subStats.elementalMastery.mean },
  ],
  defenseScalingAttack: [
    { stat: 'critRate', value: subStats.critRate.mean },
    { stat: 'critDmg', value: subStats.critDmg.mean },
    { stat: 'defensePercent', value: subStats.defensePercent.mean },
    { stat: 'flatDefense', value: subStats.flatDefense.mean },
    { stat: 'attackPercent', value: subStats.defensePercent.mean },
  ],
  hpAndAttackScalingElemental: [
    { stat: 'critRate', value: subStats.critRate.mean },
    { stat: 'critDmg', value: subStats.critDmg.mean },
    { stat: 'attackPercent', value: subStats.attackPercent.mean },
    { stat: 'hpPercent', value: subStats.hpPercent.mean },
    { stat: 'flatAttack', value: subStats.flatAttack.mean },
    { stat: 'flatHp', value: subStats.flatHp.mean },
    { stat: 'elementalMastery', value: subStats.elementalMastery.mean },
  ],
  hpAndAttackScalingPhysical: [
    { stat: 'critRate', value: subStats.critRate.mean },
    { stat: 'critDmg', value: subStats.critDmg.mean },
    { stat: 'attackPercent', value: subStats.attackPercent.mean },
    { stat: 'hpPercent', value: subStats.hpPercent.mean },
    { stat: 'flatAttack', value: subStats.flatAttack.mean },
    { stat: 'flatHp', value: subStats.flatHp.mean },
    { stat: 'elementalMastery', value: subStats.elementalMastery.mean },
  ],
  hpAndAttackScalingVaporize: [
    { stat: 'critRate', value: subStats.critRate.mean },
    { stat: 'critDmg', value: subStats.critDmg.mean },
    { stat: 'elementalMastery', value: subStats.elementalMastery.mean },
    { stat: 'hpPercent', value: subStats.hpPercent.mean },
    { stat: 'attackPercent', value: subStats.attackPercent.mean },
    { stat: 'flatAttack', value: subStats.flatAttack.mean },
    { stat: 'flatHp', value: subStats.flatHp.mean },
  ],
  eosfAttack: [
    { stat: 'critRate', value: subStats.critRate.mean },
    { stat: 'critDmg', value: subStats.critDmg.mean },
    { stat: 'energyRecharge', value: subStats.energyRecharge.mean },
    { stat: 'attackPercent', value: subStats.attackPercent.mean },
    { stat: 'flatAttack', value: subStats.flatAttack.mean },
    { stat: 'hpPercent', value: subStats.attackPercent.mean },
  ],
  eosfAttackVape: [
    { stat: 'critRate', value: subStats.critRate.mean },
    { stat: 'critDmg', value: subStats.critDmg.mean },
    { stat: 'energyRecharge', value: subStats.energyRecharge.mean },
    { stat: 'elementalMastery', value: subStats.flatAttack.mean },
    { stat: 'attackPercent', value: subStats.attackPercent.mean },
  ],
  eosfHPScaling: [
    { stat: 'critRate', value: subStats.critRate.mean },
    { stat: 'critDmg', value: subStats.critDmg.mean },
    { stat: 'hpPercent', value: subStats.flatAttack.mean },
    { stat: 'energyRecharge', value: subStats.energyRecharge.mean },
    { stat: 'flatHp', value: subStats.attackPercent.mean },
  ],
  onlyHpScaling: [
    { stat: 'critRate', value: subStats.critRate.mean },
    { stat: 'critDmg', value: subStats.critDmg.mean },
    { stat: 'hpPercent', value: subStats.flatAttack.mean },
    { stat: 'flatHp', value: subStats.attackPercent.mean },
    { stat: 'energyRecharge', value: subStats.energyRecharge.mean },
  ],
  averageAndReaction: [
    { stat: 'attackPercent', value: subStats.attackPercent.mean },
    { stat: 'critRate', value: subStats.critRate.mean },
    { stat: 'critDmg', value: subStats.critDmg.mean },
    { stat: 'elementalMastery', value: subStats.elementalMastery.mean },
    { stat: 'flatAttack', value: subStats.flatAttack.mean },
    { stat: 'energyRecharge', value: subStats.elementalMastery.mean },
  ],
};

var mainStatsPools = {
  average: {
    feather: {
      main: [{ stat: 'flatAttack', value: mainStats.flatAttack }],
    },
    flower: {
      main: [{ stat: 'flatHp', value: mainStats.flatHp }],
    },
    timePiece: {
      main: [{ stat: 'attackPercent', value: mainStats.attackPercent }],
    },
    goblet: {
      main: [
        { stat: 'elementalDmg', value: mainStats.elementalDmg },
        { stat: 'attackPercent', value: mainStats.attackPercent },
      ],
    },
    hat: {
      main: [
        { stat: 'attackPercent', value: mainStats.attackPercent },
        { stat: 'critDmg', value: mainStats.critDmg },
        { stat: 'critRate', value: mainStats.critRate },
      ],
    },
  },
  vaporize: {
    feather: {
      main: [{ stat: 'flatAttack', value: mainStats.flatAttack }],
    },
    flower: {
      main: [{ stat: 'flatHp', value: mainStats.flatHp }],
    },
    timePiece: {
      main: [
        { stat: 'attackPercent', value: mainStats.attackPercent },
        { stat: 'elementalMastery', value: mainStats.elementalMastery },
      ],
    },
    goblet: {
      main: [
        { stat: 'elementalDmg', value: mainStats.elementalDmg },
        { stat: 'attackPercent', value: mainStats.attackPercent },
        { stat: 'elementalMastery', value: mainStats.elementalMastery },
      ],
    },
    hat: {
      main: [
        { stat: 'attackPercent', value: mainStats.attackPercent },
        { stat: 'critDmg', value: mainStats.critDmg },
        { stat: 'critRate', value: mainStats.critRate },
      ],
    },
  },
  vaporizeAverage: {
    feather: {
      main: [{ stat: 'flatAttack', value: mainStats.flatAttack }],
    },
    flower: {
      main: [{ stat: 'flatHp', value: mainStats.flatHp }],
    },
    timePiece: {
      main: [
        { stat: 'elementalMastery', value: mainStats.elementalMastery },
        { stat: 'attackPercent', value: mainStats.attackPercent },
      ],
    },
    goblet: {
      main: [
        { stat: 'elementalMastery', value: mainStats.elementalMastery },
        { stat: 'elementalDmg', value: mainStats.elementalDmg },
        { stat: 'attackPercent', value: mainStats.attackPercent },
      ],
    },
    hat: {
      main: [
        { stat: 'critRate', value: mainStats.critRate },
        { stat: 'critDmg', value: mainStats.critDmg },
        { stat: 'elementalMastery', value: mainStats.elementalMastery },
      ],
    },
  },
  physical: {
    feather: {
      main: [{ stat: 'flatAttack', value: mainStats.flatAttack }],
    },
    flower: {
      main: [{ stat: 'flatHp', value: mainStats.flatHp }],
    },
    timePiece: {
      main: [{ stat: 'attackPercent', value: mainStats.attackPercent }],
    },
    goblet: {
      main: [
        { stat: 'physicalDmg', value: mainStats.physicalDmg },
        { stat: 'attackPercent', value: mainStats.attackPercent },
      ],
    },
    hat: {
      main: [
        { stat: 'attackPercent', value: mainStats.attackPercent },
        { stat: 'critDmg', value: mainStats.critDmg },
        { stat: 'critRate', value: mainStats.critRate },
      ],
    },
  },
  both: {
    feather: {
      main: [{ stat: 'flatAttack', value: mainStats.flatAttack }],
    },
    flower: {
      main: [{ stat: 'flatHp', value: mainStats.flatHp }],
    },
    timePiece: {
      main: [{ stat: 'attackPercent', value: mainStats.attackPercent }],
    },
    goblet: {
      main: [
        { stat: 'physicalDmg', value: mainStats.physicalDmg },
        { stat: 'elementalDmg', value: mainStats.elementalDmg },
        { stat: 'attackPercent', value: mainStats.attackPercent },
      ],
    },
    hat: {
      main: [
        { stat: 'attackPercent', value: mainStats.attackPercent },
        { stat: 'critDmg', value: mainStats.critDmg },
        { stat: 'critRate', value: mainStats.critRate },
      ],
    },
  },
  attackGoblet: {
    feather: {
      main: [{ stat: 'flatAttack', value: mainStats.flatAttack }],
    },
    flower: {
      main: [{ stat: 'flatHp', value: mainStats.flatHp }],
    },
    timePiece: {
      main: [{ stat: 'attackPercent', value: mainStats.attackPercent }],
    },
    goblet: {
      main: [{ stat: 'attackPercent', value: mainStats.attackPercent }],
    },
    hat: {
      main: [
        { stat: 'attackPercent', value: mainStats.attackPercent },
        { stat: 'critDmg', value: mainStats.critDmg },
        { stat: 'critRate', value: mainStats.critRate },
      ],
    },
  },
  defenseScaling: {
    feather: {
      main: [{ stat: 'flatAttack', value: mainStats.flatAttack }],
    },
    flower: {
      main: [{ stat: 'flatHp', value: mainStats.flatHp }],
    },
    timePiece: {
      main: [{ stat: 'defensePercent', value: mainStats.defensePercent }],
    },
    goblet: {
      main: [
        { stat: 'defensePercent', value: mainStats.defensePercent },
        { stat: 'elementalDmg', value: mainStats.elementalDmg },
      ],
    },
    hat: {
      main: [
        { stat: 'critDmg', value: mainStats.critDmg },
        { stat: 'critRate', value: mainStats.critRate },
        { stat: 'defensePercent', value: mainStats.defensePercent },
      ],
    },
  },
  defenseScalingAttack: {
    feather: {
      main: [{ stat: 'flatAttack', value: mainStats.flatAttack }],
    },
    flower: {
      main: [{ stat: 'flatHp', value: mainStats.flatHp }],
    },
    timePiece: {
      main: [
        { stat: 'defensePercent', value: mainStats.defensePercent },
        { stat: 'attackPercent', value: mainStats.defensePercent },
      ],
    },
    goblet: {
      main: [
        { stat: 'defensePercent', value: mainStats.defensePercent },
        { stat: 'elementalDmg', value: mainStats.elementalDmg },
      ],
    },
    hat: {
      main: [
        { stat: 'critDmg', value: mainStats.critDmg },
        { stat: 'critRate', value: mainStats.critRate },
        { stat: 'defensePercent', value: mainStats.defensePercent },
      ],
    },
  },
  hpAndAttackScalingElemental: {
    feather: {
      main: [{ stat: 'flatAttack', value: mainStats.flatAttack }],
    },
    flower: {
      main: [{ stat: 'flatHp', value: mainStats.flatHp }],
    },
    timePiece: {
      main: [
        { stat: 'hpPercent', value: mainStats.hpPercent },
        { stat: 'attackPercent', value: mainStats.hpPercent },
      ],
    },
    goblet: {
      main: [
        { stat: 'elementalDmg', value: mainStats.elementalDmg },
        { stat: 'attackPercent', value: mainStats.attackPercent },
        { stat: 'hpPercent', value: mainStats.hpPercent },
      ],
    },
    hat: {
      main: [
        { stat: 'critDmg', value: mainStats.critDmg },
        { stat: 'critRate', value: mainStats.critRate },
        { stat: 'hpPercent', value: mainStats.hpPercent },
        { stat: 'attackPercent', value: mainStats.attackPercent },
      ],
    },
  },
  hpAndAttackScalingPhysical: {
    feather: {
      main: [{ stat: 'flatAttack', value: mainStats.flatAttack }],
    },
    flower: {
      main: [{ stat: 'flatHp', value: mainStats.flatHp }],
    },
    timePiece: {
      main: [
        { stat: 'hpPercent', value: mainStats.hpPercent },
        { stat: 'attackPercent', value: mainStats.hpPercent },
      ],
    },
    goblet: {
      main: [
        { stat: 'physicalDmg', value: mainStats.physicalDmg },
        { stat: 'attackPercent', value: mainStats.attackPercent },
        { stat: 'hpPercent', value: mainStats.hpPercent },
      ],
    },
    hat: {
      main: [
        { stat: 'critDmg', value: mainStats.critDmg },
        { stat: 'critRate', value: mainStats.critRate },
        { stat: 'hpPercent', value: mainStats.hpPercent },
        { stat: 'attackPercent', value: mainStats.attackPercent },
      ],
    },
  },
  hpAndAttackScalingVaporize: {
    feather: {
      main: [{ stat: 'flatAttack', value: mainStats.flatAttack }],
    },
    flower: {
      main: [{ stat: 'flatHp', value: mainStats.flatHp }],
    },
    timePiece: {
      main: [
        { stat: 'hpPercent', value: mainStats.hpPercent },
        { stat: 'attackPercent', value: mainStats.hpPercent },
      ],
    },
    goblet: {
      main: [
        { stat: 'elementalDmg', value: mainStats.elementalDmg },
        { stat: 'elementalMastery', value: mainStats.elementalMastery },
        { stat: 'attackPercent', value: mainStats.attackPercent },
        { stat: 'hpPercent', value: mainStats.hpPercent },
      ],
    },
    hat: {
      main: [
        { stat: 'critDmg', value: mainStats.critDmg },
        { stat: 'critRate', value: mainStats.critRate },
        { stat: 'elementalMastery', value: mainStats.elementalMastery },
        { stat: 'hpPercent', value: mainStats.hpPercent },
        { stat: 'attackPercent', value: mainStats.attackPercent },
      ],
    },
  },
  eosfAttack: {
    feather: {
      main: [{ stat: 'flatAttack', value: mainStats.flatAttack }],
    },
    flower: {
      main: [{ stat: 'flatHp', value: mainStats.flatHp }],
    },
    timePiece: {
      main: [
        { stat: 'energyRecharge', value: mainStats.hpPercent },
        { stat: 'attackPercent', value: mainStats.hpPercent },
        { stat: 'hpPercent', value: mainStats.hpPercent },
      ],
    },
    goblet: {
      main: [
        { stat: 'elementalDmg', value: mainStats.elementalDmg },
        { stat: 'attackPercent', value: mainStats.attackPercent },
      ],
    },
    hat: {
      main: [
        { stat: 'critDmg', value: mainStats.critDmg },
        { stat: 'critRate', value: mainStats.critRate },
      ],
    },
  },
  eosfAttackVape: {
    feather: {
      main: [{ stat: 'flatAttack', value: mainStats.flatAttack }],
    },
    flower: {
      main: [{ stat: 'flatHp', value: mainStats.flatHp }],
    },
    timePiece: {
      main: [
        { stat: 'energyRecharge', value: mainStats.hpPercent },
        { stat: 'attackPercent', value: mainStats.hpPercent },
        { stat: 'elementalMastery', value: mainStats.hpPercent },
      ],
    },
    goblet: {
      main: [
        { stat: 'elementalDmg', value: mainStats.elementalDmg },
        { stat: 'attackPercent', value: mainStats.attackPercent },
      ],
    },
    hat: {
      main: [
        { stat: 'critDmg', value: mainStats.critDmg },
        { stat: 'critRate', value: mainStats.critRate },
      ],
    },
  },
  eosfHPScaling: {
    feather: {
      main: [{ stat: 'flatAttack', value: mainStats.flatAttack }],
    },
    flower: {
      main: [{ stat: 'flatHp', value: mainStats.flatHp }],
    },
    timePiece: {
      main: [
        { stat: 'hpPercent', value: mainStats.hpPercent },
        { stat: 'energyRecharge', value: mainStats.hpPercent },
      ],
    },
    goblet: {
      main: [
        { stat: 'elementalDmg', value: mainStats.elementalDmg },
        { stat: 'hpPercent', value: mainStats.attackPercent },
      ],
    },
    hat: {
      main: [
        { stat: 'critDmg', value: mainStats.critDmg },
        { stat: 'critRate', value: mainStats.critRate },
        { stat: 'hpPercent', value: mainStats.hpPercent },
      ],
    },
  },
  onlyHpScaling: {
    feather: {
      main: [{ stat: 'flatAttack', value: mainStats.flatAttack }],
    },
    flower: {
      main: [{ stat: 'flatHp', value: mainStats.flatHp }],
    },
    timePiece: {
      main: [{ stat: 'hpPercent', value: mainStats.hpPercent }],
    },
    goblet: {
      main: [
        { stat: 'elementalDmg', value: mainStats.elementalDmg },
        { stat: 'hpPercent', value: mainStats.attackPercent },
      ],
    },
    hat: {
      main: [
        { stat: 'critDmg', value: mainStats.critDmg },
        { stat: 'critRate', value: mainStats.critRate },
        { stat: 'hpPercent', value: mainStats.hpPercent },
      ],
    },
  },
  averageAndReaction: {
    feather: {
      main: [{ stat: 'flatAttack', value: mainStats.flatAttack }],
    },
    flower: {
      main: [{ stat: 'flatHp', value: mainStats.flatHp }],
    },
    timePiece: {
      main: [
        { stat: 'attackPercent', value: mainStats.attackPercent },
        { stat: 'elementalMastery', value: mainStats.elementalMastery },
      ],
    },
    goblet: {
      main: [
        { stat: 'elementalDmg', value: mainStats.elementalDmg },
        { stat: 'elementalMastery', value: mainStats.elementalMastery },
        { stat: 'attackPercent', value: mainStats.attackPercent },
      ],
    },
    hat: {
      main: [
        { stat: 'critDmg', value: mainStats.critDmg },
        { stat: 'critRate', value: mainStats.critRate },
        { stat: 'elementalMastery', value: mainStats.elementalMastery },
        { stat: 'attackPercent', value: mainStats.attackPercent },
      ],
    },
  },
};

function generateAllPossibleArtifactCombos(payload) {
  var maximize = payload.settings.maximize;
  var mainStatsPool = mainStatsPools[maximize];
  var timePieceLength = mainStatsPool.timePiece.main.length;
  var gobletLength = mainStatsPool.goblet.main.length;
  var hatLength = mainStatsPool.hat.main.length;
  var artifactSets = [];
  for (var i = 0; i < timePieceLength; i++) {
    for (var j = 0; j < gobletLength; j++) {
      for (var k = 0; k < hatLength; k++) {
        artifactSets.push([
          generateArtifact('feather', 0, maximize),
          generateArtifact('flower', 0, maximize),
          generateArtifact('timePiece', i, maximize),
          generateArtifact('goblet', j, maximize),
          generateArtifact('hat', k, maximize),
        ]);
      }
    }
  }
  return artifactSets;
}

function generateArtifact(type, mainIndex, maximize) {
  var artifact = {
    type: type,
    main: mainStatsPools[maximize][type].main[mainIndex],
    subStats: {},
    subStatsLevel: 4,
  };
  artifact.subStats = {};
  var subStatsPool = subStatsPools[maximize];
  // adjust substatspool to change priority of substats
  subStatsPool.forEach((subStat) => {
    if (
      subStat.stat != artifact.main.stat &&
      Object.keys(artifact.subStats).length < 4
    ) {
      artifact.subStats[subStat.stat] = 1;
    }
  });
  return artifact;
}

// function generateArtifact(type, payload) {
//   var artifact = {
//     type: type,
//     main: artifactTypes[type].main[0],
//     subStats: {},
//     subStatsLevel: 4,
//   };
//   artifact.subStats = {};
//   // adjust substatspool to change priority of substats
//   subStatsPool.forEach((subStat) => {
//     if (
//       subStat.stat != artifact.main.stat &&
//       Object.keys(artifact.subStats).length < 4
//     ) {
//       artifact.subStats[subStat.stat] = 1;
//     }
//   });
//   return artifact;
// }

function generateAllArtifacts(payload) {
  var artifacts = ['feather', 'flower', 'timePiece', 'goblet', 'hat'];
  return artifacts.map((name) => {
    var a = generateArtifact(name, payload);
    return a;
  });
}

function increaseArtifactSubStat(artifact, increase, decrease) {
  if (increase in artifact.subStats && decrease in artifact.subStats) {
    if (artifact.subStats[decrease] > 1) {
      artifact.subStats[increase]++;
      artifact.subStats[decrease]--;
      return true;
    }
  }
  return false;
}

function calculateSubStatsLevels(artifacts) {
  var stats = {
    flatAttack: {
      level: 0,
      value: 0,
    },
    attackPercent: {
      level: 0,
      value: 0,
    },
    elementalMastery: {
      level: 0,
      value: 0,
    },
    critRate: {
      level: 0,
      value: 0,
    },
    critDmg: {
      level: 0,
      value: 0,
    },
  };
  artifacts.forEach((a) => {
    for (var key in a.subStats) {
      stats[key].level += a.subStats[key];
      stats[key].value += subStats[key].mean * a.subStats[key];
    }
  });
  console.log('Substats Levels');
  console.log(stats);
  console.log('_______________________________________________________');
  return stats;
}

function calculateAttack(stats) {
  stats.attack =
    (stats.characterBaseAttack + stats.weaponAttack) *
      (1 + stats.attackPercent) +
    stats.flatAttack;
}

// var weapons = {
//   lostPrayer: {
//     weaponAttack: 608,
//     critRate: 0.331,
//     elementalDmg: 0.08 * 4,
//   },
//   skywardAtlas: {
//     weaponAttack: 674,
//     attackPercent: 0.331,
//     elementalDmg: 0.12,
//   },
//   // max refine
//   solarPearl: {
//     weaponAttack: 510,
//     critRate: 0.276,
//     elementalDmg: 0.4,
//   },
//   // max refine
//   theWidsith: {
//     weaponAttack: 510,
//     critDmg: 0.551,
//     elementalDmg: 0.96,
//     // attackPercent: 1.2,
//   },
//   wolfsGravestone: {
//     weaponAttack: 608,
//     attackPercent: 1.096,
//   },
// };

function calculateBaseStats(payload) {
  var { character, weapon, skill, additionalStats, settings } = payload;

  var stats = {
    characterBaseAttack: 0,
    characterBaseDefense: 0,
    weaponAttack: 0,
    attackPercent: 0,
    flatAttack: 0,
    attack: 0,
    critRate: 0.05,
    critDmg: 0.5,
    elementalDmg: 0,
    physicalDmg: 0,
    elementalMastery: 0,
    reactionBonus: 0,
    bonusDmg: 0,
    defensePercent: 0,
    defense: 0,
    flatDefense: 0,
    hp: 0,
    characterBaseHp: 0,
    hpPercent: 0,
    flatHp: 0,
    hpScalingAttack: 0,
    hpScalingFlatDamage: 0,
    defenseScalingAttack: 0,
    defenseScalingFlatDamage: 0,
    flatDmg: 0,
    energyRecharge: 1,
    emblemOfSeveredFate: false,
    RaidenElementalDmgBonus: false,
    engulfingLightningAttackBoost: 0,
    overloadedCount: 0,
    electroChargedCount: 0,
    swirlCount: 0,
    superconductCount: 0,
    shatterCount: 0,
    burningCount: 0,
    spreadCount: 0,
    aggrevateCount: 0,
    bloomCount: 0,
    burgeonCount: 0,
    hyperbloomCount: 0,
    emScalingFlatDamage: 0,
    emScalingAttack: 0,
  };
  var currentCharacter = characters[character.name].levels[90];
  for (var key in currentCharacter) {
    stats[key] += currentCharacter[key];
  }
  var currentWeapon = weapons[weapon.name];
  console.log('currentWeapon', currentWeapon);
  var refine = weapon.refine;

  var weaponBaseStats = currentWeapon.levels[90];
  var bonuses = currentWeapon.bonuses;
  var situationalBonus = currentWeapon.situationalBonuses[weapon.situationalBonus]; // prettier-ignore
  var situationalBonusStats = [];
  if (situationalBonus.stats) {
    situationalBonusStats = situationalBonus.stats;
  }
  // gathers all stats from weapon and adds them to the character's base stats
  var allWeaponStats = [
    ...situationalBonusStats,
    ...bonuses,
    ...weaponBaseStats,
  ];
  allWeaponStats.forEach((o) => {
    var refineMultiplier = 1;
    if (o.refinementScaling) {
      var customRefine = o.customRefine || 0.25;
      refineMultiplier = 1 + customRefine * (refine - 1);
    }
    if (o.type in stats) {
      stats[o.type] += o.value * refineMultiplier;
    }
  });

  // adds additional stats into stats
  if (additionalStats) {
    for (var key in additionalStats) {
      stats[key] += additionalStats[key];
    }
  }
  return stats;
}

function calculateStatsWithArtifacts(stats, artifacts, payload) {
  var tempStats = { ...stats };
  var { character } = payload;
  artifacts.forEach((a) => {
    if (a.main.stat in tempStats) {
      tempStats[a.main.stat] += a.main.value;
    } else {
      tempStats[a.main.stat] = a.main.value;
    }
    for (var key in a.subStats) {
      if (key in tempStats) {
        tempStats[key] += a.subStats[key] * subStats[key].mean;
      } else {
        tempStats[key] = a.subStats[key] * subStats[key].mean;
      }
    }
  });
  if (tempStats.engulfingLightningAttackBoost) {
    var engulfingAttackPercent =
      (tempStats.energyRecharge - 1) * tempStats.engulfingLightningAttackBoost;
    if (engulfingAttackPercent > tempStats.engulfingLightningMax) {
      engulfingAttackPercent = tempStats.engulfingLightningMax;
    }
    tempStats.attackPercent += engulfingAttackPercent;
  }

  tempStats.attack =
    (tempStats.characterBaseAttack + tempStats.weaponAttack) *
      (1 + tempStats.attackPercent) +
    tempStats.flatAttack +
    tempStats.elementalMastery * tempStats.emScalingAttack;

  if (tempStats.characterBaseHp) {
    tempStats.hp =
      tempStats.characterBaseHp * (1 + tempStats.hpPercent) + tempStats.flatHp;
  }
  if (tempStats.hpScalingAttack) {
    tempStats.attack += tempStats.hp * tempStats.hpScalingAttack;
  }

  if (tempStats.characterBaseDefense) {
    tempStats.defense =
      tempStats.characterBaseDefense * (1 + tempStats.defensePercent) +
      tempStats.flatDefense;
  }
  if (tempStats.defenseScalingAttack) {
    tempStats.attack += tempStats.defense * tempStats.defenseScalingAttack;
  }
  if (tempStats.emblemOfSeveredFate) {
    var emblemBonus = tempStats.energyRecharge * 0.25;
    if (emblemBonus > 0.75) {
      emblemBonus = 0.75;
    }
    tempStats.bonusDmg += emblemBonus;
  }
  if (tempStats.RaidenElementalDmgBonus) {
    var bonusElectro = (tempStats.energyRecharge - 1) * 0.4;
    tempStats.elementalDmg += bonusElectro;
  }
  tempStats.defense =
    tempStats.characterBaseDefense * (1 + tempStats.defensePercent) +
    tempStats.flatDefense;
  if (tempStats.critRate > 1) {
    tempStats.critRate = 1;
  }
  if (character.name == 'Yae') {
    tempStats.elementalDmg += 0.0015 * tempStats.elementalMastery;
  }
  if (character.name == 'Tighnari') {
    var tempBonusDmg = 0.0006 * tempStats.elementalMastery;
    if (tempBonusDmg > 0.6) {
      tempStats.bonusDmg += 0.6;
    } else {
      tempStats.bonusDmg += tempBonusDmg;
    }
  }
  if (character.name == 'DendroTraveler') {
    var tempBonusDmg = 0.015 * tempStats.elementalMastery;
    tempStats.bonusDmg += tempBonusDmg;
  }
  return tempStats;
}

function maximizeCritRate(stats, artifacts) {
  // prioritize artifacts without atk % as the main stat. this way, we have the option of moving
  var currentStats = calculateStatsWithArtifacts(stats, artifacts);
  var neededLevels = Math.floor(
    (1 - currentStats.critRate) / subStats.critRate.mean,
  );
  // i substract 1 here to avoid going over 100%
  var allocatedLevels = 0;
  var noMoreAttackPercentArtifacts = false;
  while (allocatedLevels < neededLevels) {
    // eslint-disable-next-line no-loop-func
    artifacts.forEach((a, i) => {
      if (a.main.stat === 'attackPercent') {
        if ('critRate' in a.subStats && a.subStatsLevel < USEFUL_SUBSTATS) {
          while (
            a.subStatsLevel < USEFUL_SUBSTATS &&
            allocatedLevels < neededLevels
          ) {
            a.subStats['critRate']++;
            a.subStatsLevel++;
            allocatedLevels++;
          }
        }
      } else if (
        noMoreAttackPercentArtifacts &&
        allocatedLevels < neededLevels
      ) {
        if ('critRate' in a.subStats && a.subStatsLevel < USEFUL_SUBSTATS) {
          a.subStats['critRate']++;
          a.subStatsLevel++;
          allocatedLevels++;
        }
      }
      if (i === artifacts.length - 1) {
        noMoreAttackPercentArtifacts = true;
      }
    });
  }
}

function maximizeStat(artifacts, statType) {
  artifacts.forEach((a) => {
    if (statType in a.subStats) {
      while (a.subStatsLevel < USEFUL_SUBSTATS) {
        a.subStats[statType]++;
        a.subStatsLevel++;
      }
    }
  });
}

function maximizeFirstStat(artifacts) {
  artifacts.forEach((a) => {
    for (var statType in a.subStats) {
      while (a.subStatsLevel < USEFUL_SUBSTATS) {
        a.subStats[statType]++;
        a.subStatsLevel++;
      }
    }
  });
}

function printObject(obj, name) {
  console.log('Printing ' + name);
  for (var key in obj) {
    console.log(key + ':', obj[key]);
  }
  console.log('_____________________________________________');
}

function printArtifacts(artifacts) {
  artifacts.forEach((a) => {
    console.log(a.type);
    console.log('main stat:', a.main);
    console.log('sub stats:', a.subStats);
    console.log('substats level:', a.subStatsLevel);
    console.log('_____________________________________________');
  });
}

var swirlMap = {
  90: 868,
  80: 648,
  70: 460,
};

function calculateEMBonus(stats) {
  // https://genshin-impact.fandom.com/wiki/Attributes#Elemental_Mastery_Formula
  var EM = stats.elementalMastery;

  // var vaporize = (1 * EM * (25 / 9)) / (EM + 1400);
  var vaporize = (2.78 * EM) / (1400 + EM);
  var overloaded = (16 * EM) / (2000 + EM);
  var spread = (5 * EM) / (1200 + EM);
  return {
    vaporize,
    overloaded,
    overloadedCount: overloaded,
    electroChargedCount: overloaded,
    superconductCount: overloaded,
    shatterCount: overloaded,
    burningCount: overloaded,
    bloomCount: overloaded,
    burgeonCount: overloaded,
    hyperbloomCount: overloaded,
    spreadCount: spread,
    aggrevateCount: spread,
    swirlCount: overloaded,
  };
}

var transformativeReactionMap = {
  overloadedCount: 2893.71,
  electroChargedCount: 1736.2,
  swirlCount: 868.11,
  superconductCount: 723.43,
  shatterCount: 2170.28,
  burningCount: 361,
  spreadCount: 1808,
  aggrevateCount: 1664,
  bloomCount: 2893,
  burgeonCount: 4340,
  hyperbloomCount: 4340,
};

function calculateTransformativeReactionDamage(
  stats,
  settings,
  EMBonuses,
  reactionBonus,
  transformativeReactions,
) {
  var { elementalDmg, bonusDmg, critRate, critDmg } = stats;
  var { enemyLevel, enemyResist, defenseDown } = settings;
  var characterLevel = 90;
  var damage = 0;
  for (var key in transformativeReactions) {
    var count = transformativeReactions[key];
    if (count > 0) {
      if (key != 'aggrevateCount' && key != 'spreadCount') {
        damage +=
          count *
          transformativeReactionMap[key] *
          (1 + EMBonuses[key] + reactionBonus) *
          (1 - enemyResist);
      } else {
        var flatDmg =
          count *
          transformativeReactionMap[key] *
          (1 + EMBonuses[key] + reactionBonus);
        var flatDamageBase =
          flatDmg *
          (1 + elementalDmg + bonusDmg) *
          ((characterLevel + 100) /
            ((enemyLevel + 100) * (1 - defenseDown) + (characterLevel + 100))) *
          (1 - enemyResist);
        var damageCrit = flatDamageBase * (1 + critDmg);
        var averageDamage =
          flatDamageBase * (1 - critRate) + damageCrit * critRate;
        damage += averageDamage;
      }
    }
  }
  return damage;
}

function calculateDamage(stats, payload) {
  var { character, settings } = payload;
  var { maximize, enemyLevel, enemyResist, defenseDown } = settings;
  var {
    attack,
    elementalDmg,
    physicalDmg,
    bonusDmg,
    critRate,
    critDmg,
    reactionBonus,
    flatDmg,
    defense,
    hp,
    hpScalingFlatDamage,
    defenseScalingFlatDamage,
    // energyRecharge,
    elementalMastery,
    overloadedCount,
    electroChargedCount,
    swirlCount,
    superconductCount,
    shatterCount,
    burningCount,
    spreadCount,
    aggrevateCount,
    bloomCount,
    burgeonCount,
    hyperbloomCount,
    emScalingFlatDamage,
    emScalingAttack,
  } = stats;
  var transformativeReactions = {
    overloadedCount,
    electroChargedCount,
    swirlCount,
    superconductCount,
    shatterCount,
    burningCount,
    spreadCount,
    aggrevateCount,
    bloomCount,
    burgeonCount,
    hyperbloomCount,
  };
  var EMBonuses = calculateEMBonus(stats);
  var attackStat = attack;
  if (!flatDmg) {
    flatDmg = 0;
  }
  if (hpScalingFlatDamage) {
    flatDmg += hpScalingFlatDamage * hp;
  }
  if (defenseScalingFlatDamage) {
    flatDmg += defenseScalingFlatDamage * defense;
  }
  flatDmg += emScalingFlatDamage * elementalMastery;
  if (maximize == 'defenseScaling') {
    attackStat = defense;
  }
  if (maximize == 'eosfHPScaling' || maximize == 'onlyHpScaling') {
    attackStat = 0;
  }
  var characterLevel = character.level;

  var skill = settings.skill;
  var physicalSkill = settings?.physicalSkill || settings.skill;
  var damage =
  (attackStat * skill + flatDmg) *
  (1 + elementalDmg + bonusDmg) *
  ((characterLevel + 100) / ((enemyLevel + 100) * (1 - defenseDown) + (characterLevel + 100))) *
  (1 - enemyResist); // prettier-ignore
  var damageCrit = damage * (1 + critDmg);
  var averageDamage = damage * (1 - critRate) + damageCrit * critRate; // prettier-ignore
  var hydroVaporize = 2 * (1 + EMBonuses.vaporize + (reactionBonus ? reactionBonus : 0)) * damage; // prettier-ignore
  var hydroVaporizeCrit = hydroVaporize * (1 + critDmg);
  var hydroVaporizeAverage = hydroVaporize * (1 - critRate) + hydroVaporizeCrit * critRate; // prettier-ignore
  var pyroVaporize = 1.5 * (1 + EMBonuses.vaporize + (reactionBonus ? reactionBonus : 0)) * damage; // prettier-ignore
  var pyroVaporizeCrit = pyroVaporize * (1 + critDmg);
  var pyroVaporizeAverage = pyroVaporize * (1 - critRate) + pyroVaporizeCrit * critRate; // prettier-ignore

  var physicalDamage = (attackStat * physicalSkill + flatDmg) * (1 + physicalDmg + bonusDmg) * ((characterLevel + 100) / (enemyLevel + 100 + (characterLevel + 100))) * (1 - enemyResist); // prettier-ignore
  var physicalDamageCrit = physicalDamage * (1 + critDmg);
  var physicalAverageDamage = physicalDamage * (1 - critRate) + physicalDamageCrit * critRate; // prettier-ignore
  var bothAverage = (physicalAverageDamage + averageDamage) / 2;
  var averageAndReaction = 0;
  // calculate damage from transformative reactions and including spread/aggrevate
  var transformativeReactionCount = 0;
  var transformativeReactionDamage = 0;
  for (var key in transformativeReactions) {
    transformativeReactionCount += transformativeReactions[key];
  }
  if (transformativeReactionCount > 0) {
    transformativeReactionDamage = calculateTransformativeReactionDamage(
      stats,
      settings,
      EMBonuses,
      reactionBonus,
      transformativeReactions,
    );
  }
  var temp = 0;
  if (maximize == 'averageAndReaction') {
    temp += averageDamage;
    averageAndReaction += averageDamage;
    averageAndReaction += transformativeReactionDamage;
    averageDamage = averageAndReaction;
  }
  return {
    damage,
    damageCrit,
    averageDamage,
    physicalDamage,
    physicalDamageCrit,
    physicalAverageDamage,
    bothAverage,
    hydroVaporize,
    hydroVaporizeCrit,
    hydroVaporizeAverage,
    pyroVaporize,
    pyroVaporizeCrit,
    pyroVaporizeAverage,
    transformativeReactionDamage,
    averageAndReaction,
    temp,
  };
}

var subStatsH = [
  'attackPercent',
  'critRate',
  'critDmg',
  'elementalMastery',
  'flatAttack',
  'defensePercent',
  'flatDefense',
  'hpPercent',
  'flatHp',
  'energyRecharge',
];
var subStatsV = [
  'attackPercent',
  'critRate',
  'critDmg',
  'elementalMastery',
  'flatAttack',
  'defensePercent',
  'flatDefense',
  'hpPercent',
  'flatHp',
  'energyRecharge',
];

var maximizeMap = {
  average: 'averageDamage',
  vaporize: 'hydroVaporizeCrit',
  vaporizeAverage: 'hydroVaporizeAverage',
  physical: 'physicalAverageDamage',
  both: 'bothAverage',
  attackGoblet: 'bothAverage',
  defenseScaling: 'averageDamage',
  defenseScalingAttack: 'averageDamage',
  hpAndAttackScalingElemental: 'averageDamage',
  hpAndAttackScalingPhysical: 'physicalAverageDamage',
  hpAndAttackScalingVaporize: 'hydroVaporizeAverage',
  eosfAttack: 'averageDamage',
  eosfAttackVape: 'hydroVaporizeAverage',
  eosfHPScaling: 'averageDamage',
  onlyHpScaling: 'averageDamage',
  averageAndReaction: 'averageAndReaction',
};

const maximizeOptions = [
  { value: 'average', display: 'Average Elemental Damage' },
  { value: 'vaporizeAverage', display: 'Vaporize/Melt Average Damage' },
  { value: 'physical', display: 'Average Physical Damage' },
  {
    value: 'eosfAttack',
    display: 'Emblem of Severed Fate Attack scaling (+20% ER)',
  },
  {
    value: 'eosfAttackVape',
    display: 'Emblem of Severed Fate Attack scaling Vape/Melt (+20% ER)',
  },
  {
    value: 'eosfHPScaling',
    display: 'Emblem of Severed Fate 100% HP scaling (+20% ER)',
  },
  // { value: 'both', display: '' },
  // { value: 'attackGoblet', display: '' },
  // {
  //   value: 'hpAndAttackScalingElemental',
  //   display: 'HP and Attack scaling Elemental Damage',
  // },
  // {
  //   value: 'hpAndAttackScalingPhysical',
  //   display: 'HP and Attack scaling Physical Damage',
  // },
  // {
  //   value: 'hpAndAttackScalingVaporize',
  //   display: 'HP and Attack scaling Vaporize/Melt',
  // },
  {
    value: 'defenseScalingAttack',
    display: 'Attack that scales off Defense stat (Itto and Noelle)',
  },
  { value: 'vaporize', display: 'Vaporize/Melt Crit Damage' },
  {
    value: 'defenseScaling',
    display: '100% Defense Scaling Elemental Damage (Albedo)',
  },
  {
    value: 'onlyHpScaling',
    display: '100% HP scaling Elemental Damage',
  },
  {
    value: 'averageAndReaction',
    display: 'Average Attack Scaling Damage AND Transformative Reactions',
  },
];

function optimizeSubStats(baseStats, artifacts, payload, previous) {
  var keepGoing = true;
  var previousArtifacts = JSON.parse(JSON.stringify(artifacts));
  var previousStats = calculateStatsWithArtifacts(
    baseStats,
    previousArtifacts,
    payload,
  );
  var previousDamage = calculateDamage(previousStats, payload);
  var maximizeThis = maximizeMap[payload.settings.maximize];
  for (var i = 0; i < subStatsH.length; i++) {
    for (var j = 0; j < subStatsV.length; j++) {
      var increase = subStatsH[i];
      var decrease = subStatsV[j];
      if (increase != decrease) {
        var doneSwapping = false;
        var arts = JSON.parse(JSON.stringify(previousArtifacts));
        for (var artIndex = 0; artIndex < arts.length; artIndex++) {
          var art = arts[artIndex];
          doneSwapping = increaseArtifactSubStat(art, increase, decrease);
          if (doneSwapping) {
            break;
          }
        }
        if (doneSwapping) {
          var currentStats = calculateStatsWithArtifacts(
            baseStats,
            arts,
            payload,
          );
          var currentDamage = calculateDamage(currentStats, payload);
          if (currentDamage[maximizeThis] > previousDamage[maximizeThis]) {
            previousArtifacts = JSON.parse(JSON.stringify(arts));
            previousStats = currentStats;
            previousDamage = currentDamage;
            // bestArtifacts = JSON.stringify(arts);
            j--;
          } else {
            // console.log("NOT SWAPPING");
          }
        }
      }
    }
  }
  if (previous) {
    if (previous.damage[maximizeThis] <= previousDamage[maximizeThis]) {
      keepGoing = false;
    }
  }
  if (keepGoing) {
    return optimizeSubStats(baseStats, previousArtifacts, payload, {
      damage: previousDamage,
    });
  } else {
    calculateArtifactValues(previousArtifacts);
    return {
      artifacts: previousArtifacts,
      damageSummary: previousDamage,
      stats: previousStats,
    };
  }
}

function calculateArtifactValues(artifacts) {
  artifacts.forEach((a) => {
    var values = [];
    for (var key in a.subStats) {
      var level = a.subStats[key];
      values.push({
        type: key,
        level: level,
        value: subStats[key].mean * level,
      });
    }
    a.values = values;
  });
}

function maximizeDamage(payload) {
  try {
    payload = sanitizePayload(payload);
    console.time('maximizeDamage');
    console.log('maximize damage start:', payload.settings.maximize);
    var { character, weapon, skill, additionalStats, settings } = payload;
    var { maximize } = settings;
    var stats = calculateBaseStats(payload);
    var baseStats = { ...stats };
    // if hpAndAttackScaling weapon, intercept and set maximize to hpAndAttackScaling
    if (baseStats.hpScalingAttack > 0 || baseStats.hpScalingFlatDamage > 0) {
      if (maximize === 'average') {
        maximize = 'hpAndAttackScalingElemental';
      } else if (maximize === 'physical') {
        maximize = 'hpAndAttackScalingPhysical';
      } else if (maximize === 'vaporizeAverage') {
        maximize = 'hpAndAttackScalingVaporize';
      }
    }
    if (baseStats.defenseScalingAttack > 0) {
      maximize = 'defenseScalingAttack';
    }
    if (
      maximize == 'eosfHPScaling' ||
      maximize == 'eosfAttack' ||
      maximize == 'eosfVape'
    ) {
      baseStats.emblemOfSeveredFate = true;
      baseStats.energyRecharge += 0.2;
    }
    // printObject(baseStats, 'base stats');

    var allArtifacts = generateAllPossibleArtifactCombos(payload);

    // console.log("getting damage summary");
    var allSummary = allArtifacts.map((artifacts) => {
      maximizeFirstStat(artifacts);
      return optimizeSubStats(baseStats, artifacts, payload);
    });
    allSummary.sort((a, b) => {
      var maximizeThis = maximizeMap[payload.settings.maximize];
      return b.damageSummary[maximizeThis] - a.damageSummary[maximizeThis];
    });
    // printArtifacts(allSummary[0].artifacts);
    console.timeEnd('maximizeDamage');
    return allSummary[0];
  } catch (error) {
    console.timeEnd('maximizeDamage');
    console.log(error);
    throw error;
  }
}

function sanitizePayload(payload) {
  var { character, weapon, additionalStats, settings } = payload;
  character.level = parseFloat(character.level);
  weapon.level = parseFloat(weapon.level);
  weapon.refine = parseFloat(weapon.refine);
  weapon.situationalBonus = parseFloat(weapon.situationalBonus);
  settings.skill = parseFloat(settings.skill);
  settings.enemyLevel = parseFloat(settings.enemyLevel);
  settings.enemyResist = parseFloat(settings.enemyResist);
  settings.defenseDown = parseFloat(settings.defenseDown) || 0;
  for (var key in additionalStats) {
    additionalStats[key] = parseFloat(additionalStats[key]);
  }
  return payload;
}

var exportThis = { calculateDamage, maximizeDamage, maximizeOptions };

export default exportThis;

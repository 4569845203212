// import Axios from "axios";
import React, { Component } from 'react';
import './styles.css';
import Artifact from '../../components/Artifact';
import Splash from '../../components/Splash';
import FormBox from '../../components/FormBox';
import utils from '../../utils/index';
import Weapons from '../../data/weapons';
import Characters from '../../data/characters';
import DamageMaximizer from '../../api/maximizeDamage';

var { capitalizeFirstLetter, convertStatTypeToText, convertStatValueToText } =
  utils;

var { maximizeDamage, maximizeOptions } = DamageMaximizer;
var { weaponsArray } = Weapons;
var { charactersArray } = Characters;

var maximizeDisplayMap = {
  average: 'Average Elemental Damage',
  physical: 'Average Physical Damage',
  vaporize: 'Vaporize/Melt Crit',
};

function findWeaponByName(name, weapons) {
  var weapon;
  weapons.forEach((o) => {
    if (o.name === name || o.display === name) {
      weapon = o;
    }
  });
  return weapon;
}
function filterWeaponsByType(type, weapons) {
  var filteredWeapons = [];
  weapons.forEach((o) => {
    if (o.type === type) {
      filteredWeapons.push(o);
    }
  });
  return filteredWeapons;
}

function validateFloat(value) {
  return /^\s*[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)\s*$/.test(value);
}

function validateWholeNumber(value) {
  return /^\s*\+?\d+\s*$/.test(value);
}

var startingCharacter = 'Tighnari';

class Home extends Component {
  state = {
    maximizeDisabled: false,
    character: { name: '', display: '', level: 90 },
    characters: charactersArray,
    characterStatsDisplay: '',
    currentDisplay: {
      character: { name: '', display: '', level: 90 },
      characterVision: '',
      settings: {
        skill: 13.0464,
        physicalSkill: 1.6,
        maximize: 'average',
        enemyLevel: 90,
        enemyResist: 0.1,
      },
      weapon: {
        name: '',
        display: '',
        level: 90,
      },
    },
    weapon: {
      name: '',
      level: 90,
      refine: 1,
      situationalBonus: 0,
    },
    weaponStatsDisplay: '',
    situationalBonusDisplay: '',
    currentWeapon: null,
    weapons: [],
    allWeapons: weaponsArray,
    additionalStatsChecked: false,
    transformativeReactionsChecked: false,
    // additionalStats: {
    //   elementalDmg: 0,
    //   attackPercent: 0,
    //   flatAttack: 0,
    // },
    artifacts: [],
    settings: {
      skill: 13.0464,
      physicalSkill: 1.6,
      maximize: 'average',
      enemyLevel: 90,
      enemyResist: 0.1,
    },
    ASflatAttack: 0,
    ASattackPercent: 0,
    ASelementalDmg: 0,
    ASphysicalDmg: 0,
    ASbonusDmg: 0,
    ASelementalMastery: 0,
    AScritRate: 0,
    AScritDmg: 0,
    ASenemyResist: 0.1,
    ASreactionBonus: 0,
    AShpScalingFlatDamage: 0,
    AShpPercent: 0,
    ASdefensePercent: 0,
    ASflatDefense: 0,
    AShpScalingAttack: 0,
    ASFlatDmg: 0,
    ASdefenseScalingAttack: 0,
    ASdefenseScalingFlatDamage: 0,
    ASenergyRecharge: 0,
    ASemScalingFlatDamage: 0,
    ASoverloaded: 0,
    ASelectroCharged: 0,
    ASswirl: 0,
    ASsuperconduct: 0,
    ASshatter: 0,
    ASspread: 0,
    ASaggrevate: 0,
    ASburning: 0,
    ASbloom: 0,
    ASburgeon: 0,
    AShyperbloom: 0,
  };
  componentDidMount() {
    var character = this.getCharacter(startingCharacter);
    var filteredWeapons = filterWeaponsByType(character.weapon, weaponsArray);
    var currentWeapon = filteredWeapons[0];
    var characterCurrent = {
      name: character.name,
      level: 90,
      vision: character.vision,
    };
    var weaponCurrent = {
      name: currentWeapon.name,
      level: 90,
      refine: 1,
      situationalBonus: 0,
    };
    this.setState(
      {
        character: characterCurrent,
        weapon: weaponCurrent,
        currentWeapon: currentWeapon,
        weapons: filteredWeapons,
      },
      () => {
        console.log(this.state);
        this.updateAllWeaponDisplay();
        this.updateCharacterStatsDisplay();
      },
    );
  }
  checkMaximizeDisabled() {
    var { skill, enemyLevel } = this.state.settings;
    var floats = [skill];
    var wholes = [enemyLevel];
    var disabled = false;
    if (this.state.transformativeReactionsChecked) {
      var {
        ASoverloaded,
        ASelectroCharged,
        ASswirl,
        ASsuperconduct,
        ASshatter,
        ASspread,
        ASaggrevate,
        ASburning,
        ASbloom,
        ASburgeon,
        AShyperbloom,
      } = this.state;
      wholes.push(
        ASoverloaded,
        ASelectroCharged,
        ASswirl,
        ASsuperconduct,
        ASshatter,
        ASspread,
        ASaggrevate,
        ASburning,
        ASbloom,
        ASburgeon,
        AShyperbloom,
      );
    }
    if (this.state.additionalStatsChecked) {
      var {
        ASflatAttack,
        ASattackPercent,
        ASelementalDmg,
        ASphysicalDmg,
        ASbonusDmg,
        ASelementalMastery,
        AScritRate,
        AScritDmg,
        ASenemyResist,
        ASreactionBonus,
        AShpScalingFlatDamage,
        AShpPercent,
        ASdefensePercent,
        ASflatDefense,
        AShpScalingAttack,
        ASFlatDmg,
        ASdefenseScalingAttack,
        ASdefenseScalingFlatDamage,
        ASenergyRecharge,
        ASemScalingFlatDamage,
      } = this.state;
      floats.push(
        ASattackPercent,
        ASelementalDmg,
        ASphysicalDmg,
        ASbonusDmg,
        AScritRate,
        AScritDmg,
        ASenemyResist,
        ASreactionBonus,
        AShpScalingFlatDamage,
        AShpPercent,
        ASdefensePercent,
        ASflatDefense,
        AShpScalingAttack,
        ASFlatDmg,
        ASdefenseScalingAttack,
        ASdefenseScalingFlatDamage,
        ASenergyRecharge,
        ASemScalingFlatDamage,
      );
      wholes.push(ASflatAttack, ASelementalMastery);
    }
    for (var i = 0; i < floats.length; i++) {
      if (!validateFloat(floats[i])) {
        console.log('flat', floats[i]);
        disabled = true;
      }
    }
    for (var i = 0; i < wholes.length; i++) {
      if (!validateWholeNumber(wholes[i])) {
        console.log('whole', wholes[i]);
        disabled = true;
      }
    }
    this.setState({ maximizeDisabled: disabled });
  }
  updateAllWeaponDisplay() {
    this.updateWeaponStatsDisplay();
    this.updateSituationalBonusDisplay();
  }
  getCharacter(name) {
    var character;
    this.state.characters.forEach((o) => {
      if (o.name === name || o.display === name) {
        character = o;
      }
    });
    return character;
  }
  getCharacterVision(name) {
    var vision;
    this.state.characters.forEach((o) => {
      if (o.name === name || o.display === name) {
        vision = o.vision;
      }
    });
    return vision;
  }
  getWeaponDisplay(name) {
    var display;
    this.state.weapons.forEach((o) => {
      if (o.name === name || o.display === name) {
        display = o.display;
      }
    });
    return display;
  }
  updateCharacterStatsDisplay() {
    var characters = this.state.characters;
    var { name, level } = this.state.character;
    for (var i = 0; i < characters.length; i++) {
      if (characters[i].name === name) {
        var stats = characters[i].levels[level];
        var displayStr = '';
        var vision = this.getCharacterVision(name);
        for (var key in stats) {
          var stat = { type: key, value: stats[key] };
          displayStr +=
            convertStatTypeToText({
              type: stat.type,
              vision: vision,
            }) +
            '(' +
            convertStatValueToText(stat) +
            ') ';
        }
        this.setState({
          characterStatsDisplay: displayStr,
        });
      }
    }
  }
  updateWeaponStatsDisplay() {
    var { currentWeapon } = this.state;
    var refine = this.state.weapon.refine;
    // var weaponLevel = this.state.weapon.level;
    var customRefine = this.state.weapon.customRefine || 0.25;
    var refinementMultiplier = 1 + customRefine * (refine - 1);
    var stats = {};
    var displayStr = '';
    var baseStats = currentWeapon.levels[90];
    baseStats.forEach((o) => {
      if (o.type in stats) {
        stats[o.type] += o.value;
      } else {
        stats[o.type] = o.value;
      }
    });
    if (currentWeapon.bonuses.length > 0) {
      currentWeapon.bonuses.forEach((o) => {
        var value = o.value;
        if (o.refinementScaling) {
          value = value * refinementMultiplier;
        }
        if (o.type in stats) {
          stats[o.type] += value;
        } else {
          stats[o.type] = value;
        }
      });
    }
    for (var key in stats) {
      var stat = { type: key, value: stats[key] };
      displayStr +=
        convertStatTypeToText(stat) + '(' + convertStatValueToText(stat) + ') ';
    }
    this.setState({ weaponStatsDisplay: displayStr });
  }
  updateSituationalBonusDisplay = () => {
    var currentWeapon = this.state.currentWeapon;
    var situationalBonusIndex = this.state.weapon.situationalBonus;
    var currentSituationalBonus =
      currentWeapon.situationalBonuses[situationalBonusIndex];

    var refine = this.state.weapon.refine;

    var stats = {};
    var displayStr = '';
    if (
      currentSituationalBonus &&
      currentSituationalBonus.stats &&
      currentSituationalBonus.stats.length > 0
    ) {
      currentSituationalBonus.stats.forEach((o) => {
        var value = o.value;
        if (o.refinementScaling) {
          var customRefine = o.customRefine || 0.25;
          var refinementMultiplier = 1 + customRefine * (refine - 1);
          value = value * refinementMultiplier;
        }
        if (o.type in stats) {
          stats[o.type] += value;
        } else {
          stats[o.type] = value;
        }
      });
    }

    for (var key in stats) {
      var stat = { type: key, value: stats[key] };
      displayStr +=
        convertStatTypeToText(stat) + '(' + convertStatValueToText(stat) + ') ';
    }
    this.setState({ situationalBonusDisplay: displayStr });
  };
  handleMaximizeChange = (event) => {
    var settings = { ...this.state.settings };
    settings.maximize = event.target.value;
    this.setState(
      {
        settings,
      },
      () => {
        console.log(this.state.settings);
      },
    );
  };
  handleCharacterChange = (event) => {
    var character = { ...this.state.character };
    var characterData = this.getCharacter(event.target.value);
    for (var key in characterData) {
      character[key] = characterData[key];
    }
    // character.vision = characterData.vision;
    var weapons = filterWeaponsByType(
      characterData.weapon,
      this.state.allWeapons,
    );
    var weapon = { ...this.state.weapon };
    weapon.name = weapons[0].name;
    var settings = { ...this.state.settings };
    var ASdefenseScalingAttack = this.state.ASdefenseScalingAttack;
    ASdefenseScalingAttack = 0;
    var ASdefenseScalingFlatDamage = this.state.ASdefenseScalingFlatDamage;
    ASdefenseScalingFlatDamage = 0;
    var ASenergyRecharge = this.state.ASenergyRecharge;
    ASenergyRecharge = 0;
    var ASemScalingFlatDamage = this.state.ASemScalingFlatDamage;
    ASemScalingFlatDamage = 0;
    var AShpScalingAttack = this.state.AShpScalingAttack;
    AShpScalingAttack = 0;
    var ASFlatDmg = this.state.ASFlatDmg;
    ASFlatDmg = 0;
    var AShpScalingFlatDamage = this.state.AShpScalingFlatDamage;
    AShpScalingFlatDamage = 0;
    settings.maximize = 'average';
    this.setState(
      {
        character,
        weapon,
        weapons,
        settings,
        ASdefenseScalingAttack,
        AShpScalingAttack,
        ASFlatDmg,
        AShpScalingFlatDamage,
        ASdefenseScalingFlatDamage,
        ASenergyRecharge,
        ASemScalingFlatDamage,
      },
      () => {
        this.updateCharacterStatsDisplay();
        this.handleWeaponChange({ target: { value: weapons[0].name } });
      },
    );
  };
  handleWeaponChange = (event) => {
    var weapon = { ...this.state.weapon };
    weapon.name = event.target.value;
    weapon.situationalBonus = 0;
    var currentWeapon = findWeaponByName(
      event.target.value,
      this.state.weapons,
    );
    // var currentWeapon = this.state.weapons.filter((o) => {
    //   return (o.name = weapon.name);
    // })[0];
    this.setState({ weapon, currentWeapon }, () => {
      this.updateAllWeaponDisplay();
    });
  };
  handleWeaponRefineChange = (event) => {
    var weapon = { ...this.state.weapon };
    weapon.refine = parseFloat(event.target.value);
    this.setState({ weapon }, () => {
      this.updateAllWeaponDisplay();
    });
  };
  handleSituationalBonusChange = (event) => {
    var weapon = { ...this.state.weapon };
    weapon.situationalBonus = parseFloat(event.target.value);
    this.setState({ weapon }, () => {
      this.updateAllWeaponDisplay();
    });
  };
  handleSkillChange = (event) => {
    var settings = { ...this.state.settings };
    settings.skill = event.target.value;
    this.setState({ settings }, () => {
      this.checkMaximizeDisabled();
    });
  };
  handlePhysicalSkillChange = (event) => {
    var settings = { ...this.state.settings };
    settings.physicalSkill = event.target.value;
    this.setState({ settings }, () => {
      this.checkMaximizeDisabled();
    });
  };
  handleEnemyLevelChange = (event) => {
    var settings = { ...this.state.settings };
    settings.enemyLevel = event.target.value;
    this.setState({ settings }, () => {
      this.checkMaximizeDisabled();
    });
  };
  handleCharacterLevelChange = (event) => {
    var character = { ...this.state.character };
    character.level = parseInt(event.target.value);
    this.setState({ character }, () => {
      this.updateCharacterStatsDisplay();
    });
  };
  handleWeaponLevelChange = (event) => {
    var weapon = { ...this.state.weapon };
    weapon.level = parseInt(event.target.value);
    this.setState({ weapon }, () => {
      this.updateAllWeaponDisplay();
    });
  };
  onRadioChanged = (event) => {
    var settings = { ...this.state.settings };
    settings.maximize = event.target.value;
    this.setState({ settings }, () => {});
  };
  handleInputChange = (event) => {
    const target = event.target;
    var value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value }, () => {
      this.checkMaximizeDisabled();
    });
  };
  maximizeDamage = () => {
    this.setState({ maximizeDisabled: true }, () => {
      var payload = {
        character: { ...this.state.character },
        weapon: { ...this.state.weapon },
        additionalStats: {},
        settings: { ...this.state.settings },
      };
      var {
        ASattackPercent,
        ASflatAttack,
        ASelementalDmg,
        ASphysicalDmg,
        ASbonusDmg,
        ASelementalMastery,
        ASenemyResist,
        AScritRate,
        AScritDmg,
        ASreactionBonus,
        AShpScalingFlatDamage,
        AShpPercent,
        ASdefensePercent,
        ASflatDefense,
        AShpScalingAttack,
        ASFlatDmg,
        ASdefenseScalingAttack,
        ASdefenseScalingFlatDamage,
        ASenergyRecharge,
        ASemScalingFlatDamage,
        ASoverloaded,
        ASelectroCharged,
        ASswirl,
        ASsuperconduct,
        ASshatter,
        ASspread,
        ASaggrevate,
        ASburning,
        ASbloom,
        ASburgeon,
        AShyperbloom,
      } = this.state;
      payload.additionalStats = {
        attackPercent: parseFloat(ASattackPercent),
        flatAttack: parseFloat(ASflatAttack),
        bonusDmg: parseFloat(ASbonusDmg),
        elementalDmg: parseFloat(ASelementalDmg),
        physicalDmg: parseFloat(ASphysicalDmg),
        elementalMastery: parseFloat(ASelementalMastery),
        critRate: parseFloat(AScritRate),
        critDmg: parseFloat(AScritDmg),
        reactionBonus: parseFloat(ASreactionBonus),
        hpScalingFlatDamage: parseFloat(AShpScalingFlatDamage),
        hpScalingAttack: parseFloat(AShpScalingAttack),
        flatDmg: parseFloat(ASFlatDmg),
        hpPercent: parseFloat(AShpPercent),
        defensePercent: parseFloat(ASdefensePercent),
        flatDefense: parseFloat(ASflatDefense),
        defenseScalingAttack: parseFloat(ASdefenseScalingAttack),
        defenseScalingFlatDamage: parseFloat(ASdefenseScalingFlatDamage),
        energyRecharge: parseFloat(ASenergyRecharge),
        emScalingFlatDamage: parseFloat(ASemScalingFlatDamage),
        overloadedCount: parseFloat(ASoverloaded),
        electroChargedCount: parseFloat(ASelectroCharged),
        swirlCount: parseFloat(ASswirl),
        superconductCount: parseFloat(ASsuperconduct),
        shatterCount: parseFloat(ASshatter),
        spreadCount: parseFloat(ASspread),
        aggrevateCount: parseFloat(ASaggrevate),
        burningCount: parseFloat(ASburning),
        bloomCount: parseFloat(ASbloom),
        burgeonCount: parseFloat(ASburgeon),
        hyperbloomCount: parseFloat(AShyperbloom),
      };
      payload.settings.enemyResist = parseFloat(ASenemyResist);
      payload.weapon.display = this.getWeaponDisplay(payload.weapon.name);
      try {
        var data = maximizeDamage(payload);
        this.setState(
          {
            maximizeDisabled: false,
            artifacts: data.artifacts,
            damageSummary: data.damageSummary,
            stats: data.stats,
            currentDisplay: payload,
            error: null,
          },
          () => {
            this.damageDisplayRef.scrollIntoView();
            console.log(data.damageSummary);
          },
        );
      } catch (error) {
        this.setState({
          maximizeDisabled: false,
          artifacts: [],
          damageSummary: null,
          stats: {},
          error: error.message + ' Check your form inputs!',
        });
      }
    });
  };
  checkIfRadioDisabled = () => {
    // if (
    //   this.state.weapon.name === "staffOfHoma" ||
    //   this.state.weapon.name === "primordialJadeCutter"
    // ) {
    //   return true;
    // }
    return false;
  };
  renderDamage = () => {};

  render() {
    return (
      <div className="container myContainerBG">
        <Splash />
        <form>
          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="selectCharacter">Character</label>
                <select
                  id="selectCharacter"
                  className="form-control"
                  value={this.state.character.name}
                  onChange={this.handleCharacterChange}
                >
                  {this.state.characters.length > 0 ? (
                    this.state.characters.map((o, i) => {
                      let value = o.name;
                      if (o.display) {
                        value = o.display;
                      }
                      return (
                        <option key={i} value={value}>
                          {value}
                        </option>
                      );
                    })
                  ) : (
                    <option value="Klee">Klee</option>
                  )}
                </select>
                <p>
                  <small>{this.state.characterStatsDisplay}</small>
                </p>
              </div>
              {/* <div className="form-group">
                <label htmlFor="characterLevel">Character Level</label>
                <select
                  id="characterLevel"
                  className="form-control"
                  value={this.state.character.level}
                  onChange={this.handleCharacterLevelChange}
                >
                  <option value="90">90</option>
                  <option value="80">80</option>
                  <option value="70">70</option>
                </select>
              </div> */}
              <div className="form-group">
                <label htmlFor="skillMultiplier">
                  Elemental Skill Multiplier{' '}
                  <span
                    className={
                      validateFloat(this.state.settings.skill) ? '' : 'warning'
                    }
                  >
                    (% as decimal)
                  </span>
                </label>
                <input
                  id="skillMultiplier"
                  className="form-control"
                  value={this.state.settings.skill}
                  onChange={this.handleSkillChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="physicalSkillMultiplier">
                  Physical Skill Multiplier{' '}
                  <span
                    className={
                      validateFloat(this.state.settings.physicalSkill)
                        ? ''
                        : 'warning'
                    }
                  >
                    (% as decimal)
                  </span>
                </label>
                <input
                  id="physicalSkillMultiplier"
                  className="form-control"
                  value={this.state.settings.physicalSkill}
                  onChange={this.handlePhysicalSkillChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="enemyLevel">
                  Enemy Level{' '}
                  <span
                    className={
                      validateWholeNumber(this.state.settings.enemyLevel) ? "" : "warning" // prettier-ignore
                    }
                  >
                    (whole number)
                  </span>
                </label>
                <input
                  id="enemyLevel"
                  name="enemyLevel"
                  className="form-control"
                  value={this.state.settings.enemyLevel}
                  onChange={this.handleEnemyLevelChange}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="selectWeapon">Weapon</label>
                <select
                  id="selectWeapon"
                  className="form-control"
                  value={this.state.weapon.name}
                  onChange={this.handleWeaponChange}
                >
                  {this.state.weapons.length > 0
                    ? this.state.weapons.map((o, i) => {
                        return (
                          <option key={i} value={o.name}>
                            {o.stars}★ {o.display}
                          </option>
                        );
                      })
                    : null}
                </select>
                <p>
                  <small>{this.state.weaponStatsDisplay}</small>
                </p>
              </div>
              {/* <div className="form-group">
                <label htmlFor="weaponLevel">Weapon Level</label>
                <select
                  id="weaponLevel"
                  className="form-control"
                  value={this.state.weapon.level}
                  onChange={this.handleWeaponLevelChange}
                >
                  <option value="90">90</option>
                  <option value="80">80</option>
                  <option value="70">70</option>
                </select>
              </div> */}
              <div className="form-group">
                <label htmlFor="weaponRefine">Weapon Refinement</label>
                <select
                  id="weaponRefine"
                  className="form-control"
                  value={this.state.weapon.refine}
                  onChange={this.handleWeaponRefineChange}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="situationalBonus">Situational Bonus</label>
                <select
                  id="situationalBonus"
                  className="form-control"
                  value={this.state.weapon.situationalBonus}
                  onChange={this.handleSituationalBonusChange}
                >
                  {this.state.currentWeapon
                    ? this.state.currentWeapon.situationalBonuses.map(
                        (o, i) => {
                          return (
                            <option key={i} value={i}>
                              {o.display}
                            </option>
                          );
                        },
                      )
                    : null}
                </select>
                <p>
                  <small>
                    {this.state.situationalBonusDisplay.length > 0
                      ? this.state.situationalBonusDisplay
                      : 'No situation bonus applied'}
                  </small>
                </p>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="additionalStatsChecked"
                name="additionalStatsChecked"
                checked={this.state.additionalStatsChecked}
                onChange={this.handleInputChange}
              />
              <label className="form-check-label" htmlFor="additionalStats">
                Additional Stats (add artifact set bonuses and other bonuses
                here)
              </label>
            </div>
          </div>
          {this.state.additionalStatsChecked ? (
            <div className="form-group">
              <div className="form-row">
                <FormBox
                  inputVariable={this.state.ASattackPercent}
                  variableId="ASattackPercent"
                  display="Attack Percent"
                  validate={validateFloat}
                  wholeNumber={false}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASflatAttack}
                  variableId="ASflatAttack"
                  display="Flat Attack"
                  validate={validateWholeNumber}
                  wholeNumber={true}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASelementalMastery}
                  variableId="ASelementalMastery"
                  display="Elemental Mastery"
                  validate={validateWholeNumber}
                  wholeNumber={true}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.AScritRate}
                  variableId="AScritRate"
                  display="Crit Rate"
                  validate={validateFloat}
                  wholeNumber={false}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.AScritDmg}
                  variableId="AScritDmg"
                  display="Crit Damage"
                  validate={validateFloat}
                  wholeNumber={false}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASbonusDmg}
                  variableId="ASbonusDmg"
                  display="Bonus Damage"
                  validate={validateFloat}
                  wholeNumber={false}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASelementalDmg}
                  variableId="ASelementalDmg"
                  display="Elemental Damage"
                  validate={validateFloat}
                  wholeNumber={false}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASphysicalDmg}
                  variableId="ASphysicalDmg"
                  display="Physical Damage"
                  validate={validateFloat}
                  wholeNumber={false}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASenemyResist}
                  variableId="ASenemyResist"
                  display="Enemy Resistance"
                  validate={validateFloat}
                  wholeNumber={false}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASreactionBonus}
                  variableId="ASreactionBonus"
                  display="Reaction Bonus"
                  validate={validateFloat}
                  wholeNumber={false}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASdefensePercent}
                  variableId="ASdefensePercent"
                  display="Defense Percent"
                  validate={validateFloat}
                  wholeNumber={false}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASflatDefense}
                  variableId="ASflatDefense"
                  display="Flat Defense"
                  validate={validateWholeNumber}
                  wholeNumber={true}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASFlatDmg}
                  variableId="ASFlatDmg"
                  display="Flat Damage"
                  validate={validateWholeNumber}
                  wholeNumber={true}
                  handleInputChange={this.handleInputChange}
                />
                {this.state.character.hpScalingAttack ? (
                  <FormBox
                    inputVariable={this.state.AShpScalingAttack}
                    variableId="AShpScalingAttack"
                    display="HP Scaling Attack"
                    validate={validateFloat}
                    wholeNumber={false}
                    handleInputChange={this.handleInputChange}
                  />
                ) : null}
                <FormBox
                  inputVariable={this.state.AShpScalingFlatDamage}
                  variableId="AShpScalingFlatDamage"
                  display="HP Scaling Flat Damage"
                  validate={validateWholeNumber}
                  wholeNumber={true}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.AShpPercent}
                  variableId="AShpPercent"
                  display="HP Percent"
                  validate={validateFloat}
                  wholeNumber={false}
                  handleInputChange={this.handleInputChange}
                />
                {this.state.character.defenseScalingAttack ? (
                  <FormBox
                    inputVariable={this.state.ASdefenseScalingAttack}
                    variableId="ASdefenseScalingAttack"
                    display="Defense Scaling Attack"
                    validate={validateFloat}
                    wholeNumber={false}
                    handleInputChange={this.handleInputChange}
                  />
                ) : null}
                {this.state.character.defenseScalingFlatDamage ? (
                  <FormBox
                    inputVariable={this.state.ASdefenseScalingFlatDamage}
                    variableId="ASdefenseScalingFlatDamage"
                    display="Defense Scaling Flat Damage"
                    validate={validateWholeNumber}
                    wholeNumber={true}
                    handleInputChange={this.handleInputChange}
                  />
                ) : null}
                <FormBox
                  inputVariable={this.state.ASenergyRecharge}
                  variableId="ASenergyRecharge"
                  display="Energy Recharge"
                  validate={validateFloat}
                  wholeNumber={false}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASemScalingFlatDamage}
                  variableId="ASemScalingFlatDamage"
                  display="EM Scaling Flat Damage"
                  validate={validateFloat}
                  wholeNumber={false}
                  handleInputChange={this.handleInputChange}
                />
              </div>
            </div>
          ) : null}
          <div className="form-group">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="transformativeReactionsChecked"
                name="transformativeReactionsChecked"
                checked={this.state.transformativeReactionsChecked}
                onChange={this.handleInputChange}
              />
              <label
                className="form-check-label"
                htmlFor="transformativeReactions"
              >
                Transformative Reactions
              </label>
            </div>
          </div>
          {this.state.transformativeReactionsChecked ? (
            <div className="form-group">
              <div className="form-row">
                <FormBox
                  inputVariable={this.state.ASoverloaded}
                  variableId="ASoverloaded"
                  display="Overloaded count"
                  validate={validateWholeNumber}
                  wholeNumber={true}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASelectroCharged}
                  variableId="ASelectroCharged"
                  display="Electro Charged ticks"
                  validate={validateWholeNumber}
                  wholeNumber={true}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASswirl}
                  variableId="ASswirl"
                  display="Swirl count"
                  validate={validateWholeNumber}
                  wholeNumber={true}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASsuperconduct}
                  variableId="ASsuperconduct"
                  display="Superconduct count"
                  validate={validateWholeNumber}
                  wholeNumber={true}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASshatter}
                  variableId="ASshatter"
                  display="Shatter count"
                  validate={validateWholeNumber}
                  wholeNumber={true}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASspread}
                  variableId="ASspread"
                  display="Spread count"
                  validate={validateWholeNumber}
                  wholeNumber={true}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASaggrevate}
                  variableId="ASaggrevate"
                  display="Aggrevate count"
                  validate={validateWholeNumber}
                  wholeNumber={true}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASburning}
                  variableId="ASburning"
                  display="Burning count"
                  validate={validateWholeNumber}
                  wholeNumber={true}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASbloom}
                  variableId="ASbloom"
                  display="Bloom count"
                  validate={validateWholeNumber}
                  wholeNumber={true}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.ASburgeon}
                  variableId="ASburgeon"
                  display="Burgeon count"
                  validate={validateWholeNumber}
                  wholeNumber={true}
                  handleInputChange={this.handleInputChange}
                />
                <FormBox
                  inputVariable={this.state.AShyperbloom}
                  variableId="AShyperbloom"
                  display="Hyperbloom count"
                  validate={validateWholeNumber}
                  wholeNumber={true}
                  handleInputChange={this.handleInputChange}
                />
              </div>
            </div>
          ) : null}
          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <select
                  id="selectMaximize"
                  className="form-control"
                  value={this.state.maximize}
                  onChange={this.handleMaximizeChange}
                >
                  {maximizeOptions.length > 0 ? (
                    maximizeOptions.map((o, i) => {
                      let value = o.value;
                      return (
                        <option key={i} value={value}>
                          {o.display}
                        </option>
                      );
                    })
                  ) : (
                    <option value="Klee">Klee</option>
                  )}
                </select>
              </div>
            </div>
          </div>
          {/* <fieldset className="form-group">
            <div className="row">
              <legend className="col-form-label col-sm-2 pt-0">Maximize</legend>
              <div className="col-sm-10">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="gridRadios1"
                    value="average"
                    checked={this.state.settings.maximize === 'average'}
                    // disabled={this.checkIfRadioDisabled()}
                    onChange={this.onRadioChanged}
                  />
                  <label className="form-check-label" htmlFor="gridRadios1">
                    Elemental Damage
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="gridRadios1"
                    value="physical"
                    checked={this.state.settings.maximize === 'physical'}
                    // disabled={this.checkIfRadioDisabled()}
                    onChange={this.onRadioChanged}
                  />
                  <label className="form-check-label" htmlFor="gridRadios1">
                    Physical Damage
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="gridRadios1"
                    value="both"
                    disabled={this.checkIfRadioDisabled()}
                    checked={this.state.settings.maximize === 'both'}
                    onChange={this.onRadioChanged}
                  />
                  <label className="form-check-label" htmlFor="gridRadios1">
                    BOTH Elemental and Physical Damage (average, both weighted
                    at 50/50)
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="gridRadios1"
                    value="attackGoblet"
                    disabled={this.checkIfRadioDisabled()}
                    checked={this.state.settings.maximize === 'attackGoblet'}
                    onChange={this.onRadioChanged}
                  />
                  <label className="form-check-label" htmlFor="gridRadios1">
                    Forces Goblet to be Attack Percent and Maximizes Both
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="gridRadios2"
                    value="vaporize"
                    checked={this.state.settings.maximize === 'vaporize'}
                    disabled={this.checkIfRadioDisabled()}
                    onChange={this.onRadioChanged}
                  />
                  <label className="form-check-label" htmlFor="gridRadios2">
                    Vaporize/Melt (crit)
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="gridRadios2"
                    value="vaporizeAverage"
                    checked={this.state.settings.maximize === 'vaporizeAverage'}
                    disabled={this.checkIfRadioDisabled()}
                    onChange={this.onRadioChanged}
                  />
                  <label className="form-check-label" htmlFor="gridRadios2">
                    Vaporize/Melt Average Damage
                  </label>
                </div>
                {this.state.character.name == 'Albedo' ? (
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gridRadios"
                      id="gridRadios2"
                      value="defenseScaling"
                      checked={
                        this.state.settings.maximize === 'defenseScaling'
                      }
                      disabled={this.checkIfRadioDisabled()}
                      onChange={this.onRadioChanged}
                    />
                    <label className="form-check-label" htmlFor="gridRadios2">
                      Defense Scaling Elemental Damage
                    </label>
                  </div>
                ) : null}
              </div>
            </div>
          </fieldset> */}
        </form>
        <button
          className="button1 maximize_damage"
          onClick={() => {
            this.maximizeDamage();
          }}
          disabled={this.state.maximizeDisabled}
        >
          Maximize Damage!
        </button>{' '}
        {this.state.error ? (
          <span className="text-danger">ERROR: {this.state.error}!</span>
        ) : null}
        {this.state.damageSummary && this.state.stats ? (
          <div ref={(ref) => (this.damageDisplayRef = ref)}>
            <div className="row">
              <div className="col">
                <h3>
                  Maximized{' '}
                  {
                    maximizeDisplayMap[
                      this.state.currentDisplay.settings.maximize
                    ]
                  }{' '}
                  Damage
                </h3>
                <span>
                  Level {this.state.currentDisplay.character.level}{' '}
                  <b>{this.state.currentDisplay.character.name}</b> using level{' '}
                  {this.state.currentDisplay.weapon.level}{' '}
                  <b>{this.state.currentDisplay.weapon.display}</b> vs a level{' '}
                  {this.state.currentDisplay.settings.enemyLevel} monster with{' '}
                  {(
                    this.state.currentDisplay.settings.enemyResist * 100
                  ).toFixed(0)}
                  % resist and with a skill modifier of{' '}
                  {(this.state.currentDisplay.settings.skill * 100).toFixed(1)}%
                </span>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2">
              <div className="col mb-2">
                <div className="card border-primary noBG">
                  <div className="card-header myContainerBG">
                    <span>
                      <h3>Damage Summary</h3>
                    </span>
                  </div>
                  <ul className="list-group">
                    <li className="list-group-item myContainerBG">
                      Elemental Damage:{' '}
                      {this.state.damageSummary.damage.toFixed(0)}
                    </li>
                    <li className="list-group-item myContainerBG">
                      Elemental Crit Damage:{' '}
                      {this.state.damageSummary.damageCrit.toFixed(0)}
                    </li>
                    <li className="list-group-item myContainerBG">
                      <b>
                        Elemental Average Damage:{' '}
                        {this.state.damageSummary.averageDamage.toFixed(0)}
                      </b>
                    </li>

                    <li className="list-group-item myContainerBG text-muted">
                      Physical Damage:{' '}
                      {this.state.damageSummary.physicalDamage.toFixed(0)}
                    </li>
                    <li className="list-group-item myContainerBG text-muted">
                      Crit Physical Damage:{' '}
                      {this.state.damageSummary.physicalDamageCrit.toFixed(0)}
                    </li>
                    <li className="list-group-item myContainerBG">
                      <b className="text-muted">
                        Average Physical Damage:{' '}
                        {this.state.damageSummary.physicalAverageDamage.toFixed(
                          0,
                        )}
                      </b>
                    </li>
                    <li className="list-group-item myContainerBG">
                      <b>
                        BOTH Average Damage:{' '}
                        {this.state.damageSummary.bothAverage.toFixed(0)}
                      </b>
                    </li>
                    {this.state.currentDisplay.character.vision === 'pyro' ||
                    this.state.currentDisplay.character.vision === 'hydro' ||
                    this.state.currentDisplay.character.vision === 'cryo' ? (
                      <div>
                        <li className="list-group-item myContainerBG text-primary">
                          2x Vaporize/Melt Damage:{' '}
                          {this.state.damageSummary.hydroVaporize.toFixed(0)}
                        </li>
                        <li className="list-group-item myContainerBG text-danger">
                          2x Vaporize/Melt Crit Damage:{' '}
                          {this.state.damageSummary.hydroVaporizeCrit.toFixed(
                            0,
                          )}
                        </li>
                        <li className="list-group-item myContainerBG text-danger">
                          2x Vaporize/Melt Average Damage:{' '}
                          {this.state.damageSummary.hydroVaporizeAverage.toFixed(
                            0,
                          )}
                        </li>
                        <li className="list-group-item myContainerBG text-primary">
                          1.5x Vaporize/Melt Damage:{' '}
                          {this.state.damageSummary.pyroVaporize.toFixed(0)}
                        </li>
                        <li className="list-group-item myContainerBG text-danger">
                          1.5x Vaporize/Melt Crit Damage:{' '}
                          {this.state.damageSummary.pyroVaporizeCrit.toFixed(0)}
                        </li>
                        <li className="list-group-item myContainerBG text-danger">
                          1.5x Vaporize/Melt Average Damage:{' '}
                          {this.state.damageSummary.pyroVaporizeAverage.toFixed(
                            0,
                          )}
                        </li>
                      </div>
                    ) : null}
                  </ul>
                </div>
              </div>
              <div className="col mb-2">
                <div className="card border-secondary noBG">
                  <div className="card-header myContainerBG">
                    <span>
                      <h3>Stats with Artifacts</h3>
                    </span>
                  </div>
                  <ul className="list-group">
                    {/* {this.state.stats.hp > 0 ? (
                      <li className="list-group-item myContainerBG text-primary">
                        HP: {this.state.stats.hp.toFixed(0)}
                      </li>
                    ) : null} */}
                    <li className="list-group-item myContainerBG text-primary">
                      Total Attack: {this.state.stats.attack.toFixed(0)}
                    </li>
                    {/* {this.state.stats.defense ? (
                      <li className="list-group-item myContainerBG text-primary">
                        Total Defense: {this.state.stats.defense.toFixed(0)}
                      </li>
                    ) : null} */}
                    <li className="list-group-item myContainerBG text-warning">
                      Crit Rate: {(this.state.stats.critRate * 100).toFixed(1)}%
                    </li>
                    <li className="list-group-item myContainerBG text-danger">
                      Crit Damage: {(this.state.stats.critDmg * 100).toFixed(1)}
                      %
                    </li>
                    <li className="list-group-item myContainerBG text-success">
                      {capitalizeFirstLetter(
                        this.state.currentDisplay.character.vision,
                      )}{' '}
                      Damage: {(this.state.stats.elementalDmg * 100).toFixed(1)}
                      %
                    </li>
                    {/* <li className="list-group-item myContainerBG text-muted">
                      Physical Damage:{' '}
                      {(this.state.stats.physicalDmg * 100).toFixed(1)}%
                    </li> */}
                    <li className="list-group-item myContainerBG">
                      Bonus Damage:{' '}
                      {(this.state.stats.bonusDmg * 100).toFixed(1)}%
                    </li>
                    <li className="list-group-item myContainerBG">
                      Elemental Mastery:{' '}
                      {this.state.stats.elementalMastery.toFixed(0)}
                    </li>
                    <li className="list-group-item myContainerBG text-muted">
                      Energy Recharge:{' '}
                      {(this.state.stats.energyRecharge * 100).toFixed(1)}%
                    </li>
                    <li className="list-group-item myContainerBG text-muted">
                      Attack Percent:{' '}
                      {(this.state.stats.attackPercent * 100).toFixed(1)}%
                    </li>
                    {this.state.stats.hpPercent != null ? (
                      <li className="list-group-item myContainerBG text-muted">
                        HP Percent:{' '}
                        {(this.state.stats.hpPercent * 100).toFixed(1)}%
                      </li>
                    ) : null}
                    {this.state.stats.flatHp > 4780 ? (
                      <li className="list-group-item myContainerBG text-muted">
                        Flat HP: {this.state.stats.flatHp}
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.artifacts.length > 0 ? (
          <div>
            <h2>Artifacts (5 Stars, Level 20)</h2>
            <div className="row row-cols-1 row-cols-md-3">
              {this.state.artifacts.map((a, i) => {
                return <Artifact data={a} key={i} />;
              })}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Home;

function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  } else {
    return '';
  }
}

function convertStatTypeToText(type) {
  var vision = 'Elemental';
  if (typeof type === 'object') {
    if (capitalizeFirstLetter(type.vision).length > 0) {
      vision = capitalizeFirstLetter(type.vision);
    }
    type = type.type;
  }
  if (type === 'flatAttack') {
    return 'Flat Attack';
  } else if (type === 'characterBaseAttack') {
    return 'Base Attack';
  } else if (type === 'attackPercent') {
    return 'Attack Percent';
  } else if (type === 'flatHp') {
    return 'Flat HP';
  } else if (type === 'hpPercent') {
    return 'HP Percent';
  } else if (type === 'flatDefense') {
    return 'Flat Defense';
  } else if (type === 'defensePercent') {
    return 'Defense Percent';
  } else if (type === 'elementalDmg') {
    return vision + ' Damage';
  } else if (type === 'physicalDmg') {
    return 'Physical Damage';
  } else if (type === 'bonusDmg') {
    return 'Bonus Damage';
  } else if (type === 'critRate') {
    return 'Crit Rate';
  } else if (type === 'critDmg') {
    return 'Crit Damage';
  } else if (type === 'energyRecharge') {
    return 'Energy Recharge';
  } else if (type === 'weaponAttack') {
    return 'Weapon Attack';
  } else if (type === 'elementalMastery') {
    return 'Elemental Mastery';
  } else if (type === 'hpScalingAttack') {
    return 'HP Scaling Attack';
  } else if (type === 'characterBaseHP') {
    return 'Base HP';
  } else if (type === 'characterBaseDefense') {
    return 'Base Defense';
  } else if (type === 'characterBaseHp') {
    return 'Base HP';
  } else if (type === 'defenseScalingFlatDamage') {
    return 'Defense Scaling Flat Dmg';
  } else if (type === 'flatDefense') {
    return 'Flat Defense';
  } else if (type === 'emScalingFlatDamage') {
    return 'EM Scaling Flat Damage';
  } else if (type === 'emScalingAttack') {
    return 'EM Scaling Attack';
  } else {
    return type;
  }
}

function convertStatValueToText(stat) {
  if (stat.value) {
    if (
      stat.type === 'critRate' ||
      stat.type === 'critDmg' ||
      stat.type === 'energyRecharge' ||
      stat.type === 'elementalDmg' ||
      stat.type === 'physicalDmg' ||
      stat.type === 'defensePercent' ||
      stat.type === 'hpPercent' ||
      stat.type === 'bonusDmg' ||
      stat.type === 'hpScalingAttack' ||
      stat.type === 'defenseScalingFlatDamage' ||
      stat.type === 'emScalingFlatDamage' ||
      stat.type === 'emScalingAttack'
    ) {
      return (stat.value * 100).toFixed(1) + '%';
    } else if (stat.type === 'attackPercent') {
      return (stat.value * 100).toFixed(1) + '%';
    } else {
      return Math.round(stat.value);
    }
  }
}

function convertStatsToText(stat) {
  // convert to switch
  var str = '';
  str += convertStatTypeToText(stat.type);
  str += ' - ';
  str += convertStatValueToText(stat);
  return str;
}

function validateFloat(value) {
  return /^\s*[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)\s*$/.test(value);
}

function validateWholeNumber(value) {
  return /^\s*\+?\d+\s*$/.test(value);
}

var maximizeDisplayMap = {
  average: 'Average Elemental Damage',
  physical: 'Average Physical Damage',
  vaporize: 'Vaporize/Melt Crit',
};

function sanitizeObject(obj) {
  for (var key in obj) {
    obj[key] = parseFloat(obj[key]);
  }
}

var utils = {
  capitalizeFirstLetter,
  convertStatsToText,
  convertStatTypeToText,
  convertStatValueToText,
  validateFloat,
  validateWholeNumber,
  maximizeDisplayMap,
  sanitizeObject,
};

export default utils;

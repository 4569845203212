import React, { Component } from 'react';
import './styles.css';

class KeyboardEnter extends Component {
  onClick = () => {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Enter' }));
  };
  render() {
    return (
      <div
        onClick={this.onClick}
        className="wideKey"
        key={'Enter'}
        id={'Enter'}
      >
        ENTER
      </div>
    );
  }
}

export default KeyboardEnter;

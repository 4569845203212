import React, { Component } from "react";
import "./styles.css";
import utils from "../../utils/index";
var { capitalizeFirstLetter, convertStatsToText } = utils;

class Artifact extends Component {
  render() {
    var { data } = this.props;
    return (
      <div className="col mb-4">
        <div className="card h-100 noBG">
          {/* <img src="..." className="card-img-top" alt="..." /> */}
          <div className="card-header myContainerBG">
            <h3>{capitalizeFirstLetter(data.type)}</h3>
          </div>
          <div className="card-header myContainerBG">
            <b>
              Main:{" "}
              {convertStatsToText({
                type: data.main.stat,
                value: data.main.value,
              })}
            </b>
          </div>
          <ul className="list-group list-group-flush">
            {data.values.map((o, i) => {
              return (
                <li key={i} className="list-group-item myContainerBG">
                  ({o.level}) {convertStatsToText(o)}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default Artifact;

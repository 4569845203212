import React, { Component } from 'react';
import './styles.css';

class GuessedLetter extends Component {
  render() {
    var { letter, display } = this.props;
    return <div className={'guessedLetter ' + display}>{letter}</div>;
  }
}

export default GuessedLetter;

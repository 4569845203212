var characters = {
  Albedo: {
    name: 'Albedo',
    weapon: 'sword',
    vision: 'geo',
    levels: {
      90: {
        characterBaseAttack: 251,
        elementalDmg: 0.288,
        characterBaseHp: 13226,
        characterBaseDefense: 876,
      },
      80: {
        characterBaseAttack: 222,
        elementalDmg: 0.216,
        characterBaseHp: 11669,
        characterBaseDefense: 773,
      },
      70: {
        characterBaseAttack: 192,
        elementalDmg: 0.144,
        characterBaseHp: 10119,
        characterBaseDefense: 670,
      },
    },
  },
  Aloy: {
    name: 'Aloy',
    weapon: 'bow',
    vision: 'cryo',
    levels: {
      90: { characterBaseAttack: 234, elementalDmg: 0.288 },
      80: { characterBaseAttack: 206, elementalDmg: 0.216 },
      70: { characterBaseAttack: 179, elementalDmg: 0.144 },
    },
  },
  Amber: {
    name: 'Amber',
    weapon: 'bow',
    vision: 'pyro',
    levels: {
      90: { characterBaseAttack: 223, attackPercent: 0.24 },
      80: { characterBaseAttack: 198, attackPercent: 0.18 },
      70: { characterBaseAttack: 172, attackPercent: 0.12 },
    },
  },
  Ayato: {
    name: 'Ayato',
    weapon: 'sword',
    vision: 'hydro',
    hpScalingFlatDamage: true,
    levels: {
      90: {
        characterBaseAttack: 299,
        characterBaseHp: 13715,
        characterBaseDefense: 769,
        critDmg: 0.384,
      },
    },
  },
  Beidou: {
    name: 'Beidou',
    weapon: 'claymore',
    vision: 'electro',
    levels: {
      90: {
        characterBaseAttack: 225,
        characterBaseHp: 13050,
        characterBaseDefense: 648,
        elementalDmg: 0.24,
      },
      80: {
        characterBaseAttack: 200,
        elementalDmg: 0.18,
        characterBaseHp: 11565,
        characterBaseDefense: 575,
      },
      70: {
        characterBaseAttack: 174,
        elementalDmg: 0.12,
        characterBaseHp: 10081,
        characterBaseDefense: 501,
      },
    },
  },
  Bennett: {
    name: 'Bennett',
    weapon: 'sword',
    vision: 'pyro',
    levels: {
      90: {
        characterBaseAttack: 191,
        energyRecharge: 0.267,
        characterBaseHp: 12397,
        characterBaseDefense: 771,
      },
      80: {
        characterBaseAttack: 169,
        energyRecharge: 0.2,
        characterBaseHp: 10987,
        characterBaseDefense: 684,
      },
      70: {
        characterBaseAttack: 148,
        energyRecharge: 0.133,
        characterBaseHp: 9577,
        characterBaseDefense: 596,
      },
    },
  },
  // Candace: {
  //   name: 'Candace',
  //   weapon: 'polearm',
  //   vision: 'hydro',
  //   levels: {
  //     90: {
  //       characterBaseAttack: 212,
  //       hpPercent: 0.24,
  //       characterBaseDefense: 682,
  //       characterBaseHp: 10875,
  //     },
  //     80: { characterBaseAttack: 1, critDmg: 0.1 },
  //     70: { characterBaseAttack: 1, critDmg: 0.1 },
  //   },
  // },
  ChongYun: {
    name: 'ChongYun',
    weapon: 'claymore',
    vision: 'cryo',
    levels: {
      90: {
        characterBaseAttack: 223,
        attackPercent: 0.24,
        characterBaseDefense: 648,
        characterBaseHp: 10984,
      },
      80: { characterBaseAttack: 198, attackPercent: 0.18 },
      70: { characterBaseAttack: 172, attackPercent: 0.12 },
    },
  },
  // Cyno: {
  //   name: 'Cyno',
  //   weapon: 'polearm',
  //   vision: 'electro',
  //   levels: {
  //     90: {
  //       characterBaseAttack: 318,
  //       critDmg: 0.384,
  //       characterBaseDefense: 859,
  //       characterBaseHp: 12491,
  //     },
  //     80: { characterBaseAttack: 1, critDmg: 0.1 },
  //     70: { characterBaseAttack: 1, critDmg: 0.1 },
  //   },
  // },
  Diluc: {
    name: 'Diluc',
    weapon: 'claymore',
    vision: 'pyro',
    levels: {
      90: {
        characterBaseAttack: 335,
        critRate: 0.192,
        characterBaseDefense: 784,
        characterBaseHp: 12981,
      },
      80: {
        characterBaseAttack: 295,
        critRate: 0.154,
        characterBaseDefense: 692,
        characterBaseHp: 11453,
      },
      70: {
        characterBaseAttack: 256,
        critRate: 0.096,
        characterBaseDefense: 600,
        characterBaseHp: 9932,
      },
    },
  },
  Fischl: {
    name: 'Fischl',
    weapon: 'bow',
    vision: 'electro',
    levels: {
      90: { characterBaseAttack: 244, attackPercent: 0.24 },
      80: { characterBaseAttack: 216, attackPercent: 0.18 },
      70: { characterBaseAttack: 189, attackPercent: 0.12 },
    },
  },
  GanYu: {
    name: 'GanYu',
    weapon: 'bow',
    vision: 'cryo',
    levels: {
      90: { characterBaseAttack: 335, critDmg: 0.384 },
      80: { characterBaseAttack: 295, critDmg: 0.288 },
      70: { characterBaseAttack: 256, critDmg: 0.192 },
    },
  },
  Heizou: {
    name: 'Heizou',
    weapon: 'catalyst',
    vision: 'anemo',
    levels: {
      90: {
        characterBaseAttack: 225,
        characterBaseDefense: 684,
        characterBaseHp: 10657,
        elementalDmg: 0.24,
      },
    },
  },
  Itto: {
    name: 'Itto',
    weapon: 'claymore',
    vision: 'geo',
    defenseScalingAttack: true,
    defenseScalingFlatDamage: true,
    levels: {
      90: {
        characterBaseAttack: 227,
        critRate: 0.242,
        characterBaseDefense: 959,
        characterBaseHp: 12858,
      },
    },
  },
  Jean: {
    name: 'Jean',
    weapon: 'sword',
    vision: 'anemo',
    levels: {
      90: {
        characterBaseAttack: 239,
        healingPercent: 0.222,
        characterBaseHp: 14695,
        characterBaseDefense: 769,
      },
      80: {
        characterBaseAttack: 211,
        healingPercent: 0.166,
        characterBaseHp: 12965,
        characterBaseDefense: 678,
      },
      70: {
        characterBaseAttack: 183,
        healingPercent: 0.111,
        characterBaseHp: 11243,
        characterBaseDefense: 588,
      },
    },
  },
  Kaeya: {
    name: 'Kaeya',
    weapon: 'sword',
    vision: 'cryo',
    levels: {
      90: {
        characterBaseAttack: 223,
        energyRecharge: 0.267,
        characterBaseHp: 11636,
        characterBaseDefense: 792,
      },
      80: {
        characterBaseAttack: 198,
        energyRecharge: 0.2,
        characterBaseHp: 10312,
        characterBaseDefense: 702,
      },
      70: {
        characterBaseAttack: 172,
        energyRecharge: 0.133,
        characterBaseHp: 8989,
        characterBaseDefense: 612,
      },
    },
  },
  KeQing: {
    name: 'KeQing',
    weapon: 'sword',
    vision: 'electro',
    levels: {
      90: {
        characterBaseAttack: 323,
        critDmg: 0.384,
        characterBaseHp: 13103,
        characterBaseDefense: 799,
      },
      80: {
        characterBaseAttack: 285,
        critDmg: 0.288,
        characterBaseHp: 11561,
        characterBaseDefense: 705,
      },
      70: {
        characterBaseAttack: 247,
        critDmg: 0.192,
        characterBaseHp: 10025,
        characterBaseDefense: 612,
      },
    },
  },
  Klee: {
    name: 'Klee',
    weapon: 'catalyst',
    vision: 'pyro',
    levels: {
      90: { characterBaseAttack: 311, elementalDmg: 0.288 },
      80: { characterBaseAttack: 274, elementalDmg: 0.216 },
      70: { characterBaseAttack: 238, elementalDmg: 0.144 },
    },
  },
  Lisa: {
    name: 'Lisa',
    weapon: 'catalyst',
    vision: 'electro',
    levels: {
      90: { characterBaseAttack: 232, elementalMastery: 48 },
      80: { characterBaseAttack: 205, elementalMastery: 72 },
      70: { characterBaseAttack: 179, elementalMastery: 96 },
    },
  },
  Mona: {
    name: 'Mona',
    weapon: 'catalyst',
    vision: 'hydro',
    levels: {
      90: {
        characterBaseAttack: 287,
        energyRecharge: 0.32,
        elementalDmg: 0.33,
      },
      80: {
        characterBaseAttack: 253,
        energyRecharge: 0.24,
        elementalDmg: 0.31,
      },
      70: { characterBaseAttack: 220, energyRecharge: 0.116 },
    },
  },
  Nilou: {
    name: 'Nilou',
    weapon: 'sword',
    vision: 'hydro',
    levels: {
      90: {
        characterBaseAttack: 229,
        hpPercent: 0.288,
        characterBaseDefense: 728,
        characterBaseHp: 15185,
      },
      80: { characterBaseAttack: 1, critDmg: 0.1 },
      70: { characterBaseAttack: 1, critDmg: 0.1 },
    },
  },
  NingGuang: {
    name: 'NingGuang',
    weapon: 'catalyst',
    vision: 'geo',
    levels: {
      90: { characterBaseAttack: 212, elementalDmg: 0.24 },
      80: { characterBaseAttack: 188, elementalDmg: 0.18 },
      70: { characterBaseAttack: 164, elementalDmg: 0.12 },
    },
  },
  Noelle: {
    name: 'Noelle',
    weapon: 'claymore',
    vision: 'geo',
    defenseScalingAttack: true,
    levels: {
      90: {
        characterBaseAttack: 191,
        characterBaseHp: 12071,
        characterBaseDefense: 799,
        defensePercent: 0.3,
      },
      80: {
        characterBaseAttack: 169,
        characterBaseHp: 10698,
        characterBaseDefense: 708,
        defensePercent: 0.225,
      },
      70: {
        characterBaseAttack: 148,
        characterBaseHp: 9325,
        characterBaseDefense: 617,
        defensePercent: 0.15,
      },
    },
  },
  QiQi: {
    name: 'QiQi',
    weapon: 'sword',
    vision: 'cryo',
    levels: {
      90: {
        characterBaseAttack: 287,
        healingBonus: 0.222,
        characterBaseHp: 12368,
        characterBaseDefense: 922,
      },
      80: {
        characterBaseAttack: 253,
        healingBonus: 0.166,
        characterBaseHp: 10912,
        characterBaseDefense: 814,
      },
      70: {
        characterBaseAttack: 220,
        healingBonus: 0.111,
        characterBaseHp: 9463,
        characterBaseDefense: 706,
      },
    },
  },
  Razor: {
    name: 'Razor',
    weapon: 'claymore',
    vision: 'electro',
    levels: {
      90: { characterBaseAttack: 234, physicalDmg: 0.3 },
      80: { characterBaseAttack: 207, physicalDmg: 0.225 },
      70: { characterBaseAttack: 180, physicalDmg: 0.15 },
    },
  },
  Sucrose: {
    name: 'Sucrose',
    weapon: 'catalyst',
    vision: 'anemo',
    levels: {
      90: { characterBaseAttack: 170, elementalDmg: 0.24 },
      80: { characterBaseAttack: 151, elementalDmg: 0.18 },
      70: { characterBaseAttack: 131, elementalDmg: 0.12 },
    },
  },
  Traveler: {
    name: 'Traveler',
    weapon: 'sword',
    vision: 'anemo',
    levels: {
      90: {
        characterBaseAttack: 212,
        attackPercent: 0.24,
        characterBaseHp: 10875,
        characterBaseDefense: 683,
      },
      80: {
        characterBaseAttack: 188,
        attackPercent: 0.18,
        characterBaseHp: 9638,
        characterBaseDefense: 605,
      },
      70: {
        characterBaseAttack: 164,
        attackPercent: 0.12,
        characterBaseHp: 8401,
        characterBaseDefense: 527,
      },
    },
  },
  DendroTraveler: {
    name: 'DendroTraveler',
    weapon: 'sword',
    vision: 'dendro',
    levels: {
      90: {
        characterBaseAttack: 212,
        attackPercent: 0.24,
        characterBaseHp: 10875,
        characterBaseDefense: 683,
      },
      80: {
        characterBaseAttack: 188,
        attackPercent: 0.18,
        characterBaseHp: 9638,
        characterBaseDefense: 605,
      },
      70: {
        characterBaseAttack: 164,
        attackPercent: 0.12,
        characterBaseHp: 8401,
        characterBaseDefense: 527,
      },
    },
  },
  Venti: {
    name: 'Venti',
    weapon: 'bow',
    vision: 'anemo',
    levels: {
      90: { characterBaseAttack: 263, energyRecharge: 0.32 },
      80: { characterBaseAttack: 232, energyRecharge: 0.24 },
      70: { characterBaseAttack: 214, energyRecharge: 0.16 },
    },
  },
  XiangLing: {
    name: 'XiangLing',
    weapon: 'polearm',
    vision: 'pyro',
    levels: {
      90: {
        characterBaseAttack: 225,
        elementalMastery: 96,
        characterBaseHp: 10875,
      },
      80: {
        characterBaseAttack: 200,
        elementalMastery: 72,
        characterBaseHp: 9638,
      },
      70: {
        characterBaseAttack: 174,
        elementalMastery: 48,
        characterBaseHp: 8401,
      },
    },
  },
  XingQiu: {
    name: 'XingQiu',
    weapon: 'sword',
    vision: 'hydro',
    levels: {
      90: {
        characterBaseAttack: 202,
        attackPercent: 0.24,
        characterBaseHp: 10222,
        characterBaseDefense: 758,
      },
      80: {
        characterBaseAttack: 179,
        attackPercent: 0.18,
        characterBaseHp: 9060,
        characterBaseDefense: 671,
      },
      70: {
        characterBaseAttack: 156,
        attackPercent: 0.12,
        characterBaseHp: 7897,
        characterBaseDefense: 585,
      },
    },
  },
  Tartaglia: {
    name: 'Tartaglia',
    weapon: 'bow',
    vision: 'hydro',
    levels: {
      90: { characterBaseAttack: 301, elementalDmg: 0.288 },
      80: { characterBaseAttack: 266, elementalDmg: 0.216 },
      70: { characterBaseAttack: 231, elementalDmg: 0.144 },
    },
  },
  Diona: {
    name: 'Diona',
    weapon: 'bow',
    vision: 'cryo',
    levels: {
      90: { characterBaseAttack: 212, elementalDmg: 0.24 },
      80: { characterBaseAttack: 188, elementalDmg: 0.18 },
      70: { characterBaseAttack: 164, elementalDmg: 0.12 },
    },
  },
  XinYan: {
    name: 'XinYan',
    weapon: 'claymore',
    vision: 'pyro',
    levels: {
      90: {
        characterBaseAttack: 249,
        attackPercent: 0.24,
        characterBaseHp: 11201,
        characterBaseDefense: 799,
      },
      80: { characterBaseAttack: 220, attackPercent: 0.18 },
      70: { characterBaseAttack: 192, attackPercent: 0.12 },
    },
  },
  YanFei: {
    name: 'YanFei',
    weapon: 'catalyst',
    vision: 'pyro',
    levels: {
      90: {
        characterBaseAttack: 240,
        elementalDmg: 0.24,
        characterBaseHp: 9352,
        characterBaseDefense: 587,
      },
      80: {
        characterBaseAttack: 213,
        elementalDmg: 0.18,
        characterBaseHp: 8289,
        characterBaseDefense: 520,
      },
      70: {
        characterBaseAttack: 185,
        elementalDmg: 0.12,
        characterBaseHp: 7225,
        characterBaseDefense: 480,
      },
    },
  },
  ZhongLi: {
    name: 'ZhongLi',
    weapon: 'polearm',
    vision: 'geo',
    hpScalingFlatDamage: true,
    levels: {
      90: {
        characterBaseAttack: 251,
        elementalDmg: 0.288,
        characterBaseHp: 14695,
      },
      80: {
        characterBaseAttack: 222,
        elementalDmg: 0.216,
        characterBaseHp: 12965,
      },
      70: {
        characterBaseAttack: 192,
        elementalDmg: 0.144,
        characterBaseHp: 11243,
      },
    },
  },
  ZhongLiEOSF: {
    name: 'ZhongLiEOSF',
    weapon: 'polearm',
    vision: 'geo',
    hpScalingFlatDamage: true,
    levels: {
      90: {
        characterBaseAttack: 251,
        elementalDmg: 0.288,
        characterBaseHp: 14695,
      },
      80: {
        characterBaseAttack: 222,
        elementalDmg: 0.216,
        characterBaseHp: 12965,
      },
      70: {
        characterBaseAttack: 192,
        elementalDmg: 0.144,
        characterBaseHp: 11243,
      },
    },
  },
  HuTao: {
    name: 'HuTao',
    weapon: 'polearm',
    vision: 'pyro',
    hpScalingAttack: true,
    hpScalingFlatDamage: true,
    levels: {
      90: {
        characterBaseAttack: 106,
        critDmg: 0.384,
        characterBaseHp: 15552,
      },
      80: {
        characterBaseAttack: 94,
        critDmg: 0.288,
        characterBaseHp: 13721,
      },
      70: {
        characterBaseAttack: 81,
        critDmg: 0.192,
        characterBaseHp: 11899,
      },
    },
  },
  Xiao: {
    name: 'Xiao',
    weapon: 'polearm',
    vision: 'anemo',
    levels: {
      90: {
        characterBaseAttack: 349,
        critRate: 0.192,
        characterBaseHp: 12736,
        characterBaseDefense: 799,
      },
      80: {
        characterBaseAttack: 308,
        critRate: 0.144,
        characterBaseHp: 11236,
        characterBaseDefense: 705,
      },
      70: {
        characterBaseAttack: 267,
        critRate: 0.096,
        characterBaseHp: 9744,
        characterBaseDefense: 612,
      },
    },
  },
  Rosaria: {
    name: 'Rosaria',
    weapon: 'polearm',
    vision: 'cryo',
    levels: {
      90: {
        characterBaseAttack: 240,
        attackPercent: 0.24,
        characterBaseHp: 12289,
        characterBaseDefense: 710,
      },
      80: {
        characterBaseAttack: 213,
        attackPercent: 0.18,
        characterBaseHp: 10891,
        characterBaseDefense: 629,
      },
      70: {
        characterBaseAttack: 267,
        attackPercent: 0.11,
        characterBaseHp: 9493,
        characterBaseDefense: 548,
      },
    },
  },
  Eula: {
    name: 'Eula',
    weapon: 'claymore',
    vision: 'cryo',
    levels: {
      90: {
        characterBaseAttack: 342,
        critDmg: 0.384,
        characterBaseHp: 13226,
        characterBaseDefense: 751,
      },
      80: {
        characterBaseAttack: 302,
        critDmg: 0.288,
        characterBaseHp: 11669,
        characterBaseDefense: 662,
      },
      70: {
        characterBaseAttack: 262,
        critDmg: 0.192,
        characterBaseHp: 10119,
        characterBaseDefense: 574,
      },
    },
  },
  Kazuha: {
    name: 'Kazuha',
    weapon: 'sword',
    vision: 'anemo',
    levels: {
      90: {
        characterBaseAttack: 297,
        elementalMastery: 115,
        characterBaseHp: 13348,
        characterBaseDefense: 807,
      },
      80: {
        characterBaseAttack: 262,
        elementalMastery: 86,
        characterBaseHp: 11777,
        characterBaseDefense: 712,
      },
      70: {
        characterBaseAttack: 227,
        elementalMastery: 57,
        characterBaseHp: 10213,
        characterBaseDefense: 617,
      },
    },
  },
  Ayaka: {
    name: 'Ayaka',
    weapon: 'sword',
    vision: 'cryo',
    levels: {
      90: {
        characterBaseAttack: 342,
        critDmg: 0.384,
        characterBaseHp: 12858,
        characterBaseDefense: 807,
      },
      80: {
        characterBaseAttack: 302,
        critDmg: 0.288,
        characterBaseHp: 11345,
        characterBaseDefense: 712,
      },
      70: {
        characterBaseAttack: 262,
        critDmg: 0.192,
        characterBaseHp: 9838,
        characterBaseDefense: 617,
      },
    },
  },
  Yoimiya: {
    name: 'Yoimiya',
    weapon: 'bow',
    vision: 'pyro',
    levels: {
      90: {
        characterBaseAttack: 323,
        critRate: 0.242,
        characterBaseHp: 10164,
        characterBaseDefense: 615,
      },
      80: {
        characterBaseAttack: 285,
        critRate: 0.194,
        characterBaseHp: 8968,
        characterBaseDefense: 542,
      },
      70: {
        characterBaseAttack: 247,
        critRate: 0.146,
        characterBaseHp: 7777,
        characterBaseDefense: 470,
      },
    },
  },
  Tighnari: {
    name: 'Tighnari',
    weapon: 'bow',
    vision: 'dendro',
    levels: {
      90: {
        characterBaseAttack: 267,
        characterBaseHp: 10850,
        characterBaseDefense: 630,
        elementalDmg: 0.288,
      },
    },
  },
  Collei: {
    name: 'Collei',
    weapon: 'bow',
    vision: 'dendro',
    levels: {
      90: {
        characterBaseAttack: 199,
        characterBaseHp: 9787,
        characterBaseDefense: 600,
        attackPercent: 0.24,
      },
    },
  },
  Dori: {
    name: 'Dori',
    weapon: 'claymore',
    vision: 'electro',
    levels: {
      90: {
        characterBaseAttack: 223,
        characterBaseHp: 12397,
        characterBaseDefense: 723,
        hpPercent: 0.24,
      },
    },
  },
  Sayu: {
    name: 'Sayu',
    weapon: 'claymore',
    vision: 'anemo',
    levels: {
      90: {
        characterBaseAttack: 244,
        elementalMastery: 96,
        characterBaseHp: 11854,
        characterBaseDefense: 745,
      },
      80: {
        characterBaseAttack: 216,
        elementalMastery: 72,
        characterBaseHp: 10505,
        characterBaseDefense: 660,
      },
      70: {
        characterBaseAttack: 189,
        elementalMastery: 48,
        characterBaseHp: 9157,
        characterBaseDefense: 575,
      },
    },
  },
  Raiden: {
    name: 'Raiden',
    display: 'Raiden (EoSF)',
    weapon: 'polearm',
    vision: 'electro',
    levels: {
      90: {
        characterBaseAttack: 337,
        characterBaseHp: 12907,
        characterBaseDefense: 789,
        energyRecharge: 0.32,
      },
      80: {
        characterBaseAttack: 298,
        characterBaseHp: 11388,
        characterBaseDefense: 696,
        energyRecharge: 0.24,
      },
      70: {
        characterBaseAttack: 258,
        characterBaseHp: 9875,
        characterBaseDefense: 604,
        energyRecharge: 0.16,
      },
    },
  },
  Shenhe: {
    name: 'Shenhe',
    weapon: 'polearm',
    vision: 'Cryo',
    levels: {
      90: {
        characterBaseAttack: 304,
        characterBaseHp: 12993,
        characterBaseDefense: 830,
        attackPercent: 0.288,
      },
      80: {
        characterBaseAttack: 268,
        characterBaseHp: 11463,
        characterBaseDefense: 732,
        attackPercent: 0.216,
      },
      70: {
        characterBaseAttack: 232,
        characterBaseHp: 9941,
        characterBaseDefense: 635,
        attackPercent: 0.144,
      },
    },
  },
  Yae: {
    name: 'Yae',
    weapon: 'catalyst',
    vision: 'Electro',
    levels: {
      90: {
        characterBaseAttack: 340,
        characterBaseHp: 10372,
        characterBaseDefense: 569,
        critRate: 0.242,
      },
      80: {
        characterBaseAttack: 300,
        characterBaseHp: 9151,
        characterBaseDefense: 529,
        critRate: 0.194,
      },
      70: {
        characterBaseAttack: 260,
        characterBaseHp: 7936,
        characterBaseDefense: 435,
        critRate: 0.146,
      },
    },
  },
  Yelan: {
    name: 'Yelan',
    // display: 'Yelan (pure HP scaling)',
    weapon: 'bow',
    vision: 'hydro',
    levels: {
      90: {
        characterBaseAttack: 244,
        characterBaseHp: 14450,
        characterBaseDefense: 548,
        critRate: 0.242,
      },
    },
  },
};

function makeCharactersArray(characters) {
  var arr = [];
  for (var key in characters) {
    arr.push(characters[key]);
  }
  var sortedCharacters = arr.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
  return sortedCharacters;
}

var exportThis = {
  characters: characters,
  charactersArray: makeCharactersArray(characters),
};

export default exportThis;

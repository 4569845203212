import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './components/Navbar';
import DamageMaximizer from './pages/DamageMaximizer/';
import DamageCalculator from './pages/DamageCalculator/';
import Kleedle from './pages/Kleedle/';
import WIP from './pages/WIP/';
import bgVideo from './assets/bg/mona-idle-no-logo.mp4';
import './App.css';
class App extends Component {
  componentDidMount() {
    var video = document.getElementById('myVideo');
    video.play();
  }

  render() {
    return (
      <Router>
        <div className="blackBG hideScrollBars">
          <div className="videoContainer">
            <video autoPlay muted loop id="myVideo">
              <source src={bgVideo} type="video/mp4" />
            </video>
          </div>
          <Navbar />
          <Switch>
            <Route exact path="/" component={DamageMaximizer} />
            <Route exact path="/DamageMaximizer" component={DamageMaximizer} />
            <Route exact path="/Calculator" component={DamageCalculator} />
            <Route exact path="/Kleedle" component={Kleedle} />
            <Route exact path="/WIP" component={WIP} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;

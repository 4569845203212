import React, { Component } from 'react';
import './styles.css';
import './animate.css';
import Keyboard from '../../components/Kleedle/keyboard';
import GuessedLetter from '../../components/Kleedle/guessedLetter';
import WordRow from '../../components/Kleedle/wordRow';
import kleedleUtils from '../../utils/kleedle';
import words from '../../data/words';
var { keyboardKeys, checkIfWord, checkLetters, updateKeyboard } = kleedleUtils;
var {
  wordLengthMap,
  fiveLetterWordsMerged,
  fiveLetterGenshinWords,
  fiveLetterWords,
} = words;
var guessSample = [
  { letter: 'O', display: 'contains' },
  { letter: 'P', display: 'contains' },
  { letter: 'O', display: 'correct' },
  { letter: 'P', display: 'correct' },
  { letter: 'O', display: 'incorrect' },
];

class Kleedle extends Component {
  state = {
    keyboardKeys: keyboardKeys,
    gameMode: 'GENSHIN', // REGULAR, GENSHIN
    word: 'HYDRO',
    guesses: [''],
    currentRow: 0,
    currentInput: '',
    rows: [],
    gameState: 'active', // win, active, loss
    lives: 6,
    message: 'Welcome to Kleedle!',
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeypress, false);
    this.startGame();
  }

  startGame = () => {
    var { lives, gameMode } = this.state;
    var rows = [];
    var row = [];
    var word = 'POOPY';
    if (gameMode === 'GENSHIN') {
      var genshinWordIndex = Math.floor(
        Math.random() * Object.keys(fiveLetterGenshinWords).length,
      );
      word = Object.keys(fiveLetterGenshinWords)[
        genshinWordIndex
      ].toUpperCase();
      console.log('word', word);
    }
    if (gameMode === 'REGULAR') {
      var wordIndex = Math.floor(Math.random() * 1500);
      word = Object.keys(fiveLetterWords)[wordIndex].toUpperCase();
      console.log('word', word);
    }
    for (var j = 0; j < word.length; j++) {
      row.push({ letter: '', display: 'empty' });
    }
    for (var i = 0; i < lives; i++) {
      rows.push(row);
    }
    this.setState({ rows, word });
  };

  handleKeypress = (event) => {
    let key = event.key;
    console.log('key', key);
    var { word, currentInput, currentRow, rows, gameState } = this.state;
    var inputRow = [];
    var shouldUpdateRow = false;
    if (gameState === 'victory' || gameState === 'loss') {
      return;
    }
    if (key === 'Escape') {
      console.log('Escape pressed');
    }
    if (key === 'Backspace') {
      if (currentInput.length > 0) {
        currentInput = currentInput.slice(0, -1);
        shouldUpdateRow = true;
      }
    }
    if (key === 'Enter') {
      if (currentInput.length === word.length) {
        this.submitGuess();
      } else {
        this.setState({ message: 'Not Enough Characters' });
      }
    }
    // if any letter
    if (/^[a-zA-Z]+$/.test(key)) {
      if (key.length === 1) {
        // check word length vs input
        if (currentInput.length < word.length) {
          currentInput += key.toUpperCase();
          shouldUpdateRow = true;
        }
      }
    }
    if (shouldUpdateRow) {
      inputRow = this.createInputRow(currentInput);
      rows[currentRow] = inputRow;
      this.setState({ currentInput, rows });
    }
  };

  createInputRow = (currentInput) => {
    var wordLength = this.state.word.length;
    var row = [];
    for (var i = 0; i < wordLength; i++) {
      var tempObj = { letter: '', display: 'empty' };
      if (currentInput.charAt(i)) {
        tempObj.letter = currentInput.charAt(i);
        tempObj.display = 'guessInput';
      }
      row.push(tempObj);
    }
    return row;
  };

  submitGuess = () => {
    var {
      word,
      guesses,
      currentRow,
      currentInput,
      rows,
      gameState,
      lives,
      keyboardKeys,
    } = this.state;
    var isWord = wordLengthMap[word.length][currentInput.toLowerCase()];
    if (!isWord) {
      this.setState({ message: currentInput + " is not in Kleedle's List" });
    } else {
      var newRow = checkLetters(currentInput, word);
      guesses.push(currentInput);
      rows[currentRow] = newRow;
      keyboardKeys = updateKeyboard(rows);
      currentRow++;
      var message = '';
      if (currentInput.toLowerCase() === word.toLowerCase()) {
        message = 'Congratulations! U R Winner!';
        gameState = 'victory';
      } else if (currentRow === lives) {
        message = 'RIP';
        gameState = 'loss';
      } else {
        message = 'Incredible guess! Try again :D';
      }
      currentInput = '';
      this.setState({
        guesses,
        currentInput,
        rows,
        currentRow,
        keyboardKeys,
        message,
        gameState,
      });
    }
  };

  render() {
    var { rows, keyboardKeys, message } = this.state;
    return (
      <div>
        <div className="messageBox">
          <div className="message animated bounceIn">{message}</div>
        </div>
        <div className="container kleedleContainer">
          <div className="guessesContainer">
            {rows.map((row, i) => {
              return <WordRow guess={row} key={i} />;
            })}
          </div>
          <Keyboard keyboardKeys={keyboardKeys} />
        </div>
      </div>
    );
  }
}

export default Kleedle;

// import Axios from "axios";
import React, { Component } from 'react';
import utils from '../../utils/index';
import Maximizer from '../../api/maximizeDamage';
var {
  capitalizeFirstLetter,
  convertStatTypeToText,
  convertStatValueToText,
  validateFloat,
  validateWholeNumber,
  maximizeDisplayMap,
  sanitizeObject,
} = utils;
var { calculateDamage } = Maximizer;

class Calculator extends Component {
  state = {
    calculateDisabled: false,
    currentDisplay: {
      stats: {},
    },
    level: 90,
    enemyLevel: 91,
    enemyResist: 0.1,
    skill: 1,
    attack: 1000,
    elementalDmg: 0,
    physicalDmg: 0,
    bonusDmg: 0,
    elementalMastery: 0,
    critRate: 0.05,
    critDmg: 0.5,
    reactionBonus: 0,
    flatDmg: 0,
    defenseDown: 0,
  };
  componentDidMount() {
    if (typeof Storage !== 'undefined') {
      if (localStorage.getItem('state')) {
        try {
          var state = JSON.parse(localStorage.getItem('state'));
          this.setState(state);
        } catch (error) {}
      } else {
      }
    } else {
    }
  }
  checkCalculateDisabled() {
    var {
      skill,
      level,
      enemyLevel,
      enemyResist,
      attack,
      elementalDmg,
      physicalDmg,
      bonusDmg,
      elementalMastery,
      critRate,
      critDmg,
      reactionBonus,
      flatDmg,
      defenseDown,
    } = this.state;
    var floats = [
      skill,
      enemyResist,
      elementalDmg,
      physicalDmg,
      bonusDmg,
      critRate,
      critDmg,
      reactionBonus,
      defenseDown,
    ];
    var wholes = [level, enemyLevel, attack, elementalMastery, flatDmg];
    var disabled = false;
    for (var i = 0; i < floats.length; i++) {
      if (!validateFloat(floats[i])) {
        disabled = true;
      }
    }
    for (var i = 0; i < wholes.length; i++) {
      if (!validateWholeNumber(wholes[i])) {
        disabled = true;
      }
    }
    this.setState({ calculateDisabled: disabled });
  }

  handleInputChange = (event) => {
    const target = event.target;
    var value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value }, () => {
      this.checkCalculateDisabled();
    });
  };
  calculateDamage = () => {
    var {
      level,
      enemyLevel,
      enemyResist,
      skill,
      attack,
      elementalDmg,
      physicalDmg,
      bonusDmg,
      critRate,
      critDmg,
      elementalMastery,
      reactionBonus,
      flatDmg,
      defenseDown,
    } = this.state;
    var character = {
      level,
    };
    var settings = {
      enemyLevel,
      enemyResist,
      skill,
      defenseDown,
    };
    var stats = {
      attack,
      elementalDmg,
      physicalDmg,
      bonusDmg,
      critRate,
      critDmg,
      elementalMastery,
      reactionBonus,
      flatDmg,
    };
    sanitizeObject(character);
    sanitizeObject(settings);
    sanitizeObject(stats);
    var payload = {
      character,
      settings,
    };
    try {
      var damageSummary = calculateDamage(stats, payload);
      this.setState({ damageSummary }, () => {
        // this.damageDisplayRef.scrollIntoView();
        localStorage.setItem('state', JSON.stringify(this.state));
      });
    } catch (error) {
      this.setState({
        error: error.message + ' Check your form inputs!',
      });
    }
  };
  renderDamage = () => {};

  render() {
    return (
      <div className="container myContainerBG myContainerBG">
        <h3>Damage Calculator</h3>
        <form>
          <div className="form-row">
            <div className="col-6">
              <label htmlFor="level">
                Character Level{' '}
                <span
                  className={
                    validateWholeNumber(this.state.level) ? "" : "warning" // prettier-ignore
                  }
                >
                  (whole number)
                </span>
              </label>
              <input
                id="level"
                name="level"
                className="form-control bottomMargin"
                value={this.state.level}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6">
              <label htmlFor="enemyLevel">
                Enemy Level{' '}
                <span
                  className={
                    validateWholeNumber(this.state.enemyLevel) ? "" : "warning" // prettier-ignore
                  }
                >
                  (whole number)
                </span>
              </label>
              <input
                id="enemyLevel"
                name="enemyLevel"
                className="form-control bottomMargin"
                value={this.state.enemyLevel}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6">
              <label htmlFor="skill">
                Skill Multiplier{' '}
                <span
                  className={
                    validateFloat(this.state.skill) ? "" : "warning" // prettier-ignore
                  }
                >
                  (% as decimal)
                </span>
              </label>
              <input
                id="skill"
                name="skill"
                className="form-control bottomMargin"
                value={this.state.skill}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6">
              <label htmlFor="enemyResist">
                Enemy Resist{' '}
                <span
                  className={
                    validateFloat(this.state.enemyResist) ? "" : "warning" // prettier-ignore
                  }
                >
                  (% as decimal)
                </span>
              </label>
              <input
                id="enemyResist"
                name="enemyResist"
                className="form-control bottomMargin"
                value={this.state.enemyResist}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6">
              <label htmlFor="attack">
                Attack{' '}
                <span
                  className={
                    validateWholeNumber(this.state.attack) ? "" : "warning" // prettier-ignore
                  }
                >
                  (whole number)
                </span>
              </label>
              <input
                id="attack"
                name="attack"
                className="form-control bottomMargin"
                value={this.state.attack}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6">
              <label htmlFor="elementalDmg">
                Elemental Damage{' '}
                <span
                  className={
                    validateFloat(this.state.elementalDmg) ? "" : "warning" // prettier-ignore
                  }
                >
                  (% as decimal)
                </span>
              </label>
              <input
                id="elementalDmg"
                name="elementalDmg"
                className="form-control bottomMargin"
                value={this.state.elementalDmg}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6">
              <label htmlFor="physicalDmg">
                Physical Damage{' '}
                <span
                  className={
                    validateFloat(this.state.physicalDmg) ? "" : "warning" // prettier-ignore
                  }
                >
                  {/* (whole number) */}
                  (% as decimal)
                </span>
              </label>
              <input
                id="physicalDmg"
                name="physicalDmg"
                className="form-control bottomMargin"
                value={this.state.physicalDmg}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6">
              <label htmlFor="bonusDmg">
                Damage Bonuses{' '}
                <span
                  className={
                    validateFloat(this.state.bonusDmg) ? "" : "warning" // prettier-ignore
                  }
                >
                  {/* (whole number) */}
                  (% as decimal)
                </span>
              </label>
              <input
                id="bonusDmg"
                name="bonusDmg"
                className="form-control bottomMargin"
                value={this.state.bonusDmg}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6">
              <label htmlFor="elementalMastery">
                Elemental Mastery{' '}
                <span
                  className={
                    validateWholeNumber(this.state.elementalMastery) ? "" : "warning" // prettier-ignore
                  }
                >
                  (whole number)
                  {/* (% as decimal) */}
                </span>
              </label>
              <input
                id="elementalMastery"
                name="elementalMastery"
                className="form-control bottomMargin"
                value={this.state.elementalMastery}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6">
              <label htmlFor="critRate">
                Crit Rate{' '}
                <span
                  className={
                    validateFloat(this.state.critRate) ? "" : "warning" // prettier-ignore
                  }
                >
                  {/* (whole number) */}
                  (% as decimal)
                </span>
              </label>
              <input
                id="critRate"
                name="critRate"
                className="form-control bottomMargin"
                value={this.state.critRate}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6">
              <label htmlFor="critDmg">
                Crit Damage{' '}
                <span
                  className={
                    validateFloat(this.state.critDmg) ? "" : "warning" // prettier-ignore
                  }
                >
                  {/* (whole number) */}
                  (% as decimal)
                </span>
              </label>
              <input
                id="critDmg"
                name="critDmg"
                className="form-control bottomMargin"
                value={this.state.critDmg}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6">
              <label htmlFor="reactionBonus">
                Reaction Bonus{' '}
                <span
                  className={
                    validateFloat(this.state.reactionBonus) ? "" : "warning" // prettier-ignore
                  }
                >
                  {/* (whole number) */}
                  (% as decimal)
                </span>
              </label>
              <input
                id="reactionBonus"
                name="reactionBonus"
                className="form-control bottomMargin"
                value={this.state.reactionBonus}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6">
              <label htmlFor="flatDmg">
                Flat Damage{' '}
                <span
                  className={
                    validateWholeNumber(this.state.flatDmg) ? "" : "warning" // prettier-ignore
                  }
                >
                  (whole number)
                  {/* (% as decimal) */}
                </span>
              </label>
              <input
                id="flatDmg"
                name="flatDmg"
                className="form-control bottomMargin"
                value={this.state.flatDmg}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6">
              <label htmlFor="defenseDown">
                Defense Down{' '}
                <span
                  className={
                    validateFloat(this.state.defenseDown) ? "" : "warning" // prettier-ignore
                  }
                >
                  {/* (whole number) */}
                  (% as decimal)
                </span>
              </label>
              <input
                id="defenseDown"
                name="defenseDown"
                className="form-control bottomMargin"
                value={this.state.defenseDown}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
        </form>
        <button
          className="button1 maximize_damage bottomMargin"
          onClick={() => {
            this.calculateDamage();
          }}
          disabled={this.state.calculateDisabled}
        >
          Calculate Damage!
        </button>
        {this.state.damageSummary ? (
          <div
            className="row row-cols-1 row-cols-md-2"
            ref={(ref) => (this.damageDisplayRef = ref)}
          >
            <div className="col mb-2">
              <div className="card border-primary myContainerBG">
                <div className="card-header noBG">
                  <span>
                    <h3>Damage Summary</h3>
                  </span>
                </div>
                <ul className="list-group">
                  <li className="list-group-item noBG">
                    Elemental Damage:{' '}
                    {this.state.damageSummary.damage?.toFixed(0)}
                  </li>
                  <li className="list-group-item noBG">
                    Elemental Crit Damage:{' '}
                    {this.state.damageSummary.damageCrit?.toFixed(0)}
                  </li>
                  <li className="list-group-item noBG">
                    <b>
                      Elemental Average Damage:{' '}
                      {this.state.damageSummary.averageDamage?.toFixed(0)}
                    </b>
                  </li>
                  <li className="list-group-item noBG text-muted">
                    Physical Damage:{' '}
                    {this.state.damageSummary?.physicalDamage?.toFixed(0)}
                  </li>
                  <li className="list-group-item noBG text-muted">
                    Crit Physical Damage:{' '}
                    {this.state.damageSummary.physicalDamageCrit?.toFixed(0)}
                  </li>
                  <li className="list-group-item noBG">
                    <b className="text-muted">
                      Average Physical Damage:{' '}
                      {this.state.damageSummary?.physicalAverageDamage?.toFixed(
                        0,
                      )}
                    </b>
                  </li>
                  <li className="list-group-item noBG">
                    <b>
                      BOTH Average Damage:{' '}
                      {this.state.damageSummary?.bothAverage?.toFixed(0)}
                    </b>
                  </li>
                  <li className="list-group-item noBG text-primary">
                    2x Vaporize/Melt Damage:{' '}
                    {this.state.damageSummary.hydroVaporize.toFixed(0)}
                  </li>
                  <li className="list-group-item noBG text-danger">
                    2x Vaporize/Melt Crit Damage:{' '}
                    {this.state.damageSummary.hydroVaporizeCrit.toFixed(0)}
                  </li>
                  <li className="list-group-item noBG text-primary">
                    1.5x Vaporize/Melt Damage:{' '}
                    {this.state.damageSummary.pyroVaporize.toFixed(0)}
                  </li>
                  <li className="list-group-item noBG text-danger">
                    1.5x Vaporize/Melt Crit Damage:{' '}
                    {this.state.damageSummary.pyroVaporizeCrit.toFixed(0)}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Calculator;

import React, { Component } from 'react';
import './styles.css';
import KeyboardLetter from './keyboardLetter';
import KeyboardEnter from './keyboardEnter';
import KeyboardDelete from './keyboardBackspace';

class Keyboard extends Component {
  componentDidMount() {}
  render() {
    var { keyboardKeys } = this.props;
    const keyboardTopRow = keyboardKeys.slice(0, 10);
    const keyboardMiddleRow = keyboardKeys.slice(10, 19);
    const keyboardBottomRow = keyboardKeys.slice(19, 26);
    return (
      <div className="keyboard">
        <div className="keyboardRow">
          {keyboardTopRow.map((key) => {
            return (
              <KeyboardLetter
                letter={key.letter}
                key={key.letter}
                display={key.display}
              />
            );
          })}
        </div>
        <div className="keyboardRow">
          {keyboardMiddleRow.map((key) => {
            return (
              <KeyboardLetter
                letter={key.letter}
                key={key.letter}
                display={key.display}
              />
            );
          })}
        </div>
        <div className="keyboardRow">
          <KeyboardEnter />
          {keyboardBottomRow.map((key) => {
            return (
              <KeyboardLetter
                letter={key.letter}
                key={key.letter}
                display={key.display}
              />
            );
          })}
          <KeyboardDelete />
        </div>
      </div>
    );
  }
}

export default Keyboard;

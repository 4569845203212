var getLetterCount = (word) => {
  var letterCount = {};
  for (var i = 0; i < word.length; i++) {
    letterCount[word.charAt(i)] = { count: 0, checked: 0 };
  }
  for (var i = 0; i < word.length; i++) {
    letterCount[word.charAt(i)].count++;
  }
  return letterCount;
};

var keyboardKeys = [
  { letter: 'Q', display: '' },
  { letter: 'W', display: '' },
  { letter: 'E', display: '' },
  { letter: 'R', display: '' },
  { letter: 'T', display: '' },
  { letter: 'Y', display: '' },
  { letter: 'U', display: '' },
  { letter: 'I', display: '' },
  { letter: 'O', display: '' },
  { letter: 'P', display: '' },
  { letter: 'A', display: '' },
  { letter: 'S', display: '' },
  { letter: 'D', display: '' },
  { letter: 'F', display: '' },
  { letter: 'G', display: '' },
  { letter: 'H', display: '' },
  { letter: 'J', display: '' },
  { letter: 'K', display: '' },
  { letter: 'L', display: '' },
  { letter: 'Z', display: '' },
  { letter: 'X', display: '' },
  { letter: 'C', display: '' },
  { letter: 'V', display: '' },
  { letter: 'B', display: '' },
  { letter: 'N', display: '' },
  { letter: 'M', display: '' },
];

var checkLetters = (guess, word) => {
  var checked = [];
  var letterCount = getLetterCount(word);
  for (var i = 0; i < word.length; i++) {
    checked.push({ letter: guess.charAt(i), display: 'incorrect' });
  }
  // check if letters are correct
  for (var j = 0; j < word.length; j++) {
    if (guess.charAt(j) === word.charAt(j)) {
      checked[j].display = 'correct';
      letterCount[word.charAt(j)].checked++;
    }
  }
  // check if letters are contained
  for (var k = 0; k < word.length; k++) {
    var currentLetter = guess.charAt(k);
    var currentLetterChecked = letterCount[currentLetter]?.checked || 0;
    var wordLetterCount = letterCount[currentLetter]?.count || 0;
    if (currentLetterChecked < wordLetterCount) {
      if (checked[k].display !== 'correct') {
        checked[k].display = 'contains';
        letterCount[currentLetter].checked++;
      }
    }
  }
  return checked;
};

var checkIfWord = (word) => {
  return true;
};

var row = [
  { letter: 'O', display: 'contains' },
  { letter: 'P', display: 'contains' },
  { letter: 'O', display: 'correct' },
  { letter: 'P', display: 'correct' },
  { letter: 'O', display: 'incorrect' },
];

var updateKeyboard = (rows) => {
  var keyboard = keyboardKeys;
  rows.forEach((row) => {
    row.forEach((key) => {
      if (key.display === 'incorrect') {
        var keyboardIndex = keyboard.findIndex((k) => {
          return k.letter === key.letter;
        });
        keyboard[keyboardIndex].display = 'incorrect';
      }
    });
  });
  rows.forEach((row) => {
    row.forEach((key) => {
      if (key.display === 'contains') {
        var keyboardIndex = keyboard.findIndex((k) => {
          return k.letter === key.letter;
        });
        keyboard[keyboardIndex].display = 'contains';
      }
    });
  });
  rows.forEach((row) => {
    row.forEach((key) => {
      if (key.display === 'correct') {
        var keyboardIndex = keyboard.findIndex((k) => {
          return k.letter === key.letter;
        });
        keyboard[keyboardIndex].display = 'correct';
      }
    });
  });
  return keyboard;
};

var kleedle = { checkLetters, keyboardKeys, checkIfWord, updateKeyboard };

export default kleedle;

import React, { Component } from "react";
import "./styles.css";

var list = [
  "The damage formula used is from genshin-impact.fandom.com/wiki/attributes.",
  "This currently does not choose artifact set bonuses for you. Perhaps when I implement damage types (basic attacks, charged attacks, etc) splitting, I'll consider implementing this.",
  "DEF and HP scaling damage abilities are not yet implemented. Sorry Noelle mains :(.",
  "Damage bonuses are not split up between normal attacks, charged attacks, and bursts. This effects the calculations for some weapons so please keep that in mind.",
  "I'd like for the damage calculator to allow you to input character, weapon, refinement level, and situational bonus. Then the damage calculator will compare your damage to the damage maximizer's calculation and you can have an easier idea as to how close you are to your character's potential.",
  "Mona's elemental damage scaling with energy regen has not been implemented yet. I added the base amount of elemental damage she gains from her ascension but the algorithm does not currently shuffle energy regeneration for maximizing damage.",
  "Not all weapons are included. This is currently a manual process. Expect all 4* and 5* weapons to be added by December 13th.",
  "Electro elemental reactions are not currently included. I figured these are really straightforward since all you have to do is max level and max elemental mastery if you want to max these reactions.",
  "Different weights for favoring elemental and physical damage has not yet been implemented. This would allow you to optimize for 60% elemental damage and 40% physical damage.",
  "I've only included level 70, 80, and 90 because it's not practical to have full sets of good level 20 good 5 star artifacts before level 80.",
  "It's possible to have average damage higher than what is shown by this maximizer app, since the artifact substat rolls use the average value.",
  "There are some rounding errors which may have small discrepencies compared to in-game damage.",
  "I apologize in advance for any downtime this site might have. I don't expect you guys to crash it but if you do, my apologies.",
];

class WIP extends Component {
  render() {
    return (
      <div className="container myContainerBG">
        <h3>
          Here's some stuff that's currently not accounted for. Some of this
          stuff is stuff I am actively working on.{" "}
          <a href="/">Take me back to the damage maximizer!</a>
        </h3>
        <ul className="list-group list-group-flush noBG">
          {list.map((o, i) => {
            return (
              <li key={i} className="list-group-item myContainerBG">
                {o}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default WIP;

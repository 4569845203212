import React, { Component } from 'react';

class FormBox extends Component {
  render() {
    var {
      inputVariable,
      variableId,
      validate,
      display,
      wholeNumber,
      handleInputChange,
    } = this.props;
    return (
      <div className="col-6 col-sm-4 bottomMargin">
        <label htmlFor={variableId}>
          {display + ' '}
          <span
            className={
              validate(inputVariable) ? "" : "warning" // prettier-ignore
            }
          >
            {wholeNumber ? '(Whole Number)' : '(% as decimal)'}
          </span>
        </label>
        <input
          id={variableId}
          name={variableId}
          className="form-control"
          value={inputVariable}
          onChange={handleInputChange}
        />
      </div>
    );
  }
}

export default FormBox;
